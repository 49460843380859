import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import DraftCard from './DraftCard';

const LegalDrafts = () => {
    const [drafts, setDrafts] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [legalNoticeOrRecoverySuit, setLegalNoticeOrRecoverySuit] = useState('');
    const [draftType, setDraftType] = useState(null);
    const [modalTitle, setModalTitle] = useState('');
    const [activeTab, setActiveTab] = useState('civilSuits');
    const commonUrl = process.env.REACT_APP_COMMON_URL;
    const contentRef = useRef(null);

    const civilSuitsDrafts = [
        {
            title: 'Legal Notice',
            description: 'Draft a legal notice to formally notify a party of a legal obligation.',
            draftTypeId: 11, // Replace with your actual draftTypeId for Legal Notice
            disabled: false,
        },
        {
            title: 'Reply to Legal Notice',
            description: "Draft a formal response to a legal notice, addressing the claims made and outlining the party's legal stance or obligations.",
            draftTypeId: 12, // Replace with your actual draftTypeId for Legal Notice
            disabled: false,
        },
        {
            title: 'Recovery Suit',
            description: 'Prepare a recovery suit to reclaim owed debts or assets.',
            draftTypeId: 4, // Replace with your actual draftTypeId for Recovery Suit
            disabled: false,
        },
        {
            title: 'Settlement Notice',
            description: 'Prepare a Notice for Negotiation or Settlement',
            draftTypeId: 18, // Replace with your actual draftTypeId for Recovery Suit
            disabled: false,
        },
        {
            title: 'Declaration Suit',
            description: 'Create a suit requesting a legal declaration on a matter.',
            draftTypeId: 14, // Assign appropriate draftTypeId
            disabled: false,
        },
        {
            title: 'Injunction Suit',
            description: 'Prepare a suit seeking a court order to prevent an action.',
            draftTypeId: 16,
            disabled: false,
        },
        {
            title: 'Injunction Application',
            description: 'Prepare a temporary application seeking a court order to prevent an action.',
            draftTypeId: 15,
            disabled: false,
        },
        {
            title: 'Stay Application',
            description: 'Draft a stay application',
            draftTypeId: 13, // Assign appropriate draftTypeId
            disabled: false,
        },
        {
            title: 'Execution Application',
            description: 'Prepare a application seeking a court order to execute an action.',
            draftTypeId: 17, // Assign appropriate draftTypeId
            disabled: false,
        },
        {
            title: 'Property Dispute Suit',
            description: 'Create a suit to resolve disputes over property ownership or rights.',
            draftTypeId: 15, // Assign appropriate draftTypeId
            disabled: true,
        },
        {
            title: 'Breach of Contract',
            description: 'Draft a suit addressing the breach of a contractual agreement.',
            draftTypeId: 16, // Assign appropriate draftTypeId
            disabled: true,
        },
        {
            title: 'Specific Performance',
            description: 'Draft a suit demanding the fulfillment of contractual obligations.',
            draftTypeId: 18, // Assign appropriate draftTypeId
            disabled: true,
        },
        {
            title: 'Damages Suit',
            description: 'Prepare a suit seeking compensation for losses or injuries.',
            draftTypeId: 20, // Assign appropriate draftTypeId
            disabled: true,
        },
        {
            title: 'Partition Suit',
            description: 'Draft a suit to divide jointly owned property among owners.',
            draftTypeId: 21, // Assign appropriate draftTypeId
            disabled: true,
        },
    ];

    const contractsDrafts = [
        {
            title: 'Partnership Deed',
            description: 'Create a legal document outlining the terms of a partnership.',
            draftTypeId: 2,
            disabled: false,
        },
        {
            title: 'Settlement Agreement',
            description: 'Draft an agreement to settle a dispute between parties.',
            draftTypeId: 3,
            disabled: false,
        },
        {
            title: 'Sale Agreement',
            description: 'Prepare a contract for the sale of goods or services.',
            draftTypeId: 6,
            disabled: false,
        },
        {
            title: 'Lease Agreement',
            description: 'Generate a lease contract for renting property.',
            draftTypeId: 10,
            disabled: true,
        },
        {
            title: 'NDAs',
            description: 'Create a Non-Disclosure Agreement to protect confidential information.',
            draftTypeId: 5,
            disabled: false,
        },
        {
            title: 'Employment Contracts',
            description: 'Draft a contract outlining employment terms.',
            draftTypeId: 8,
            disabled: false,
        },
        {
            title: 'Service Agreement',
            description: 'Prepare an agreement for providing services to a client.',
            draftTypeId: 7,
            disabled: false,
        },
        {
            title: 'Joint Venture Agreement',
            description: 'Create an agreement for a business joint venture.',
            draftTypeId: 11,
            disabled: true,
        },
        {
            title: 'Franchise Agreement',
            description: 'Draft a contract for franchising a business model.',
            draftTypeId: 12,
            disabled: true,
        },
    ];

    const legalPetitionsDrafts = [
        {
            title: 'Bail Application',
            description: 'Prepare an application for bail in a legal proceeding.',
            draftTypeId: 1,
            disabled: false,
        },
        {
            title: 'Appeals',
            description: 'Draft a legal document to appeal a court decision.',
            draftTypeId: 9,
            disabled: false,
        },
        {
            title: 'Writ Petition',
            description: 'Generate a petition for writ in a higher court.',
            draftTypeId: 13,
            disabled: true,
        },
        {
            title: 'Constitutional Petition',
            description: 'Prepare a petition challenging the constitutionality of a law.',
            draftTypeId: 14,
            disabled: true,
        },
    ];

    return (
        <div className="container mt-4">
            {/* Tabs Navigation */}
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <button
                        className={`nav-link ${activeTab === 'legalPetitions' ? 'active' : ''}`}
                        onClick={() => setActiveTab('legalPetitions')}
                    >
                        Legal Petitions
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        className={`nav-link ${activeTab === 'contracts' ? 'active' : ''}`}
                        onClick={() => setActiveTab('contracts')}
                    >
                        Contracts & Agreements
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        className={`nav-link ${activeTab === 'civilSuits' ? 'active' : ''}`}
                        onClick={() => setActiveTab('civilSuits')}
                    >
                        Civil Matters
                    </button>
                </li>
            </ul>

            {/* Tab Content */}
            <div className="tab-content mt-3">
                {/* Contracts & Agreements Tab */}
                {activeTab === 'contracts' && (
                    <div className="tab-pane active mt-5">
                        <div className="row">
                            {contractsDrafts.map((draft) => (
                                <DraftCard
                                    key={draft.draftTypeId}
                                    title={draft.title}
                                    description={draft.description}
                                    draftTypeId={draft.draftTypeId}
                                    disabled={draft.disabled}
                                />
                            ))}
                        </div>
                    </div>
                )}

                {/* Legal Petitions Tab */}
                {activeTab === 'legalPetitions' && (
                    <div className="tab-pane active mt-5">
                        <div className="row">
                            {legalPetitionsDrafts.map((draft) => (
                                <DraftCard
                                    key={draft.draftTypeId}
                                    title={draft.title}
                                    description={draft.description}
                                    draftTypeId={draft.draftTypeId}
                                    disabled={draft.disabled}
                                />
                            ))}
                        </div>
                    </div>
                )}

                {/* Civil Suits Tab */}
                {activeTab === 'civilSuits' && (
                    <div className="tab-pane active mt-5">
                        <div className="row">
                            {civilSuitsDrafts.map((draft) => (
                                <DraftCard
                                    key={draft.draftTypeId}
                                    title={draft.title}
                                    description={draft.description}
                                    draftTypeId={draft.draftTypeId}
                                    disabled={draft.disabled}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LegalDrafts;

import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import style from "../Chatbot/ChatArea.module.css"
import { saveAs } from "file-saver";
import HtmlDocx from "html-docx-js/dist/html-docx";
import { NumericFormat } from 'react-number-format';

function PreviewPage(
    {
        bailApplicationData,
        setBailApplicationData,
        partnershipDeedData,
        setPartnershipDeedData,
        settlementAgreementData,
        setSettlementAgreementData,
        recoverySuitData,
        setRecoverySuitData,
        nonDisclosureAgreementData,
        setNonDisclosureAgreementData,
        salesAgreementData,
        setSalesAgreementData,
        setServiceAgreementData,
        serviceAgreementData,
        employmentContractData,
        setEmploymentContractData,
        legalAppealData,
        setLegalAppealData,
        caseSummarizationData,
        setCaseSummarizationData,
        setLegalNoticeData,
        legalNoticeData,
        draftMasterId,
        setReplyLegalNoticeData,
        replyLegalNoticeData,
        setDeclarationSuitData,
        declarationSuitData,
        setStayApplicationData,
        stayApplicationData,
        setInjunctionAppData,
        injunctionAppData,
        setInjunctionSuitData,
        injunctionSuitData,
        setExecutionAppData,
        executionAppData,
        setSettlementNoticeData,
        settlementNoticeData
    }) {
    const commonUrl = process.env.REACT_APP_COMMON_URL;
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState(''); // Store petition/partnership deed content
    const [insertedDraftID, setInsertedDraftID] = useState(''); // Store inserted draft ID
    const [generated, setGenerated] = useState(false); // Track if content is generated
    const [reseachDocList, setReseachDocList] = useState([]);
    const [isEditing, setIsEditing] = useState(false); // Track if in edit mode
    const [editedContent, setEditedContent] = useState('');
    const [isQuotaExceeded, setIsQuotaExceeded] = useState(false);
    const contentRef = useRef(null); // Reference for the content to be exported to PDF

    const [isModalOpen, setModalOpen] = useState(false);
    const [judgmentUrl, setJudgmentUrl] = useState('');
    const [fileContent, setFileContent] = useState('');
    const [modalTitle, setModalTitle] = useState('');

    const navigate = useNavigate();
    const location = useLocation();
    const { draftTypeId } = location.state || {};
    // console.log(draftTypeId)

    const handleJudgmentClick = (source, citation) => {
        const url = `/Judgments/${encodeURIComponent(source)}`;
        setJudgmentUrl(url);      // Set the URL to fetch
        setModalTitle(citation);  // Set the modal title
        setFileContent('');       // Reset the file content
        setModalOpen(true);       // Open the modal
    };

    useEffect(() => {
        if (judgmentUrl) {
            fetch(judgmentUrl)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.text();
                })
                .then(text => {
                    setFileContent(text);
                })
                .catch(error => {
                    console.error('Error loading judgment content:', error);
                    setFileContent('Error loading judgment content.');
                });
        }
    }, [judgmentUrl]);


    console.log(draftMasterId)

    const handleGenerate = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('access_token');

            // Use a switch or mapping to handle different form data
            let formData;

            switch (draftTypeId) {
                case '1':
                    formData = bailApplicationData;
                    break;
                case '2':
                    formData = partnershipDeedData;
                    break;
                case '3':
                    formData = settlementAgreementData;
                    break;
                case '4':
                    formData = recoverySuitData;
                    break;
                case '5':
                    formData = nonDisclosureAgreementData;
                    break;
                case '6':
                    formData = salesAgreementData;
                    break;
                case '7':
                    formData = serviceAgreementData;
                    break;
                case '8':
                    formData = employmentContractData;
                    break;
                case '9':
                    formData = legalAppealData;
                    break;
                case '10':
                    formData = caseSummarizationData;
                    break;
                case '11':
                    formData = legalNoticeData;
                    break;
                case '12':
                    formData = replyLegalNoticeData;
                    break;
                case '13':
                    formData = stayApplicationData;
                    break;
                case '14':
                    formData = declarationSuitData;
                    break;
                case '15':
                    formData = injunctionAppData;
                    break;
                case '16':
                    formData = injunctionSuitData;
                    break;
                case '17':
                    formData = executionAppData;
                    break;
                case '18':
                    formData = settlementNoticeData;
                    break;
                default:
                    console.error("Unknown draft type ID:", draftTypeId);
                    return;
            }

            // Determine the API endpoint based on draftTypeId
            let apiEndpoint = `${commonUrl}/api/generate-draft`;
            if (draftTypeId === '11') {
                apiEndpoint = `${commonUrl}/api/generate-legal-notice`;
            } else if (draftTypeId === '3') {
                apiEndpoint = `${commonUrl}/api/generate-settlement-agreement`;
            } else if (draftTypeId === '4') {
                apiEndpoint = `${commonUrl}/api/generate-recovery-suit`;
            } else if (draftTypeId === '12') {
                apiEndpoint = `${commonUrl}/api/generate-reply-legal-notice`;
            } else if (draftTypeId === '13') {
                apiEndpoint = `${commonUrl}/api/generate-stay-order`;
            } else if (draftTypeId === '14') {
                apiEndpoint = `${commonUrl}/api/generate-declaration-suit`;
            } else if (draftTypeId === '15') {
                apiEndpoint = `${commonUrl}/api/generate-injunction-application`;
            } else if (draftTypeId === '16') {
                apiEndpoint = `${commonUrl}/api/generate-injunction-suit`;
            } else if (draftTypeId === '17') {
                apiEndpoint = `${commonUrl}/api/generate-execution-application`;
            } else if (draftTypeId === '18') {
                apiEndpoint = `${commonUrl}/api/generate-settlement-notice`;
            }

            const requestBody = {
                case_details: JSON.stringify(formData),
                draft_type_id: draftTypeId,
            };
            // Include draftMasterId if it's set
            if (draftMasterId) {
                requestBody.draft_master_id = draftMasterId;
            }

            const response = await axios.post(apiEndpoint, requestBody, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setContent(response.data.generated_draft);
            setInsertedDraftID(response.data.draft_id);
            setGenerated(true); // Mark that the content is generated
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    localStorage.removeItem('IsAuthenticated');
                    localStorage.removeItem('access_token');
                    navigate('/', {
                        state: {
                            message:
                                'You have been logged out due to an invalid or expired session.',
                        },
                    });
                } else if (error.response.status === 403) {
                    setIsQuotaExceeded(true)
                } else {
                    console.error('Error communicating with the API:', error);
                }
            } else {
                console.error('Error communicating with the API:', error);
            }
        } finally {
            setLoading(false);
        }
    };

    const formatDate = dateStr => {
        if (!dateStr) return 'Not specified';
        const date = new Date(dateStr);
        return date.toLocaleDateString();
    };

    const handleFinishEditing = async () => {
        if (!insertedDraftID) return;

        try {
            const token = localStorage.getItem('access_token');
            await axios.put(`${commonUrl}/api/update-draft/`, {
                draft: editedContent,
                inserted_draft_id: insertedDraftID,
                draft_type_id: draftTypeId
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setContent(editedContent); // Update the content with the edited content
            setIsEditing(false);
        } catch (error) {
            console.error('Error updating content:', error);
        }
    };

    const handleResearchToggle = async () => {
        setLoading(true);
        console.log(draftTypeId);
        console.log(insertedDraftID);
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.post(`${commonUrl}/api/research-judgments/`, {
                draft_type_id: draftTypeId,
                inserted_draft_id: insertedDraftID
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // console.log(response.data.results);
            setReseachDocList(response.data.results);
        } catch (error) {
            console.error('Error updating content:', error);
        } finally {
            setLoading(false);
        }
    };

    const closeModal = () => {
        setModalOpen(false);
        setFileContent('');
        setJudgmentUrl('');
    };

    const handleBack = () => {
        // Pass the correct form data back to the form page depending on draftTypeId
        if (draftTypeId === '1') {
            setBailApplicationData(bailApplicationData);
        } else if (draftTypeId === '2') {
            setPartnershipDeedData(partnershipDeedData);
        } else if (draftTypeId === '3') {
            setSettlementAgreementData(settlementAgreementData);
        } else if (draftTypeId === '4') {
            setRecoverySuitData(recoverySuitData);
        } else if (draftTypeId === '5') {
            setNonDisclosureAgreementData(nonDisclosureAgreementData);
        } else if (draftTypeId === '6') {
            setSalesAgreementData(salesAgreementData);
        } else if (draftTypeId === '7') {
            setServiceAgreementData(serviceAgreementData);
        } else if (draftTypeId === '8') {
            setEmploymentContractData(employmentContractData);
        } else if (draftTypeId === '9') {
            setLegalAppealData(legalAppealData);
        } else if (draftTypeId === '10') {
            setCaseSummarizationData(caseSummarizationData);
        } else if (draftTypeId === '11') {
            setLegalNoticeData(legalNoticeData);
        } else if (draftTypeId === '12') {
            setReplyLegalNoticeData(replyLegalNoticeData);
        } else if (draftTypeId === '13') {
            setStayApplicationData(stayApplicationData);
        } else if (draftTypeId === '14') {
            setDeclarationSuitData(declarationSuitData);
        } else if (draftTypeId === '15') {
            setInjunctionAppData(injunctionAppData);
        } else if (draftTypeId === '16') {
            setInjunctionSuitData(injunctionSuitData);
        } else if (draftTypeId === '17') {
            setExecutionAppData(executionAppData);
        } else if (draftTypeId === '18') {
            setSettlementNoticeData(settlementNoticeData);
        }

        navigate(`/dashboard/form?draft_type_id=${draftTypeId}`, { state: { draftTypeId } });
    };

    const preprocessHtmlContent = (html) => {
        return html
            .replace(/“/g, '&ldquo;')
            .replace(/”/g, '&rdquo;')
            .replace(/‘/g, '&lsquo;')
            .replace(/’/g, '&rsquo;')
            .replace(/-/g, '&ndash;')
            .replace(/—/g, '&mdash;')
            .replace(/…/g, '&hellip;');
    };

    const generateWord = () => {
        try {
            const contentElement = contentRef.current;

            if (!contentElement) {
                console.error("Content reference is null");
                return;
            }

            // Get the HTML content
            let contentHtml = contentElement.innerHTML;
            contentHtml = preprocessHtmlContent(contentHtml);
            // Convert the HTML to a Word document blob
            const docxBlob = HtmlDocx.asBlob(contentHtml);

            // Save the blob as a .docx file
            saveAs(docxBlob, "content.docx");
        } catch (error) {
            console.error("Error generating document:", error);
        }
    };


    useEffect(() => {
        if (!isEditing) {
            handleFinishEditing();
        }
    }, [isEditing]);

    // When switching to edit mode, initialize the editedContent state with current content
    const handleEditToggle = () => {
        if (!isEditing) {
            setEditedContent(content); // Set the edited content to the current content when editing starts
        }
        setIsEditing(!isEditing); // Toggle edit mode
    };

    const renderPreviewContent = () => {
        if (draftTypeId === '1') {
            // Render Bail Application Preview
            return (
                <ul className="list-group list-group-flush">
                    <li className="list-group-item"><strong>Court Name:</strong> {bailApplicationData.courtName}</li>
                    <li className="list-group-item"><strong>City:</strong> {bailApplicationData.city}</li>
                    <li className="list-group-item"><strong>Petitioner Name:</strong> {bailApplicationData.petitioner}</li>
                    <li className="list-group-item"><strong>Respondents:</strong> {bailApplicationData.respondents}</li>
                    <li className="list-group-item"><strong>FIR Number:</strong> {bailApplicationData.firNumber}</li>
                    <li className="list-group-item"><strong>FIR Date:</strong> {bailApplicationData.firDate}</li>
                    <li className="list-group-item"><strong>Offense Section:</strong> {bailApplicationData.offenseSection}</li>
                    <li className="list-group-item"><strong>Police Station:</strong> {bailApplicationData.policeStation}</li>
                    <li className="list-group-item"><strong>FIR Description:</strong> {bailApplicationData.firDescription}</li>
                    <li className="list-group-item"><strong>Petitioner's Arguments:</strong> {bailApplicationData.petitionerArguments}</li>
                    <li className="list-group-item"><strong>Advocate Name:</strong> {bailApplicationData.advocateName}</li>
                    <li className="list-group-item"><strong>License Number:</strong> {bailApplicationData.licenseNumber}</li>
                </ul>
            );
        } else if (draftTypeId === '2') {
            // Render Partnership Deed Preview
            return (
                <>
                    <h4>Partners Information</h4>
                    {partnershipDeedData.partners.map((partner, index) => (
                        <ul className="list-group list-group-flush" key={index}>
                            <li className="list-group-item"><strong>Partner {index + 1} Full Name:</strong> {partner.fullName}</li>
                            <li className="list-group-item"><strong>ID/Identification Number:</strong> {partner.idNumber}</li>
                            <li className="list-group-item"><strong>Address:</strong> {partner.address}</li>
                            <li className="list-group-item"><strong>Role:</strong> {partner.role}</li>
                            <li className="list-group-item"><strong>Responsibilities:</strong> {partnershipDeedData.responsibilities[index] || 'No specific responsibility'}</li>
                        </ul>
                    ))}

                    <h4>Business Information</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Business Name:</strong> {partnershipDeedData.businessInfo.businessName}</li>
                        <li className="list-group-item"><strong>Business Nature:</strong> {partnershipDeedData.businessInfo.businessNature}</li>
                        <li className="list-group-item"><strong>Business Address:</strong> {partnershipDeedData.businessInfo.businessAddress}</li>
                    </ul>

                    <h4>Financial Information</h4>
                    {Object.keys(partnershipDeedData.financials.capitalContributions).map((partnerIndex) => (
                        <ul className="list-group list-group-flush" key={partnerIndex}>
                            <li className="list-group-item">
                                <strong>Partner {parseInt(partnerIndex) + 1} Capital Contribution:</strong> {partnershipDeedData.financials.capitalContributions[partnerIndex]}
                            </li>
                            <li className="list-group-item">
                                <strong>Partner {parseInt(partnerIndex) + 1} Profit Sharing Ratio:</strong> {partnershipDeedData.financials.profitSharingRatios[partnerIndex]}
                            </li>
                        </ul>
                    ))}

                    <h4>Banking and Financial Arrangements</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Joint Signatory Required:</strong> {partnershipDeedData.bankingInfo.jointSignatory ? 'Yes' : 'No'}</li>
                        <li className="list-group-item"><strong>Other Banking Instructions:</strong> {partnershipDeedData.bankingInfo.bankInstructions}</li>
                        <li className="list-group-item"><strong>Fiscal Year Start:</strong> {partnershipDeedData.bankingInfo.fiscalYearStart}</li>
                        <li className="list-group-item"><strong>Fiscal Year End:</strong> {partnershipDeedData.bankingInfo.fiscalYearEnd}</li>
                    </ul>

                    <h4>Legal Provisions</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Dispute Resolution Method:</strong> {partnershipDeedData.legalProvisions.disputeResolutionMethod}</li>
                        <li className="list-group-item"><strong>Jurisdiction:</strong> {partnershipDeedData.legalProvisions.jurisdiction}</li>
                        <li className="list-group-item"><strong>Transfer Shares to Heirs:</strong> {partnershipDeedData.legalProvisions.transferSharesToHeirs ? 'Yes' : 'No'}</li>
                        <li className="list-group-item"><strong>Share Transfer Restrictions:</strong> {partnershipDeedData.legalProvisions.shareTransferRestrictions}</li>
                    </ul>
                </>
            );
        } else if (draftTypeId === '3') {
            // return (
            //     <>
            //         <h2 className="text-center">Settlement Agreement Preview</h2>

            //         {/* Parties Information */}
            //         <h4 className="mt-4">1. Parties Involved</h4>
            //         <ul className="list-group list-group-flush">
            //             <li className="list-group-item">
            //                 <strong>Party 1 (Creditor/Settler) Full Name or Business Name:</strong> {settlementAgreementData.party1.fullName}
            //             </li>
            //             <li className="list-group-item">
            //                 <strong>Party 1 CNIC/Company Registration Number:</strong> {settlementAgreementData.party1.cnic}
            //             </li>
            //             <li className="list-group-item">
            //                 <strong>Party 1 Address:</strong> {settlementAgreementData.party1.address}
            //             </li>
            //             <li className="list-group-item">
            //                 <strong>Party 1 Contact Information:</strong> {settlementAgreementData.party1.contact}
            //             </li>
            //         </ul>

            //         <ul className="list-group list-group-flush">
            //             <li className="list-group-item">
            //                 <strong>Party 2 (Debtor/Settlee) Full Name or Business Name:</strong> {settlementAgreementData.party2.fullName}
            //             </li>
            //             <li className="list-group-item">
            //                 <strong>Party 2 CNIC/Company Registration Number:</strong> {settlementAgreementData.party2.cnic}
            //             </li>
            //             <li className="list-group-item">
            //                 <strong>Party 2 Address:</strong> {settlementAgreementData.party2.address}
            //             </li>
            //             <li className="list-group-item">
            //                 <strong>Party 2 Contact Information:</strong> {settlementAgreementData.party2.contact}
            //             </li>
            //         </ul>

            //         {/* Agreement Details */}
            //         <h4 className="mt-4">2. Agreement Details</h4>
            //         <ul className="list-group list-group-flush">
            //             <li className="list-group-item">
            //                 <strong>Effective Date:</strong> {settlementAgreementData.agreement.effectiveDate}
            //             </li>
            //             <li className="list-group-item">
            //                 <strong>Settlement Purpose:</strong> {settlementAgreementData.agreement.purpose}
            //             </li>
            //             <li className="list-group-item">
            //                 <strong>Jurisdiction:</strong> {settlementAgreementData.agreement.jurisdiction}
            //             </li>
            //         </ul>

            //         {/* Financial Settlement Terms */}
            //         <h4 className="mt-4">3. Financial Settlement Terms</h4>
            //         <ul className="list-group list-group-flush">
            //             <li className="list-group-item">
            //                 <strong>Total Amount Due:</strong> {settlementAgreementData.financial.totalAmountDue}
            //             </li>
            //             <li className="list-group-item">
            //                 <strong>Currency:</strong> {settlementAgreementData.financial.currency}
            //             </li>
            //             <li className="list-group-item">
            //                 <strong>Payment Schedule:</strong> {settlementAgreementData.financial.paymentSchedule || 'No payment schedule provided'}
            //             </li>
            //         </ul>

            //         {/* Ownership and Asset Transfer */}
            //         <h4 className="mt-4">4. Ownership and Asset Transfer (if applicable)</h4>
            //         <ul className="list-group list-group-flush">
            //             <li className="list-group-item">
            //                 <strong>Items to Be Transferred:</strong> {settlementAgreementData.ownershipTransfer.itemsToTransfer || 'No items specified'}
            //             </li>
            //             <li className="list-group-item">
            //                 <strong>Transfer of Ownership Date:</strong> {settlementAgreementData.ownershipTransfer.transferDate}
            //             </li>
            //             <li className="list-group-item">
            //                 <strong>Conditions for Transfer:</strong> {settlementAgreementData.ownershipTransfer.transferConditions || 'No conditions specified'}
            //             </li>
            //         </ul>

            //         {/* Breach of Agreement and Dispute Resolution */}
            //         <h4 className="mt-4">5. Breach of Agreement and Dispute Resolution</h4>
            //         <ul className="list-group list-group-flush">
            //             <li className="list-group-item">
            //                 <strong>Dispute Resolution Method:</strong> {settlementAgreementData.disputeResolution.method}
            //             </li>
            //             <li className="list-group-item">
            //                 <strong>Arbitration Clause:</strong> {settlementAgreementData.disputeResolution.arbitrationClause || 'No arbitration clause provided'}
            //             </li>
            //         </ul>

            //         {/* Confidentiality and Other Clauses */}
            //         <h4 className="mt-4">6. Confidentiality and Other Clauses</h4>
            //         <ul className="list-group list-group-flush">
            //             <li className="list-group-item">
            //                 <strong>Confidentiality Clause:</strong> {settlementAgreementData.confidentiality.confidentialityClause || 'No confidentiality clause'}
            //             </li>
            //             <li className="list-group-item">
            //                 <strong>Force Majeure:</strong> {settlementAgreementData.confidentiality.forceMajeure || 'No force majeure clause'}
            //             </li>
            //             <li className="list-group-item">
            //                 <strong>Governing Law:</strong> {settlementAgreementData.confidentiality.governingLaw}
            //             </li>
            //         </ul>

            //         {/* Signatures and Witnesses */}
            //         <h4 className="mt-4">7. Signatures and Witnesses</h4>
            //         <ul className="list-group list-group-flush">
            //             <li className="list-group-item">
            //                 <strong>Witness 1 Name:</strong> {settlementAgreementData.signatures.witness1.name || 'Not provided'}
            //             </li>
            //             <li className="list-group-item">
            //                 <strong>Witness 1 Address:</strong> {settlementAgreementData.signatures.witness1.address || 'Not provided'}
            //             </li>
            //             <li className="list-group-item">
            //                 <strong>Witness 2 Name:</strong> {settlementAgreementData.signatures.witness2.name || 'Not provided'}
            //             </li>
            //             <li className="list-group-item">
            //                 <strong>Witness 2 Address:</strong> {settlementAgreementData.signatures.witness2.address || 'Not provided'}
            //             </li>
            //         </ul>
            //     </>
            // );
            return (
                <>
                    <h2 className="text-center">Settlement Agreement Preview</h2>

                    {/* Section 1: Basic Details */}
                    <h4 className="mt-4">Basic Details</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Agreement Title:</strong> {settlementAgreementData.basicDetails.agreementTitle || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Date of Agreement:</strong> {settlementAgreementData.basicDetails.dateOfAgreement || 'Not specified'}
                        </li>
                    </ul>

                    <h5 className="mt-3">Creditor (Bank/Finance Company) Information</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Bank/Finance Company Name:</strong> {settlementAgreementData.basicDetails.bankName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Branch Name:</strong> {settlementAgreementData.basicDetails.branchName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Address:</strong> {settlementAgreementData.basicDetails.bankAddress || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Authorized Representative Name:</strong> {settlementAgreementData.basicDetails.authorizedRepName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Designation:</strong> {settlementAgreementData.basicDetails.designation || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Contact Information:</strong> {settlementAgreementData.basicDetails.bankContactInfo || 'Not specified'}
                        </li>
                    </ul>

                    <h5 className="mt-3">Debtor (Borrower) Information</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Debtor’s Name:</strong> {settlementAgreementData.basicDetails.debtorName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Company Name:</strong> {settlementAgreementData.basicDetails.debtorCompanyName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>CNIC/ID Number:</strong> {settlementAgreementData.basicDetails.debtorCnic || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Address:</strong> {settlementAgreementData.basicDetails.debtorAddress || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Contact Information:</strong> {settlementAgreementData.basicDetails.debtorContactInfo || 'Not specified'}
                        </li>
                    </ul>

                    {/* Section 2: Loan Details */}
                    <h4 className="mt-4">Loan or Financial Obligation Details</h4>
                    <h5>Loan Details</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Loan Account Number:</strong> {settlementAgreementData.loanDetails.loanAccountNumber || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Loan Agreement Date:</strong> {settlementAgreementData.loanDetails.loanAgreementDate || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Original Loan Amount:</strong>{' '}
                            <NumericFormat
                                displayType="text"
                                thousandSeparator
                                value={settlementAgreementData.loanDetails.originalLoanAmount || '0'}
                            />
                        </li>
                        <li className="list-group-item">
                            <strong>Outstanding Principal Amount:</strong>{' '}
                            <NumericFormat
                                displayType="text"
                                thousandSeparator
                                value={settlementAgreementData.loanDetails.outstandingPrincipal || '0'}
                            />
                        </li>
                        <li className="list-group-item">
                            <strong>Mark-up (Interest):</strong>{' '}
                            <NumericFormat
                                displayType="text"
                                thousandSeparator
                                value={settlementAgreementData.loanDetails.markUp || '0'}
                            />
                        </li>
                        <li className="list-group-item">
                            <strong>Penal Charges (if any):</strong>{' '}
                            <NumericFormat
                                displayType="text"
                                thousandSeparator
                                value={settlementAgreementData.loanDetails.penalCharges || '0'}
                            />
                        </li>
                        <li className="list-group-item">
                            <strong>Total Outstanding Amount:</strong>{' '}
                            <NumericFormat
                                displayType="text"
                                thousandSeparator
                                value={settlementAgreementData.loanDetails.totalOutstanding || '0'}
                            />
                        </li>
                    </ul>
                    <h5 className="mt-3">Payment History (if applicable)</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Amount Paid to Date:</strong>{' '}
                            <NumericFormat
                                displayType="text"
                                thousandSeparator
                                value={settlementAgreementData.loanDetails.amountPaidToDate || '0'}
                            />
                        </li>
                        <li className="list-group-item">
                            <strong>Number of Missed Payments:</strong>{' '}
                            <NumericFormat
                                displayType="text"
                                thousandSeparator
                                value={settlementAgreementData.loanDetails.numMissedPayments || '0'}
                            />
                        </li>
                    </ul>

                    {/* Section 3: Settlement Terms */}
                    <h4 className="mt-4">Settlement Terms</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Total Settlement Amount:</strong>{' '}
                            <NumericFormat
                                displayType="text"
                                thousandSeparator
                                value={settlementAgreementData.settlementTerms.totalSettlementAmount || '0'}
                            />
                        </li>
                        <li className="list-group-item">
                            <strong>Agreed Discount/Waiver:</strong>{' '}
                            <NumericFormat
                                displayType="text"
                                thousandSeparator
                                value={settlementAgreementData.settlementTerms.agreedDiscountWaiver || '0'}
                            />
                        </li>
                        <li className="list-group-item">
                            <strong>Final Agreed Settlement Amount:</strong>{' '}
                            <NumericFormat
                                displayType="text"
                                thousandSeparator
                                value={settlementAgreementData.settlementTerms.finalAgreedSettlement || '0'}
                            />
                        </li>
                        <li className="list-group-item">
                            <strong>Settlement Method:</strong> {settlementAgreementData.settlementTerms.settlementMethod || 'Not specified'}
                        </li>
                        {settlementAgreementData.settlementTerms.settlementMethod === 'Lump-Sum Payment' && (
                            <li className="list-group-item">
                                <strong>Due Date for Payment:</strong>{' '}
                                {settlementAgreementData.settlementTerms.dueDateForPayment || 'Not specified'}
                            </li>
                        )}
                        {settlementAgreementData.settlementTerms.settlementMethod === 'Installment Plan' && (
                            <>
                                <li className="list-group-item">
                                    <strong>Number of Installments:</strong>{' '}
                                    <NumericFormat
                                        displayType="text"
                                        thousandSeparator
                                        value={settlementAgreementData.settlementTerms.numberOfInstallments || '0'}
                                    />
                                </li>
                                <li className="list-group-item">
                                    <strong>Amount per Installment:</strong>{' '}
                                    <NumericFormat
                                        displayType="text"
                                        thousandSeparator
                                        value={settlementAgreementData.settlementTerms.amountPerInstallment || '0'}
                                    />
                                </li>
                                <li className="list-group-item">
                                    <strong>Payment Schedule:</strong> {settlementAgreementData.settlementTerms.paymentSchedule || 'Not specified'}
                                </li>
                                <li className="list-group-item">
                                    <strong>Start Date of Installments:</strong>{' '}
                                    {settlementAgreementData.settlementTerms.startDateOfInstallments || 'Not specified'}
                                </li>
                            </>
                        )}
                    </ul>

                    {/* Section 4: Additional Clauses */}
                    <h4 className="mt-4">Additional Clauses</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Default Clause:</strong> {settlementAgreementData.additionalClauses.defaultClause || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Release Clause:</strong> {settlementAgreementData.additionalClauses.releaseClause || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Confidentiality Clause:</strong> {settlementAgreementData.additionalClauses.confidentialityClause || 'Not specified'}
                        </li>
                    </ul>

                    {/* Section 5: Attachments */}
                    <h4 className="mt-4">Attachments</h4>
                    <h5>Mandatory Attachments</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            {settlementAgreementData.attachments.mandatory || 'Not specified'}
                        </li>
                    </ul>
                    <h5 className="mt-3">Optional Attachments</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            {settlementAgreementData.attachments.optional || 'Not specified'}
                        </li>
                    </ul>
                </>
            );
        } else if (draftTypeId === '4') {
            return (
                <>
                    <h2 className="text-center">Debt Recovery Suit Preview</h2>

                    {/* A. Basic Information */}
                    <h4 className="mt-4">1. Basic Information</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Court Name:</strong> {recoverySuitData.basicInfo.courtName}
                        </li>
                        {/* <li className="list-group-item">
                            <strong>Case Title:</strong> {recoverySuitData.basicInfo.caseTitle || 'Auto-generated'}
                        </li>
                        <li className="list-group-item">
                            <strong>Suit Number:</strong> {recoverySuitData.basicInfo.suitNumber || 'To be assigned by the court'}
                        </li> */}
                        <li className="list-group-item">
                            <strong>Filing Date:</strong> {recoverySuitData.basicInfo.filingDate}
                        </li>
                    </ul>

                    {/* B. Plaintiff’s Information */}
                    <h4 className="mt-4">2. Plaintiff’s Information</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Plaintiff Name:</strong> {recoverySuitData.plaintiffInfo.name}
                        </li>
                        <li className="list-group-item">
                            <strong>Plaintiff's Position:</strong> {recoverySuitData.plaintiffInfo.position || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Plaintiff Address:</strong> {recoverySuitData.plaintiffInfo.address}
                        </li>
                        <li className="list-group-item">
                            <strong>Plaintiff’s Legal Representative:</strong> {recoverySuitData.plaintiffInfo.legalRepresentative || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Company Authorization:</strong> {recoverySuitData.plaintiffInfo.companyNominationAttorney || 'Not provided'}
                        </li>
                    </ul>

                    {/* C. Defendant’s Information */}
                    <h4 className="mt-4">3. Defendant’s Information</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Defendant Name:</strong> {recoverySuitData.defendantInfo.name}
                        </li>
                        <li className="list-group-item">
                            <strong>Defendant's Business Information:</strong> {recoverySuitData.defendantInfo.businessInformation || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Defendant Address:</strong> {recoverySuitData.defendantInfo.address}
                        </li>
                        <li className="list-group-item">
                            <strong>Defendant's Legal Representative:</strong> {recoverySuitData.defendantInfo.legalRepresentative || 'Not specified'}
                        </li>
                    </ul>

                    {/* D. Debt and Transaction Details */}
                    <h4 className="mt-4">4. Debt and Transaction Details</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Nature of Business Relationship:</strong> {recoverySuitData.debtDetails.natureOfLoan}
                        </li>
                        <li className="list-group-item">
                            <strong>Details of Collateral Asset Provided:</strong> {recoverySuitData.debtDetails.assetOfferedAsCollateral || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Outstanding Amount:</strong>
                            {recoverySuitData.debtDetails.outstandingAmount
                                ? new Intl.NumberFormat('en-US').format(recoverySuitData.debtDetails.outstandingAmount)
                                : 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Default Since:</strong> {recoverySuitData.debtDetails.defaultSince}
                        </li>
                        {/* <li className="list-group-item">
                            <strong>Invoices:</strong> {recoverySuitData.debtDetails.invoices || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Cheques Issued:</strong> {recoverySuitData.debtDetails.chequesIssued || 'Not specified'}
                        </li> */}
                    </ul>

                    {/* E. Cause of Action */}
                    <h4 className="mt-4">5. Cause of Action</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Contractual Obligation or Breach:</strong> {recoverySuitData.causeOfAction.contractualObligation}
                        </li>
                        <li className="list-group-item">
                            <strong>Reason for Default:</strong> {recoverySuitData.causeOfAction.reasonForDefault || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Payment Follow-Up:</strong> {recoverySuitData.causeOfAction.paymentFollowUp || 'Not specified'}
                        </li>
                        {/* <li className="list-group-item">
                            <strong>Interest Clause:</strong> {recoverySuitData.causeOfAction.interestClause || 'Not specified'}
                        </li> */}
                    </ul>

                    {/* F. Supporting Documents */}
                    <h4 className="mt-4">6. Supporting Documents</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Contracts/Agreements:</strong> {recoverySuitData.supportingDocuments.contracts || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Invoices or Bills:</strong> {recoverySuitData.supportingDocuments.invoices || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Cheque Details:</strong> {recoverySuitData.supportingDocuments.chequeDetails || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Collateral Asset Detail:</strong> {recoverySuitData.supportingDocuments.collateralAssetDetail || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Acknowledgment of Debt:</strong> {recoverySuitData.supportingDocuments.anyOtherInfo || 'Not specified'}
                        </li>
                    </ul>

                    {/* G. Legal Notices */}
                    <h4 className="mt-4">7. Legal Notices</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Date of Legal Notice Sent:</strong> {recoverySuitData.legalNotices.noticeDate || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Defendant's Response to Notice:</strong> {recoverySuitData.legalNotices.defendantResponse || 'Not specified'}
                        </li>
                        {/* <li className="list-group-item">
                            <strong>Rejoinder to Response:</strong> {recoverySuitData.legalNotices.rejoinderToResponse || 'Not specified'}
                        </li> */}
                    </ul>

                    {/* H. Relief Sought */}
                    <h4 className="mt-4">8. Relief Sought</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Total Principal Amount:</strong> {recoverySuitData.reliefSought.totalPrincipalAmount
                                ? new Intl.NumberFormat('en-US').format(recoverySuitData.reliefSought.totalPrincipalAmount)
                                : 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Interest Calculation:</strong> {recoverySuitData.reliefSought.interestCalculation
                                ? new Intl.NumberFormat('en-US').format(recoverySuitData.reliefSought.interestCalculation)
                                : 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Legal Costs:</strong> {recoverySuitData.reliefSought.legalCosts
                                ? new Intl.NumberFormat('en-US').format(recoverySuitData.reliefSought.legalCosts)
                                : 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Penal Charges:</strong> {recoverySuitData.reliefSought.penalCharges
                                ? new Intl.NumberFormat('en-US').format(recoverySuitData.reliefSought.penalCharges)
                                : 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Penalties:</strong> {recoverySuitData.reliefSought.penalties || 'Not specified'}
                        </li>
                    </ul>

                    {/* I. Jurisdiction */}
                    <h4 className="mt-4">9. Jurisdiction</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Pecuniary Jurisdiction:</strong> {recoverySuitData.jurisdiction.pecuniaryJurisdiction}
                        </li>
                        <li className="list-group-item">
                            <strong>Territorial Jurisdiction:</strong> {recoverySuitData.jurisdiction.territorialJurisdiction}
                        </li>
                    </ul>

                    {/* J. Witness Details (if applicable) */}
                    {/* <h4 className="mt-4">10. Witness Details (if applicable)</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Witness 1 Name:</strong> {recoverySuitData.witnesses.witness1Name || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Witness 2 Name:</strong> {recoverySuitData.witnesses.witness2Name || 'Not specified'}
                        </li>
                    </ul> */}

                    {/* K. Lawyer’s Details */}
                    <h4 className="mt-4">11. Lawyer’s Details</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Plaintiff’s Advocate Name:</strong> {recoverySuitData.lawyerDetails.advocateName}
                        </li>
                        <li className="list-group-item">
                            <strong>License Number:</strong> {recoverySuitData.lawyerDetails.licenseNumber}
                        </li>
                        <li className="list-group-item">
                            <strong>Law Firm Name:</strong> {recoverySuitData.lawyerDetails.lawFirmName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Advocate Contact Information:</strong> {recoverySuitData.lawyerDetails.contactInformation}
                        </li>
                    </ul>

                    {/* L. Prayer */}
                    <h4 className="mt-4">12. Prayer</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Prayer for Decree:</strong> {recoverySuitData.prayer.prayerForDecree}
                        </li>
                        <li className="list-group-item">
                            <strong>Additional Relief:</strong> {recoverySuitData.prayer.additionalRelief || 'Not specified'}
                        </li>
                    </ul>
                </>
            );
        } else if (draftTypeId === '5') {
            return (
                <>
                    <h2 className="text-center">Non-Disclosure Agreement Preview</h2>

                    {/* A. Basic Information */}
                    <h4 className="mt-4">1. Title and Date</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Title:</strong> {nonDisclosureAgreementData.NDABasicDetail?.title || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Date:</strong> {nonDisclosureAgreementData.NDABasicDetail?.date || 'Not specified'}
                        </li>
                    </ul>

                    {/* B. Parties Involved */}
                    <h4 className="mt-4">2. Disclosing Party Information</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Disclosing Party Full Name:</strong> {nonDisclosureAgreementData.disclosingParty?.fullName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Disclosing Party Address:</strong> {nonDisclosureAgreementData.disclosingParty?.address || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Disclosing Party Contact Information:</strong> {nonDisclosureAgreementData.disclosingParty?.contact || 'Not specified'}
                        </li>
                    </ul>

                    <h4 className="mt-4">3. Receiving Party Information</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Receiving Party Full Name:</strong> {nonDisclosureAgreementData.receivingParty?.fullName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Receiving Party Address:</strong> {nonDisclosureAgreementData.receivingParty?.address || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Receiving Party Contact Information:</strong> {nonDisclosureAgreementData.receivingParty?.contact || 'Not specified'}
                        </li>
                    </ul>

                    {/* C. Purpose of Agreement */}
                    <h4 className="mt-4">4. Purpose of Agreement</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Purpose:</strong> {nonDisclosureAgreementData.NDADetail?.purpose || 'Not specified'}
                        </li>
                    </ul>

                    {/* D. Definition of Confidential Information */}
                    <h4 className="mt-4">5. Definition of Confidential Information</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Confidential Information:</strong> {nonDisclosureAgreementData.NDADetail?.confidentialInfo || 'Not specified'}
                        </li>
                    </ul>

                    {/* E. Obligations of the Receiving Party */}
                    <h4 className="mt-4">6. Obligations of the Receiving Party</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Obligations:</strong> {nonDisclosureAgreementData.NDADetail?.obligations || 'Not specified'}
                        </li>
                    </ul>

                    {/* F. Duration of Confidentiality */}
                    <h4 className="mt-4">7. Duration of Confidentiality</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Duration (e.g., number of years):</strong> {nonDisclosureAgreementData.NDADetail?.duration || 'Not specified'}
                        </li>
                    </ul>

                    {/* G. Return or Destruction of Information */}
                    <h4 className="mt-4">8. Return or Destruction of Information</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Return or Destruction:</strong> {nonDisclosureAgreementData.NDADetail?.returnordestructionInfo || 'Not specified'}
                        </li>
                    </ul>

                    {/* H. Remedies for Breach */}
                    <h4 className="mt-4">9. Remedies for Breach</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Remedies:</strong> {nonDisclosureAgreementData.NDADetail?.breach || 'Not specified'}
                        </li>
                    </ul>

                    {/* I. Governing Law */}
                    <h4 className="mt-4">10. Governing Law</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Governing Law:</strong> {nonDisclosureAgreementData.NDADetail?.governingLaw || 'Not specified'}
                        </li>
                    </ul>
                </>
            );
        } else if (draftTypeId === '6') {
            return (
                <>
                    <h2 className="text-center">Sales Agreement Preview</h2>

                    {/* 1. Parties Involved */}
                    <h4 className="mt-4">1. Parties Involved</h4>
                    <h5>Seller's Information</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Full Name or Business Name:</strong> {salesAgreementData.seller?.fullName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>CNIC/Company Registration Number:</strong> {salesAgreementData.seller?.cnic || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Address:</strong> {salesAgreementData.seller?.address || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Contact Information:</strong> {salesAgreementData.seller?.contact || 'Not specified'}
                        </li>
                    </ul>

                    <h5 className="mt-3">Buyer's Information</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Full Name or Business Name:</strong> {salesAgreementData.buyer?.fullName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>CNIC/Company Registration Number:</strong> {salesAgreementData.buyer?.cnic || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Address:</strong> {salesAgreementData.buyer?.address || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Contact Information:</strong> {salesAgreementData.buyer?.contact || 'Not specified'}
                        </li>
                    </ul>

                    {/* 2. Sale Subject */}
                    <h4 className="mt-4">2. Sale Subject</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Description of Goods:</strong> {salesAgreementData.saleSubject?.description || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Condition of Goods:</strong> {salesAgreementData.saleSubject?.condition || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Ownership Confirmation:</strong> {salesAgreementData.saleSubject?.ownershipConfirmation ? 'Confirmed' : 'Not confirmed'}
                        </li>
                    </ul>

                    {/* 3. Sale Price and Payment Terms */}
                    <h4 className="mt-4">3. Sale Price and Payment Terms</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Total Sale Price (PKR):</strong> {salesAgreementData.paymentTerms?.totalPrice || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Payment Method:</strong> {salesAgreementData.paymentTerms?.paymentMethod || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Payment Schedule:</strong> {salesAgreementData.paymentTerms?.paymentSchedule || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Deposit or Earnest Money:</strong> {salesAgreementData.paymentTerms?.deposit || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Deposit Payment Date:</strong> {salesAgreementData.paymentTerms?.depositDate || 'Not specified'}
                        </li>
                    </ul>

                    {/* 4. Transfer of Ownership and Delivery */}
                    <h4 className="mt-4">4. Transfer of Ownership and Delivery</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Ownership Transfer Date:</strong> {salesAgreementData.transfer?.ownershipTransferDate || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Delivery Date:</strong> {salesAgreementData.transfer?.deliveryDate || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Delivery Method:</strong> {salesAgreementData.transfer?.deliveryMethod || 'Not specified'}
                        </li>
                    </ul>

                    {/* 5. Warranties and Representations */}
                    <h4 className="mt-4">5. Warranties and Representations</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Seller's Warranties:</strong> {salesAgreementData.warranties?.sellerWarranties || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Buyer's Representations:</strong> {salesAgreementData.warranties?.buyerRepresentations || 'Not specified'}
                        </li>
                    </ul>

                    {/* 6. Dispute Resolution */}
                    <h4 className="mt-4">6. Dispute Resolution</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Governing Law:</strong> {salesAgreementData.disputeResolution?.governingLaw || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Dispute Resolution Method:</strong> {salesAgreementData.disputeResolution?.disputeMethod || 'Not specified'}
                        </li>
                    </ul>

                    {/* 7. Default and Penalties */}
                    <h4 className="mt-4">7. Default and Penalties</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Consequences of Default:</strong> {salesAgreementData.defaultPenalties?.consequences || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Late Payment Penalties:</strong> {salesAgreementData.defaultPenalties?.latePayment || 'Not specified'}
                        </li>
                    </ul>

                    {/* 8. Miscellaneous Clauses */}
                    <h4 className="mt-4">8. Miscellaneous Clauses</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Indemnity Clause:</strong> {salesAgreementData.miscellaneous?.indemnity ? 'Included' : 'Not included'}
                        </li>
                        <li className="list-group-item">
                            <strong>Force Majeure Clause:</strong> {salesAgreementData.miscellaneous?.forceMajeure ? 'Included' : 'Not included'}
                        </li>
                        <li className="list-group-item">
                            <strong>Confidentiality Clause:</strong> {salesAgreementData.miscellaneous?.confidentiality ? 'Included' : 'Not included'}
                        </li>
                    </ul>

                    {/* 9. Signatures and Witnesses */}
                    <h4 className="mt-4">9. Signatures and Witnesses</h4>
                    <h5>Seller's Signature</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Name:</strong> {salesAgreementData.signatures?.sellerName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Date:</strong> {salesAgreementData.signatures?.sellerDate || 'Not specified'}
                        </li>
                    </ul>

                    <h5 className="mt-3">Buyer's Signature</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Name:</strong> {salesAgreementData.signatures?.buyerName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Date:</strong> {salesAgreementData.signatures?.buyerDate || 'Not specified'}
                        </li>
                    </ul>

                    <h5 className="mt-3">Witness 1</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Name:</strong> {salesAgreementData.signatures?.witness1Name || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Address:</strong> {salesAgreementData.signatures?.witness1Address || 'Not specified'}
                        </li>
                    </ul>

                    <h5 className="mt-3">Witness 2</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Name:</strong> {salesAgreementData.signatures?.witness2Name || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Address:</strong> {salesAgreementData.signatures?.witness2Address || 'Not specified'}
                        </li>
                    </ul>
                </>
            );
        } else if (draftTypeId === '7') {
            return (
                <div className="mb-2">
                    <h2 className="text-center">Service Agreement Preview</h2>

                    {/* 1. Type of Service Agreement */}
                    <h4 className="mt-4">1. Type of Service Agreement</h4>
                    <p><strong>Service Type:</strong> {serviceAgreementData.serviceType || 'Not specified'}</p>

                    {/* 2. Parties Involved */}
                    <h4 className="mt-4">2. Parties Involved</h4>
                    <h5>Service Provider Information</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Full Name or Business Name:</strong> {serviceAgreementData.serviceProvider?.fullName || 'Not specified'}</li>
                        <li className="list-group-item"><strong>CNIC/Company Registration Number:</strong> {serviceAgreementData.serviceProvider?.cnic || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Address:</strong> {serviceAgreementData.serviceProvider?.address || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Contact Information:</strong> {serviceAgreementData.serviceProvider?.contact || 'Not specified'}</li>
                    </ul>

                    <h5 className="mt-3">Client Information</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Full Name or Business Name:</strong> {serviceAgreementData.client?.fullName || 'Not specified'}</li>
                        <li className="list-group-item"><strong>CNIC/Company Registration Number:</strong> {serviceAgreementData.client?.cnic || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Address:</strong> {serviceAgreementData.client?.address || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Contact Information:</strong> {serviceAgreementData.client?.contact || 'Not specified'}</li>
                    </ul>

                    {/* 3. Scope of Services */}
                    <h4 className="mt-4">3. Scope of Services</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Description of Services:</strong> {serviceAgreementData.scopeOfServices?.description || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Service Location:</strong> {serviceAgreementData.scopeOfServices?.serviceLocation || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Performance Standards:</strong> {serviceAgreementData.scopeOfServices?.performanceStandards || 'Not specified'}</li>
                    </ul>

                    {/* 4. Duration and Timeline */}
                    <h4 className="mt-4">4. Duration and Timeline</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Start Date:</strong> {serviceAgreementData.duration?.startDate || 'Not specified'}</li>
                        <li className="list-group-item"><strong>End Date:</strong> {serviceAgreementData.duration?.endDate || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Key Milestones/Deadlines:</strong> {serviceAgreementData.duration?.milestones || 'Not specified'}</li>
                    </ul>

                    {/* 5. Payment Terms */}
                    <h4 className="mt-4">5. Payment Terms</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Service Fee:</strong> {serviceAgreementData.paymentTerms?.serviceFee || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Payment Schedule:</strong> {serviceAgreementData.paymentTerms?.paymentSchedule || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Payment Method:</strong> {serviceAgreementData.paymentTerms?.paymentMethod || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Late Payment Penalties:</strong> {serviceAgreementData.paymentTerms?.latePaymentPenalties || 'Not specified'}</li>
                    </ul>

                    {/* 6. Specific Terms for Selected Service Type */}
                    {serviceAgreementData.serviceType === 'Consulting' && (
                        <>
                            <h4 className="mt-4">6. Consulting Specific Terms</h4>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><strong>Consulting Deliverables:</strong> {serviceAgreementData.consulting?.deliverables || 'Not specified'}</li>
                                <li className="list-group-item"><strong>Performance Review Milestones:</strong> {serviceAgreementData.consulting?.performanceReviewMilestones || 'Not specified'}</li>
                                <li className="list-group-item"><strong>IP Ownership:</strong> {serviceAgreementData.consulting?.ipOwnership || 'Not specified'}</li>
                            </ul>
                        </>
                    )}

                    {serviceAgreementData.serviceType === 'Contracting' && (
                        <>
                            <h4 className="mt-4">6. Contracting Specific Terms</h4>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><strong>Material or Equipment Responsibilities:</strong> {serviceAgreementData.contracting?.materialsResponsibility || 'Not specified'}</li>
                                <li className="list-group-item"><strong>Site Information:</strong> {serviceAgreementData.contracting?.siteInformation || 'Not specified'}</li>
                                <li className="list-group-item"><strong>Insurance and Liability Clauses:</strong> {serviceAgreementData.contracting?.insuranceLiability || 'Not specified'}</li>
                                <li className="list-group-item"><strong>Compliance with Local Regulations:</strong> {serviceAgreementData.contracting?.compliance || 'Not specified'}</li>
                            </ul>
                        </>
                    )}

                    {serviceAgreementData.serviceType === 'Freelancing' && (
                        <>
                            <h4 className="mt-4">6. Freelancing Specific Terms</h4>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><strong>Project-Based Payments:</strong> {serviceAgreementData.freelancing?.projectPayments || 'Not specified'}</li>
                                <li className="list-group-item"><strong>Ownership of Work:</strong> {serviceAgreementData.freelancing?.ownershipOfWork || 'Not specified'}</li>
                                <li className="list-group-item"><strong>Revisions and Edits:</strong> {serviceAgreementData.freelancing?.revisions || 'Not specified'}</li>
                            </ul>
                        </>
                    )}

                    {serviceAgreementData.serviceType === 'General Services' && (
                        <>
                            <h4 className="mt-4">6. General Services Specific Terms</h4>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><strong>Service Location:</strong> {serviceAgreementData.generalServices?.serviceLocation || 'Not specified'}</li>
                                <li className="list-group-item"><strong>Supplies and Materials:</strong> {serviceAgreementData.generalServices?.suppliesMaterials || 'Not specified'}</li>
                            </ul>
                        </>
                    )}

                    {serviceAgreementData.serviceType === 'IT/Software' && (
                        <>
                            <h4 className="mt-4">6. IT/Software Specific Terms</h4>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><strong>Service Level Agreement (SLA):</strong> {serviceAgreementData.itSoftware?.sla || 'Not specified'}</li>
                                <li className="list-group-item"><strong>Maintenance and Updates:</strong> {serviceAgreementData.itSoftware?.maintenanceUpdates || 'Not specified'}</li>
                                <li className="list-group-item"><strong>Intellectual Property:</strong> {serviceAgreementData.itSoftware?.intellectualProperty || 'Not specified'}</li>
                            </ul>
                        </>
                    )}

                    {serviceAgreementData.serviceType === 'Marketing/Advertising' && (
                        <>
                            <h4 className="mt-4">6. Marketing/Advertising Specific Terms</h4>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><strong>Campaign Details:</strong> {serviceAgreementData.marketing?.campaignDetails || 'Not specified'}</li>
                                <li className="list-group-item"><strong>Target Goals/Performance Metrics:</strong> {serviceAgreementData.marketing?.performanceMetrics || 'Not specified'}</li>
                                <li className="list-group-item"><strong>Content Ownership:</strong> {serviceAgreementData.marketing?.contentOwnership || 'Not specified'}</li>
                            </ul>
                        </>
                    )}

                    {/* 7. Termination of Agreement */}
                    <h4 className="mt-4">7. Termination of Agreement</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Termination Conditions:</strong> {serviceAgreementData.termination?.conditions || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Termination Notice Period:</strong> {serviceAgreementData.termination?.noticePeriod || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Refund or Payment on Termination:</strong> {serviceAgreementData.termination?.refundPayment || 'Not specified'}</li>
                    </ul>

                    {/* 8. Dispute Resolution */}
                    <h4 className="mt-4">8. Dispute Resolution</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Governing Law:</strong> {serviceAgreementData.disputeResolution?.governingLaw || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Dispute Resolution Method:</strong> {serviceAgreementData.disputeResolution?.disputeMethod || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Jurisdiction:</strong> {serviceAgreementData.disputeResolution?.jurisdiction || 'Not specified'}</li>
                    </ul>

                    {/* 9. Liability and Indemnification */}
                    <h4 className="mt-4">9. Liability and Indemnification</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Limitation of Liability:</strong> {serviceAgreementData.liability?.limitation || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Indemnification Clause:</strong> {serviceAgreementData.liability?.indemnification || 'Not specified'}</li>
                    </ul>

                    {/* 10. Force Majeure Clause */}
                    <h4 className="mt-4">10. Force Majeure Clause</h4>
                    <p>{serviceAgreementData.forceMajeure?.events || 'Not specified'}</p>

                    {/* 11. Signatures and Witnesses */}
                    <h4 className="mt-4">11. Signatures and Witnesses</h4>
                    <h5>Service Provider's Signature</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Name:</strong> {serviceAgreementData.signatures?.serviceProviderName || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Date:</strong> {serviceAgreementData.signatures?.serviceProviderDate || 'Not specified'}</li>
                    </ul>

                    <h5 className="mt-3">Client's Signature</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Name:</strong> {serviceAgreementData.signatures?.clientName || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Date:</strong> {serviceAgreementData.signatures?.clientDate || 'Not specified'}</li>
                    </ul>

                    <h5 className="mt-3">Witness 1</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Name:</strong> {serviceAgreementData.signatures?.witness1Name || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Address:</strong> {serviceAgreementData.signatures?.witness1Address || 'Not specified'}</li>
                    </ul>

                    <h5 className="mt-3">Witness 2</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Name:</strong> {serviceAgreementData.signatures?.witness2Name || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Address:</strong> {serviceAgreementData.signatures?.witness2Address || 'Not specified'}</li>
                    </ul>
                </div>
            );
        } else if (draftTypeId === '8') {
            return (
                <div className="mb-2">
                    <h2 className="text-center">Employment Contract Preview</h2>

                    {/* 1. Parties Information */}
                    <h4 className="mt-4">1. Parties Information</h4>
                    <h5>Employer Information</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Company Name:</strong> {employmentContractData.employer?.companyName || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Registered Address:</strong> {employmentContractData.employer?.registeredAddress || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Company Registration Number / NTN:</strong> {employmentContractData.employer?.registrationNumber || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Email:</strong> {employmentContractData.employer?.email || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Phone:</strong> {employmentContractData.employer?.phone || 'Not specified'}</li>
                    </ul>

                    <h5 className="mt-3">Employee Information</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Full Name:</strong> {employmentContractData.employee?.fullName || 'Not specified'}</li>
                        <li className="list-group-item"><strong>CNIC Number:</strong> {employmentContractData.employee?.cnic || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Residential Address:</strong> {employmentContractData.employee?.address || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Email:</strong> {employmentContractData.employee?.email || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Phone:</strong> {employmentContractData.employee?.phone || 'Not specified'}</li>
                    </ul>

                    {/* 2. Job Details */}
                    <h4 className="mt-4">2. Job Details</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Job Title:</strong> {employmentContractData.jobDetails?.jobTitle || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Job Description:</strong> {employmentContractData.jobDetails?.jobDescription || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Department:</strong> {employmentContractData.jobDetails?.department || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Place of Work:</strong> {employmentContractData.jobDetails?.placeOfWork || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Reporting Manager/Supervisor:</strong> {employmentContractData.jobDetails?.reportingManager || 'Not specified'}</li>
                    </ul>

                    {/* 3. Employment Terms */}
                    <h4 className="mt-4">3. Employment Terms</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Start Date:</strong> {employmentContractData.employmentTerms?.startDate || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Type of Employment:</strong> {employmentContractData.employmentTerms?.employmentType || 'Not specified'}</li>
                        {employmentContractData.employmentTerms?.employmentType === 'Probationary' && (
                            <>
                                <li className="list-group-item"><strong>Probation Period Duration:</strong> {employmentContractData.employmentTerms?.probationPeriod || 'Not specified'}</li>
                                <li className="list-group-item"><strong>Conditions for Confirmation:</strong> {employmentContractData.employmentTerms?.confirmationConditions || 'Not specified'}</li>
                            </>
                        )}
                    </ul>

                    {/* 4. Working Hours */}
                    <h4 className="mt-4">4. Working Hours</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Working Days per Week:</strong> {employmentContractData.workingHours?.workingDays || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Daily Working Hours:</strong> {employmentContractData.workingHours?.dailyHours || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Total Weekly Hours:</strong> {employmentContractData.workingHours?.weeklyHours || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Overtime Eligibility:</strong> {employmentContractData.workingHours?.overtimeEligibility || 'Not specified'}</li>
                        {employmentContractData.workingHours?.overtimeEligibility === 'Yes' && (
                            <>
                                <li className="list-group-item"><strong>Overtime Rate:</strong> {employmentContractData.workingHours?.overtimeRate || 'Not specified'}</li>
                                <li className="list-group-item"><strong>Maximum Overtime Hours:</strong> {employmentContractData.workingHours?.maxOvertimeHours || 'Not specified'}</li>
                            </>
                        )}
                    </ul>

                    {/* 5. Remuneration */}
                    <h4 className="mt-4">5. Remuneration</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Basic Salary:</strong> {employmentContractData.remuneration?.basicSalary || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Currency:</strong> {employmentContractData.remuneration?.currency || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Payment Frequency:</strong> {employmentContractData.remuneration?.paymentFrequency || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Payment Method:</strong> {employmentContractData.remuneration?.paymentMethod || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Allowances and Benefits:</strong> {employmentContractData.remuneration?.allowances || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Bonuses:</strong> {employmentContractData.remuneration?.bonuses || 'Not specified'}</li>
                    </ul>

                    {/* 6. Leave Entitlements */}
                    <h4 className="mt-4">6. Leave Entitlements</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Annual Leave Days Per Year:</strong> {employmentContractData.leaveEntitlements?.annualLeaveDays || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Sick Leave Days Per Year:</strong> {employmentContractData.leaveEntitlements?.sickLeaveDays || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Casual Leave Days Per Year:</strong> {employmentContractData.leaveEntitlements?.casualLeaveDays || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Additional Leave Details:</strong> {employmentContractData.leaveEntitlements?.additionalLeaves || 'Not specified'}</li>
                    </ul>

                    {/* 7. Benefits and Allowances */}
                    <h4 className="mt-4">7. Benefits and Allowances</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Health Insurance Details:</strong> {employmentContractData.benefits?.healthInsurance || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Retirement Benefits:</strong> {employmentContractData.benefits?.retirementBenefits || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Other Benefits:</strong> {employmentContractData.benefits?.otherBenefits || 'Not specified'}</li>
                    </ul>

                    {/* 8. Confidentiality and Restrictive Covenants */}
                    <h4 className="mt-4">8. Confidentiality and Restrictive Covenants</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Confidentiality Clause:</strong> {employmentContractData.confidentiality?.clause || 'Not specified'}</li>
                        {employmentContractData.confidentiality?.clause === 'Yes' && (
                            <li className="list-group-item"><strong>Confidentiality Details:</strong> {employmentContractData.confidentiality?.details || 'Not specified'}</li>
                        )}
                        <li className="list-group-item"><strong>Non-Compete Clause:</strong> {employmentContractData.nonCompete?.clause || 'Not specified'}</li>
                        {employmentContractData.nonCompete?.clause === 'Yes' && (
                            <>
                                <li className="list-group-item"><strong>Duration:</strong> {employmentContractData.nonCompete?.duration || 'Not specified'}</li>
                                <li className="list-group-item"><strong>Geographical Scope:</strong> {employmentContractData.nonCompete?.geographicalScope || 'Not specified'}</li>
                            </>
                        )}
                        <li className="list-group-item"><strong>Non-Solicitation Clause:</strong> {employmentContractData.nonSolicitation?.clause || 'Not specified'}</li>
                        {employmentContractData.nonSolicitation?.clause === 'Yes' && (
                            <li className="list-group-item"><strong>Non-Solicitation Details:</strong> {employmentContractData.nonSolicitation?.details || 'Not specified'}</li>
                        )}
                    </ul>

                    {/* 9. Termination Conditions */}
                    <h4 className="mt-4">9. Termination Conditions</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Notice Period by Employer:</strong> {employmentContractData.termination?.noticePeriodEmployer || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Notice Period by Employee:</strong> {employmentContractData.termination?.noticePeriodEmployee || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Grounds for Termination:</strong> {employmentContractData.termination?.grounds || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Severance Pay Details:</strong> {employmentContractData.termination?.severancePay || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Retirement Age:</strong> {employmentContractData.termination?.retirementAge || 'Not specified'}</li>
                    </ul>

                    {/* 10. Dispute Resolution */}
                    <h4 className="mt-4">10. Dispute Resolution</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Governing Law:</strong> {employmentContractData.disputeResolution?.governingLaw || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Jurisdiction:</strong> {employmentContractData.disputeResolution?.jurisdiction || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Dispute Resolution Method:</strong> {employmentContractData.disputeResolution?.disputeMethod || 'Not specified'}</li>
                    </ul>

                    {/* 11. Other Terms */}
                    <h4 className="mt-4">11. Other Terms</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Code of Conduct:</strong> {employmentContractData.otherTerms?.codeOfConduct || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Employee Handbook Acknowledgment:</strong> {employmentContractData.otherTerms?.employeeHandbook || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Amendments to Contract Procedure:</strong> {employmentContractData.otherTerms?.amendments || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Entire Agreement Clause:</strong> {employmentContractData.otherTerms?.entireAgreement || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Severability Clause:</strong> {employmentContractData.otherTerms?.severability || 'Not specified'}</li>
                    </ul>

                    {/* 12. Signatures and Witnesses */}
                    <h4 className="mt-4">12. Signatures and Witnesses</h4>
                    <h5>Employer's Authorized Representative</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Name:</strong> {employmentContractData.signatures?.employerName || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Designation:</strong> {employmentContractData.signatures?.employerDesignation || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Signature:</strong> {employmentContractData.signatures?.employerSignature || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Date:</strong> {employmentContractData.signatures?.employerDate || 'Not specified'}</li>
                    </ul>

                    <h5 className="mt-3">Employee</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Name:</strong> {employmentContractData.signatures?.employeeName || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Signature:</strong> {employmentContractData.signatures?.employeeSignature || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Date:</strong> {employmentContractData.signatures?.employeeDate || 'Not specified'}</li>
                    </ul>

                    <h5 className="mt-3">Witness 1</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Name:</strong> {employmentContractData.signatures?.witness1Name || 'Not specified'}</li>
                        <li className="list-group-item"><strong>CNIC Number:</strong> {employmentContractData.signatures?.witness1Cnic || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Date:</strong> {employmentContractData.signatures?.witness1Date || 'Not specified'}</li>
                    </ul>

                    <h5 className="mt-3">Witness 2</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><strong>Name:</strong> {employmentContractData.signatures?.witness2Name || 'Not specified'}</li>
                        <li className="list-group-item"><strong>CNIC Number:</strong> {employmentContractData.signatures?.witness2Cnic || 'Not specified'}</li>
                        <li className="list-group-item"><strong>Date:</strong> {employmentContractData.signatures?.witness2Date || 'Not specified'}</li>
                    </ul>
                </div>
            );
        } else if (draftTypeId === '9') {
            return (
                <div className="mb-2">
                    <h2 className="text-center">Legal Appeal Preview</h2>

                    {/* 1. Case Information */}
                    <h4 className="mt-4">1. Case Information</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Case Title:</strong> {legalAppealData.caseInformation?.caseTitle || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Case Number:</strong> {legalAppealData.caseInformation?.caseNumber || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Trial Court:</strong> {legalAppealData.caseInformation?.trialCourt || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Trial Court Judgment Date:</strong>{' '}
                            {formatDate(legalAppealData.caseInformation?.trialCourtJudgmentDate)}
                        </li>
                        <li className="list-group-item">
                            <strong>Appellate Court:</strong> {legalAppealData.caseInformation?.appellateCourt || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Jurisdiction:</strong> {legalAppealData.caseInformation?.jurisdiction || 'Not specified'}
                        </li>
                    </ul>

                    {/* 2. Grounds for Appeal */}
                    <h4 className="mt-4">2. Grounds for Appeal</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Type of Appeal:</strong>{' '}
                            {legalAppealData.groundsForAppeal?.typeOfAppeal === 'Other'
                                ? legalAppealData.groundsForAppeal?.otherAppealType || 'Not specified'
                                : legalAppealData.groundsForAppeal?.typeOfAppeal || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Legal Grounds for Appeal:</strong>{' '}
                            {legalAppealData.groundsForAppeal?.legalGrounds?.length > 0
                                ? legalAppealData.groundsForAppeal?.legalGrounds.join(', ')
                                : 'Not specified'}
                        </li>
                        {legalAppealData.groundsForAppeal?.legalGrounds?.includes('Other') && (
                            <li className="list-group-item">
                                <strong>Other Legal Grounds:</strong>{' '}
                                {legalAppealData.groundsForAppeal?.otherLegalGrounds || 'Not specified'}
                            </li>
                        )}
                    </ul>

                    {/* 3. Appeal Issues */}
                    <h4 className="mt-4">3. Appeal Issues</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Issues to be Appealed:</strong>{' '}
                            {legalAppealData.appealIssues?.issuesToBeAppealed || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Trial Record References:</strong>{' '}
                            {legalAppealData.appealIssues?.trialRecordReferences || 'Not specified'}
                        </li>
                    </ul>

                    {/* 4. Desired Outcome */}
                    <h4 className="mt-4">4. Desired Outcome</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Relief Sought:</strong>{' '}
                            {legalAppealData.desiredOutcome?.reliefSought?.length > 0
                                ? legalAppealData.desiredOutcome?.reliefSought.join(', ')
                                : 'Not specified'}
                        </li>
                        {legalAppealData.desiredOutcome?.reliefSought?.includes('Other') && (
                            <li className="list-group-item">
                                <strong>Other Relief Sought:</strong>{' '}
                                {legalAppealData.desiredOutcome?.otherReliefSought || 'Not specified'}
                            </li>
                        )}
                    </ul>

                    {/* 5. Applicable Law and Legal Authority */}
                    <h4 className="mt-4">5. Applicable Law and Legal Authority</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Relevant Statutes:</strong>{' '}
                            {legalAppealData.applicableLaw?.relevantStatutes || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Relevant Case Law:</strong>{' '}
                            {legalAppealData.applicableLaw?.relevantCaseLaw || 'Not specified'}
                        </li>
                    </ul>

                    {/* 6. Supporting Documents */}
                    {/* <h4 className="mt-4">6. Supporting Documents</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Trial Court Judgment:</strong>{' '}
                            {getFileName(legalAppealData.supportingDocuments?.trialCourtJudgment)}
                        </li>
                        <li className="list-group-item">
                            <strong>Notice of Appeal:</strong>{' '}
                            {getFileName(legalAppealData.supportingDocuments?.noticeOfAppeal)}
                        </li>
                        <li className="list-group-item">
                            <strong>Trial Record/Transcripts:</strong>{' '}
                            {getFileName(legalAppealData.supportingDocuments?.trialRecord)}
                        </li>
                        <li className="list-group-item">
                            <strong>New Evidence:</strong>{' '}
                            {getFileName(legalAppealData.supportingDocuments?.newEvidence)}
                        </li>
                        <li className="list-group-item">
                            <strong>Additional Supporting Documents:</strong>{' '}
                            {getFileName(legalAppealData.supportingDocuments?.additionalDocuments)}
                        </li>
                    </ul> */}

                    {/* 7. Procedural Information */}
                    <h4 className="mt-4">7. Procedural Information</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Date of Filing Notice of Appeal:</strong>{' '}
                            {formatDate(legalAppealData.proceduralInformation?.dateOfFilingNotice)}
                        </li>
                        <li className="list-group-item">
                            <strong>Deadline for Filing Appeal:</strong>{' '}
                            {formatDate(legalAppealData.proceduralInformation?.deadlineForFilingAppeal)}
                        </li>
                        <li className="list-group-item">
                            <strong>Any Previous Extensions:</strong>{' '}
                            {legalAppealData.proceduralInformation?.previousExtensions || 'Not specified'}
                        </li>
                        {legalAppealData.proceduralInformation?.previousExtensions === 'Yes' && (
                            <li className="list-group-item">
                                <strong>Extension Details:</strong>{' '}
                                {legalAppealData.proceduralInformation?.extensionDetails || 'Not specified'}
                            </li>
                        )}
                    </ul>

                    {/* 8. Representation Information */}
                    <h4 className="mt-4">8. Representation Information</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Appellant’s Lawyer Name:</strong>{' '}
                            {legalAppealData.representation?.lawyerName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Law Firm Name:</strong> {legalAppealData.representation?.lawFirmName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Lawyer’s Email:</strong> {legalAppealData.representation?.email || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Lawyer’s Phone:</strong> {legalAppealData.representation?.phone || 'Not specified'}
                        </li>
                    </ul>

                    {/* 9. Appellant Information */}
                    <h4 className="mt-4">9. Appellant Information</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Appellant's Full Name:</strong> {legalAppealData.appellant?.fullName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Appellant’s CNIC:</strong> {legalAppealData.appellant?.cnic || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Appellant's Address:</strong> {legalAppealData.appellant?.address || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Appellant’s Email:</strong> {legalAppealData.appellant?.email || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Appellant’s Phone:</strong> {legalAppealData.appellant?.phone || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Type of Appellant:</strong> {legalAppealData.appellant?.type || 'Not specified'}
                        </li>
                    </ul>

                    {/* 10. Respondent Information */}
                    <h4 className="mt-4">10. Respondent Information</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Respondent’s Full Name:</strong> {legalAppealData.respondent?.fullName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Respondent’s CNIC:</strong> {legalAppealData.respondent?.cnic || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Respondent’s Address:</strong> {legalAppealData.respondent?.address || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Respondent’s Email:</strong> {legalAppealData.respondent?.email || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Respondent’s Phone:</strong> {legalAppealData.respondent?.phone || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Type of Respondent:</strong> {legalAppealData.respondent?.type || 'Not specified'}
                        </li>
                    </ul>

                    {/* 11. Jurisdiction and Governing Law */}
                    <h4 className="mt-4">11. Jurisdiction and Governing Law</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Governing Law:</strong>{' '}
                            {legalAppealData.jurisdictionLaw?.governingLaw?.length > 0
                                ? legalAppealData.jurisdictionLaw?.governingLaw.join(', ')
                                : 'Not specified'}
                        </li>
                        {legalAppealData.jurisdictionLaw?.governingLaw?.includes('Other') && (
                            <li className="list-group-item">
                                <strong>Other Governing Law:</strong> {legalAppealData.jurisdictionLaw?.otherGoverningLaw || 'Not specified'}
                            </li>
                        )}
                        <li className="list-group-item">
                            <strong>Jurisdiction:</strong>{' '}
                            {legalAppealData.jurisdictionLaw?.jurisdiction === 'Other'
                                ? legalAppealData.jurisdictionLaw?.otherJurisdiction || 'Not specified'
                                : legalAppealData.jurisdictionLaw?.jurisdiction || 'Not specified'}
                        </li>
                    </ul>

                    {/* 12. Signature Fields */}
                    <h4 className="mt-4">12. Signature Fields</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Appellant's Lawyer’s Signature:</strong>{' '}
                            {legalAppealData.signatures?.lawyerSignature ? 'Signed' : 'Not signed'}
                        </li>
                        <li className="list-group-item">
                            <strong>Appellant's Signature:</strong>{' '}
                            {legalAppealData.signatures?.appellantSignature ? 'Signed' : 'Not signed'}
                        </li>
                    </ul>

                    {/* 13. Additional Notes or Comments */}
                    <h4 className="mt-4">13. Additional Notes or Comments</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            {legalAppealData.additionalNotes?.notes || 'No additional notes provided.'}
                        </li>
                    </ul>

                    {/* 14. Submission Confirmation */}
                    <h4 className="mt-4">14. Submission Confirmation</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Confirmation:</strong>{' '}
                            {legalAppealData.confirmation?.confirmation
                                ? 'The appellant confirms that the information provided is accurate and complete.'
                                : 'Not confirmed'}
                        </li>
                    </ul>
                </div>
            );
        } else if (draftTypeId === '10') {
            return (
                // <div className="mb-2">
                //     <h2 className="text-center">Case Preview</h2>
                //     <div className="form-group">
                //         <label>Extracted Text:</label>
                //         <textarea
                //             className="form-control"
                //             value={caseSummarizationData.jugmenttextContent || caseSummarizationData.jugmentpdfContent || ''}
                //             readOnly
                //             rows={15}
                //         ></textarea>
                //     </div>
                // </div>
                <div className="mt-4" style={{ fontFamily: 'Arial, sans-serif' }}>
                    <h2 className="text-center" style={{ fontWeight: 'bold', fontSize: '1.8rem', marginBottom: '0.5rem' }}>Case Summarization (Preview)</h2>
                    <p className="text-center" style={{ fontSize: '1rem', marginBottom: '1rem' }}>
                        Click the <strong>'Confirm & Generate'</strong> button below to create the case summary instantly.
                    </p>
                    <div className="form-group" style={{ marginBottom: '1.5rem' }}>
                        <label style={{ fontSize: '1rem', fontWeight: '500', marginBottom: '0.5rem' }}>Case Content:</label>
                        <textarea
                            className="form-control"
                            value={caseSummarizationData.jugmenttextContent || caseSummarizationData.jugmentpdfContent || ''}
                            readOnly
                            rows={15}
                            style={{
                                width: '100%',
                                padding: '1rem',
                                fontSize: '1rem',
                                borderRadius: '8px',
                                border: '1px solid #ccc',
                                backgroundColor: '#f9f9f9',
                                resize: 'none',
                            }}
                        ></textarea>
                    </div>
                </div>
            );
        } else if (draftTypeId === '11') {
            return (
                <>
                    <h2 className="text-center">Legal Notice Preview</h2>

                    {/* Debtor Information */}
                    <h4 className="mt-4">1. Debtor Information</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Company Name:</strong> {legalNoticeData.debtorInfo?.companyName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Chief Executive's Name:</strong> {legalNoticeData.debtorInfo?.chiefExecutiveName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Address:</strong> {legalNoticeData.debtorInfo?.address || 'Not specified'}
                        </li>
                    </ul>

                    {/* Creditor Information */}
                    <h4 className="mt-4">2. Creditor (Bank) Information</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Bank Name:</strong> {legalNoticeData.creditorInfo?.bankName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Head Office Address:</strong> {legalNoticeData.creditorInfo?.headOfficeAddress || 'Not specified'}
                        </li>
                    </ul>

                    {/* Outstanding Amount Details */}
                    <h4 className="mt-4">3. Outstanding Amount Details</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Total Principal Amount:</strong>
                            {legalNoticeData.outstandingDetails?.totalPrincipal
                                ? new Intl.NumberFormat('en-US').format(legalNoticeData.outstandingDetails.totalPrincipal)
                                : 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Total Mark-up:</strong>
                            {legalNoticeData.outstandingDetails?.totalMarkup
                                ? new Intl.NumberFormat('en-US').format(legalNoticeData.outstandingDetails.totalMarkup)
                                : 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Penal Charges:</strong>
                            {legalNoticeData.outstandingDetails?.penalCharges
                                ? new Intl.NumberFormat('en-US').format(legalNoticeData.outstandingDetails.penalCharges)
                                : 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Amount Paid:</strong>
                            {legalNoticeData.outstandingDetails?.amountPaid
                                ? new Intl.NumberFormat('en-US').format(legalNoticeData.outstandingDetails.amountPaid)
                                : 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Aggregated Amount:</strong>
                            {legalNoticeData.outstandingDetails?.aggregatedAmount
                                ? new Intl.NumberFormat('en-US').format(legalNoticeData.outstandingDetails.aggregatedAmount)
                                : 'Not specified'}
                        </li>
                    </ul>

                    {/* Dates and Deadlines */}
                    <h4 className="mt-4">4. Dates and Deadlines</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Notice Date:</strong> {legalNoticeData.datesAndDeadlines?.noticeDate || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Over Due Date:</strong> {legalNoticeData.datesAndDeadlines?.overDueDate || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Payment Deadline Date:</strong> {legalNoticeData.datesAndDeadlines?.paymentDeadlineDate || 'Not specified'}
                        </li>
                    </ul>
                </>
            );

        } else if (draftTypeId === '12') {
            return (
                <>
                    <h2 className="text-center">Reply Legal Notice Preview</h2>

                    {/* 1. Recipient (Notice Sender) */}
                    <h4 className="mt-4">1. Recipient (Notice Sender)</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Name/Company Name:</strong> {replyLegalNoticeData.recipient?.name || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Contact Person Name:</strong> {replyLegalNoticeData.recipient?.contactPersonName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Address:</strong> {replyLegalNoticeData.recipient?.address || 'Not specified'}
                        </li>
                    </ul>

                    {/* 2. Sender (Replying Party) */}
                    <h4 className="mt-4">2. Sender (Replying Party)</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Name/Company Name:</strong> {replyLegalNoticeData.sender?.name || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Contact Person Name:</strong> {replyLegalNoticeData.sender?.contactPersonName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Address:</strong> {replyLegalNoticeData.sender?.address || 'Not specified'}
                        </li>
                    </ul>

                    {/* 3. Legal Notice Reference */}
                    <h4 className="mt-4">3. Legal Notice Reference</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Reference Number:</strong> {replyLegalNoticeData.legalNoticeReference?.referenceNumber || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Date of Notice:</strong> {replyLegalNoticeData.legalNoticeReference?.dateOfNotice || 'Not specified'}
                        </li>
                        {replyLegalNoticeData.legalNoticeReference?.uploadedDocument ? (
                            <li className="list-group-item">
                                <strong>Uploaded Document:</strong> {replyLegalNoticeData.legalNoticeReference.uploadedDocument.name || 'Document uploaded'}
                            </li>
                        ) : replyLegalNoticeData.legalNoticeReference?.summary ? (
                            <li className="list-group-item">
                                <strong>Summary:</strong> {replyLegalNoticeData.legalNoticeReference.summary}
                            </li>
                        ) : (
                            <li className="list-group-item">
                                <strong>Summary / Uploaded Document:</strong> Not specified
                            </li>
                        )}
                    </ul>

                    {/* 4. Reply Details */}
                    <h4 className="mt-4">4. Reply Details</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Do you agree with the claim made in legal notice?</strong> {replyLegalNoticeData.replyDetails?.agreeWithClaim || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Admitted Claims:</strong> {replyLegalNoticeData.replyDetails?.admittedClaims || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Denied Claims:</strong> {replyLegalNoticeData.replyDetails?.deniedClaims || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Justifications/Explanations:</strong> {replyLegalNoticeData.replyDetails?.justifications || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Agreed Amount:</strong>
                            {replyLegalNoticeData.replyDetails?.agreedAmount
                                ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(replyLegalNoticeData.replyDetails.agreedAmount)
                                : 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Disputed Amount:</strong>
                            {replyLegalNoticeData.replyDetails?.disputedAmount
                                ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(replyLegalNoticeData.replyDetails.disputedAmount)
                                : 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Reason for Dispute:</strong> {replyLegalNoticeData.replyDetails?.reasonForDispute || 'Not specified'}
                        </li>
                    </ul>

                    {/* 5. Additional Information */}
                    <h4 className="mt-4">5. Additional Information</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Counter Claims:</strong> {replyLegalNoticeData.counterClaims || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Legal Grounds for Reply:</strong> {replyLegalNoticeData.legalGrounds || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Supporting Documents:</strong> {replyLegalNoticeData.supportingDocuments || 'Not specified'}
                        </li>
                    </ul>
                </>
            );

        } else if (draftTypeId === '13') {
            return (
                <>
                    <h2 className="text-center">Stay Order Preview</h2>

                    {/* Section 1: Court Information */}
                    <h4 className="mt-4">1. Court Information</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Court Name:</strong> {stayApplicationData.courtInfo?.courtName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Suit Number:</strong> {stayApplicationData.courtInfo?.suitNumber || 'Not specified'}
                        </li>
                    </ul>

                    {/* Section 2: Suit Details */}
                    <h4 className="mt-4">2. Suit Details</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Type of Suit:</strong> {stayApplicationData.suitDetails?.typeOfSuit || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>CPC Order and Rule:</strong> {stayApplicationData.suitDetails?.cpcOrderAndRule || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Additional Suit Information:</strong> {stayApplicationData.suitDetails?.additionalSuitInfo || 'Not specified'}
                        </li>
                    </ul>

                    {/* Section 3: Applicant Information */}
                    <h4 className="mt-4">3. Applicant Information</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Full Name:</strong> {stayApplicationData.applicantInfo?.fullName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Address:</strong> {stayApplicationData.applicantInfo?.address || 'Not specified'}
                        </li>
                    </ul>

                    {/* Section 4: Respondent Information */}
                    <h4 className="mt-4">4. Respondent Information</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Full Name:</strong> {stayApplicationData.respondentInfo?.fullName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Address:</strong> {stayApplicationData.respondentInfo?.address || 'Not specified'}
                        </li>
                    </ul>

                    {/* Section 5: Advocate Information */}
                    <h4 className="mt-4">5. Advocate Information</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Advocate's Name:</strong> {stayApplicationData.advocateInfo?.name || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Title:</strong> {stayApplicationData.advocateInfo?.title || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Address:</strong> {stayApplicationData.advocateInfo?.address || 'Not specified'}
                        </li>
                    </ul>

                    {/* Section 6: Affidavit Details */}
                    <h4 className="mt-4">6. Affidavit Details</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Deponent's Name:</strong> {stayApplicationData.affidavitDetails?.deponentName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Address:</strong> {stayApplicationData.affidavitDetails?.address || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Affidavit Date:</strong> {stayApplicationData.affidavitDetails?.affidavitDate || 'Not specified'}
                        </li>
                    </ul>

                    {/* Section 7: Verification Details */}
                    <h4 className="mt-4">7. Verification Details</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Verification Date:</strong> {stayApplicationData.verificationDetails?.verificationDate || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Verification Location:</strong> {stayApplicationData.verificationDetails?.verificationLocation || 'Not specified'}
                        </li>
                    </ul>

                    {/* Section 8: Additional Information */}
                    <h4 className="mt-4">8. Additional Information</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Property Details:</strong> {stayApplicationData.additionalInfo?.propertyDetails || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Reasons for Application:</strong> {stayApplicationData.additionalInfo?.reasonsForApplication || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Prayer/Application Requests:</strong> {stayApplicationData.additionalInfo?.prayerRequests || 'Not specified'}
                        </li>
                    </ul>
                </>
            );
        } else if (draftTypeId === '14') {
            return (
                <>
                    <h2 className="text-center">Declaration Suit Preview</h2>

                    {/* 1. Type of Declaration */}
                    <h4 className="mt-4">1. Type of Declaration</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Subject of Declaration:</strong> {declarationSuitData.declarationType || 'Not specified'}
                        </li>
                    </ul>

                    {/* 2. Parties */}
                    <h4 className="mt-4">2. Parties</h4>
                    <h5 className="mt-3">Plaintiff Details</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Full Name:</strong> {declarationSuitData.plaintiffDetails.name || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>CNIC/ID Number:</strong> {declarationSuitData.plaintiffDetails.cnic || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Address:</strong> {declarationSuitData.plaintiffDetails.address || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Contact Information:</strong> {declarationSuitData.plaintiffDetails.contactInfo || 'Not specified'}
                        </li>
                    </ul>

                    <h5 className="mt-3">Defendant Details</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Full Name:</strong> {declarationSuitData.defendantDetails.name || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>CNIC/ID Number:</strong> {declarationSuitData.defendantDetails.cnic || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Address:</strong> {declarationSuitData.defendantDetails.address || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Relationship to Plaintiff:</strong> {declarationSuitData.defendantDetails.relationship || 'Not specified'}
                        </li>
                    </ul>

                    {/* 3. Jurisdiction */}
                    <h4 className="mt-4">3. Jurisdiction</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Territorial Jurisdiction (Basis):</strong> {declarationSuitData.jurisdiction.territorial || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Pecuniary Jurisdiction (If Applicable):</strong> {declarationSuitData.jurisdiction.pecuniary || 'Not specified'}
                        </li>
                    </ul>

                    {/* 4. Case Summary */}
                    <h4 className="mt-4">4. Case Summary</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Subject of Declaration:</strong> {declarationSuitData.caseSummary.subjectOfDeclaration || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Cause of Action (Key Facts):</strong> {declarationSuitData.caseSummary.causeOfAction || 'Not specified'}
                        </li>
                    </ul>

                    {/* 5. Relief Sought */}
                    <h4 className="mt-4">5. Relief Sought</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Specific Declaration:</strong> {declarationSuitData.reliefSought.specificDeclaration || 'Not specified'}
                        </li>
                    </ul>

                    {/* 6. Supporting Documents */}
                    <h4 className="mt-4">6. Supporting Documents</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>List of Attached Documents:</strong> {declarationSuitData.supportingDocuments.list || 'Not specified'}
                        </li>
                    </ul>

                    {/* Conditional Sections Based on Declaration Type */}
                    {declarationSuitData.declarationType === 'Property Ownership' && (
                        <>
                            <h5 className="mt-3">Property Ownership Details</h5>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <strong>Property Address/Location:</strong> {declarationSuitData.propertyOwnership.propertyAddress || 'Not specified'}
                                </li>
                                <li className="list-group-item">
                                    <strong>Area/Size:</strong> {declarationSuitData.propertyOwnership.propertyArea || 'Not specified'}
                                </li>
                                <li className="list-group-item">
                                    <strong>Ownership Documents:</strong> {declarationSuitData.propertyOwnership.ownershipDocs || 'Not specified'}
                                </li>
                                <li className="list-group-item">
                                    <strong>Nature of Dispute:</strong> {declarationSuitData.propertyOwnership.disputeNature || 'Not specified'}
                                </li>
                            </ul>
                        </>
                    )}

                    {declarationSuitData.declarationType === 'Legal Heirship' && (
                        <>
                            <h5 className="mt-3">Legal Heirship Details</h5>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <strong>Deceased's Name:</strong> {declarationSuitData.legalHeirship.deceasedName || 'Not specified'}
                                </li>
                                <li className="list-group-item">
                                    <strong>Deceased's CNIC/ID Number:</strong> {declarationSuitData.legalHeirship.deceasedCnic || 'Not specified'}
                                </li>
                                <li className="list-group-item">
                                    <strong>Date of Death:</strong> {declarationSuitData.legalHeirship.dateOfDeath || 'Not specified'}
                                </li>
                                <li className="list-group-item">
                                    <strong>Potential Heirs:</strong> {declarationSuitData.legalHeirship.potentialHeirs || 'Not specified'}
                                </li>
                            </ul>
                        </>
                    )}

                    {declarationSuitData.declarationType === 'Contractual Disputes' && (
                        <>
                            <h5 className="mt-3">Contractual Dispute Details</h5>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <strong>Contract Date of Execution:</strong> {declarationSuitData.contractualDisputes.executionDate || 'Not specified'}
                                </li>
                                <li className="list-group-item">
                                    <strong>Parties Involved:</strong> {declarationSuitData.contractualDisputes.partiesInvolved || 'Not specified'}
                                </li>
                                <li className="list-group-item">
                                    <strong>Key Terms of Agreement:</strong> {declarationSuitData.contractualDisputes.keyTerms || 'Not specified'}
                                </li>
                                <li className="list-group-item">
                                    <strong>Nature of Dispute:</strong> {declarationSuitData.contractualDisputes.disputeNature || 'Not specified'}
                                </li>
                            </ul>
                        </>
                    )}

                    {declarationSuitData.declarationType === 'Marital or Citizenship Status' && (
                        <>
                            <h5 className="mt-3">Marital/Citizenship Details</h5>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <strong>Marital Details:</strong> {declarationSuitData.maritalCitizenship.maritalDetails || 'Not specified'}
                                </li>
                                <li className="list-group-item">
                                    <strong>Citizenship Details:</strong> {declarationSuitData.maritalCitizenship.citizenshipDetails || 'Not specified'}
                                </li>
                                <li className="list-group-item">
                                    <strong>Supporting Documents:</strong> {declarationSuitData.maritalCitizenship.supportingDocs || 'Not specified'}
                                </li>
                            </ul>
                        </>
                    )}

                    {declarationSuitData.declarationType === 'Financial or Business Matters' && (
                        <>
                            <h5 className="mt-3">Financial/Business Details</h5>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <strong>Nature of Financial Relationship:</strong> {declarationSuitData.financialBusiness.nature || 'Not specified'}
                                </li>
                                <li className="list-group-item">
                                    <strong>Relevant Transactions and Their Value:</strong> {declarationSuitData.financialBusiness.transactions || 'Not specified'}
                                </li>
                                <li className="list-group-item">
                                    <strong>Business Documentation:</strong> {declarationSuitData.financialBusiness.documents || 'Not specified'}
                                </li>
                            </ul>
                        </>
                    )}

                    {/* 7. Other Details */}
                    <h4 className="mt-4">7. Other Details (If Any)</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Opposing Claims:</strong> {declarationSuitData.otherDetails.opposingClaims || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Prior Legal Action:</strong> {declarationSuitData.otherDetails.priorAction || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Witness Details:</strong> {declarationSuitData.otherDetails.witnesses || 'Not specified'}
                        </li>
                    </ul>
                </>
            );
        } else if (draftTypeId === '15') {
            return (
                <>
                    <h2 className="text-center">Temporary Injunction Application Preview</h2>

                    {/* Reference */}
                    <p className="text-center">
                        <em>Reference: Order XXXIX, Rules 1 &amp; 2 of the CPC, 1908</em>
                    </p>

                    {/* 1. Injunction & Court Details */}
                    <h4 className="mt-4">1. Injunction & Court Details</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Type of Injunction:</strong>{' '}
                            {injunctionAppData.injunctionType || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Court Name (Overall):</strong>{' '}
                            {injunctionAppData.courtName || 'Not specified'}
                        </li>
                    </ul>

                    {/* 2. Case Details */}
                    <h4 className="mt-4">2. Case Details</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Case Title (Plaintiff vs. Defendant):</strong>{' '}
                            {injunctionAppData.caseDetails.caseTitle || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Case Number:</strong>{' '}
                            {injunctionAppData.caseDetails.caseNumber || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Court Name (for this Case):</strong>{' '}
                            {injunctionAppData.caseDetails.courtName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Existing Suit Details:</strong>{' '}
                            {injunctionAppData.caseDetails.existingSuitDetails || 'Not specified'}
                        </li>
                    </ul>

                    {/* 3. Applicant (Plaintiff) Details */}
                    <h4 className="mt-4">3. Applicant (Plaintiff) Details</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Full Name:</strong>{' '}
                            {injunctionAppData.applicantDetails.name || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Address:</strong>{' '}
                            {injunctionAppData.applicantDetails.address || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>CNIC/ID Number:</strong>{' '}
                            {injunctionAppData.applicantDetails.cnic || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Contact Information:</strong>{' '}
                            {injunctionAppData.applicantDetails.contactInfo || 'Not specified'}
                        </li>
                    </ul>

                    {/* 4. Respondent (Defendant) Details */}
                    <h4 className="mt-4">4. Respondent (Defendant) Details</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Full Name:</strong>{' '}
                            {injunctionAppData.respondentDetails.name || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Address:</strong>{' '}
                            {injunctionAppData.respondentDetails.address || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>CNIC/ID Number:</strong>{' '}
                            {injunctionAppData.respondentDetails.cnic || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Relationship to Plaintiff:</strong>{' '}
                            {injunctionAppData.respondentDetails.relationship || 'Not specified'}
                        </li>
                    </ul>

                    {/* 5. Common Fields */}
                    <h4 className="mt-4">5. Common Fields</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Subject of Application:</strong>{' '}
                            {injunctionAppData.commonFields.subjectOfApplication || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Cause of Action (Key Facts):</strong>{' '}
                            {injunctionAppData.commonFields.causeOfAction || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Relief Sought:</strong>{' '}
                            {injunctionAppData.commonFields.reliefSought || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Urgency of Relief:</strong>{' '}
                            {injunctionAppData.commonFields.urgencyOfRelief || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Supporting Evidence (List of Documents):</strong>{' '}
                            {injunctionAppData.commonFields.supportingEvidence || 'Not specified'}
                        </li>
                    </ul>

                    {/* 6. Conditional Sections Based on Injunction Type */}
                    {injunctionAppData.injunctionType === 'Restraining Injunction Application' && (
                        <>
                            <h5 className="mt-3">Restraining Injunction Details</h5>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <strong>Specific Act to Be Restrained:</strong>{' '}
                                    {injunctionAppData.restrainingInjunction.specificAct || 'Not specified'}
                                </li>
                                <li className="list-group-item">
                                    <strong>Balance of Convenience:</strong>{' '}
                                    {injunctionAppData.restrainingInjunction.balanceOfConvenience ||
                                        'Not specified'}
                                </li>
                            </ul>
                        </>
                    )}

                    {injunctionAppData.injunctionType === 'Mandatory Injunction Application' && (
                        <>
                            <h5 className="mt-3">Mandatory Injunction Details</h5>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <strong>Specific Act to Be Compelled:</strong>{' '}
                                    {injunctionAppData.mandatoryInjunction.specificAct || 'Not specified'}
                                </li>
                                <li className="list-group-item">
                                    <strong>Irreparable Harm Without Injunction:</strong>{' '}
                                    {injunctionAppData.mandatoryInjunction.irreparableHarm || 'Not specified'}
                                </li>
                            </ul>
                        </>
                    )}

                    {/* 7. Optional Fields (If Any) */}
                    <h4 className="mt-4">7. Optional Details</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Prior Legal Actions:</strong>{' '}
                            {injunctionAppData.optionalFields.priorLegalActions || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Witness Information:</strong>{' '}
                            {injunctionAppData.optionalFields.witnessInformation || 'Not specified'}
                        </li>
                    </ul>
                </>
            );
        } else if (draftTypeId === '16') {
            return (
                <>
                    <h2 className="text-center">Injunction Suit Preview</h2>
                    <p className="text-center">
                        <em>Relevant Law: Sections 54–57 of the Specific Relief Act, 1877</em>
                    </p>

                    {/* 1. Basic Suit Details */}
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Type of Injunction Sought:</strong> {injunctionSuitData.typeOfInjunction || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Case Title:</strong> {injunctionSuitData.basicSuitDetails.caseTitle || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Court Name:</strong> {injunctionSuitData.basicSuitDetails.courtName || 'Not specified'}
                        </li>
                    </ul>

                    <h4 className="mt-3">Plaintiff Details</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Full Name:</strong> {injunctionSuitData.basicSuitDetails.plaintiffName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>CNIC/ID Number:</strong> {injunctionSuitData.basicSuitDetails.plaintiffCnic || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Address:</strong> {injunctionSuitData.basicSuitDetails.plaintiffAddress || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Contact Information:</strong> {injunctionSuitData.basicSuitDetails.plaintiffContactInfo || 'Not specified'}
                        </li>
                    </ul>

                    <h4 className="mt-3">Defendant Details</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Full Name:</strong> {injunctionSuitData.basicSuitDetails.defendantName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>CNIC/ID Number:</strong> {injunctionSuitData.basicSuitDetails.defendantCnic || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Address:</strong> {injunctionSuitData.basicSuitDetails.defendantAddress || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Relationship to Plaintiff:</strong> {injunctionSuitData.basicSuitDetails.defendantRelationship || 'Not specified'}
                        </li>
                    </ul>

                    {/* 2. Common Fields */}
                    <h4 className="mt-4">Injunction Suit Detail</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Cause of Action:</strong> {injunctionSuitData.commonFields.causeOfAction || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Relief Sought:</strong> {injunctionSuitData.commonFields.reliefSought || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Nature of the Disputed Subject:</strong> {injunctionSuitData.commonFields.disputedSubject.nature || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Specific Details (Property/Contract/etc.):</strong> {injunctionSuitData.commonFields.disputedSubject.specificDetails || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Urgency and Harm:</strong> {injunctionSuitData.commonFields.urgencyAndHarm || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Prior Legal Actions:</strong> {injunctionSuitData.commonFields.priorLegalActions || 'Not specified'}
                        </li>
                    </ul>

                    {/* 3. Specific Fields */}
                    {injunctionSuitData.typeOfInjunction === "Restraining Injunction Suit" && (
                        <>
                            <h4 className="mt-3">Restraining Injunction Details</h4>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <strong>Act to Be Restrained:</strong> {injunctionSuitData.restrainingInjunction.actToBeRestrained || 'Not specified'}
                                </li>
                                <li className="list-group-item">
                                    <strong>Balance of Convenience:</strong> {injunctionSuitData.restrainingInjunction.balanceOfConvenience || 'Not specified'}
                                </li>
                            </ul>
                        </>
                    )}

                    {injunctionSuitData.typeOfInjunction === "Mandatory Injunction Suit" && (
                        <>
                            <h4 className="mt-3">Mandatory Injunction Details</h4>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <strong>Act to Be Compelled:</strong> {injunctionSuitData.mandatoryInjunction.actToBeCompelled || 'Not specified'}
                                </li>
                                <li className="list-group-item">
                                    <strong>Nature of Harm:</strong> {injunctionSuitData.mandatoryInjunction.natureOfHarm || 'Not specified'}
                                </li>
                            </ul>
                        </>
                    )}

                    {/* 4. Evidence and Supporting Details */}
                    <h4 className="mt-4">Evidence and Supporting Details</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Supporting Documents:</strong> {injunctionSuitData.evidence.supportingDocuments || 'Not specified'}
                        </li>
                    </ul>

                    {/* 5. Legal References (Optional) */}
                    <h4 className="mt-4">Legal References (Optional)</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Relevant Sections Invoked:</strong> {injunctionSuitData.legalReferences.sections || 'Not specified'}
                        </li>
                    </ul>

                    {/* 6. Prayer/Relief Sought */}
                    <h4 className="mt-4">Prayer/Relief Sought</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            {injunctionSuitData.prayer || 'Not specified'}
                        </li>
                    </ul>
                </>
            );
        } else if (draftTypeId === '17') {
            return (
                <>
                    <h2 className="text-center">Execution Application Preview</h2>

                    {/* Section 1: Basic Details */}
                    <h4 className="mt-4">Basic Details</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Court Name:</strong> {executionAppData.basicDetails.courtName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Case Title:</strong> {executionAppData.basicDetails.caseTitle || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Case Number:</strong> {executionAppData.basicDetails.caseNumber || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Date of Decree:</strong> {executionAppData.basicDetails.dateOfDecree || 'Not specified'}
                        </li>
                    </ul>

                    <h5 className="mt-3">Applicant (Decree-Holder) Details</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Bank Name:</strong> {executionAppData.basicDetails.decreeHolder.bankName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Branch Name:</strong> {executionAppData.basicDetails.decreeHolder.branchName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Contact Information:</strong> {executionAppData.basicDetails.decreeHolder.contactInfo || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Authorized Representative Name:</strong> {executionAppData.basicDetails.decreeHolder.authorizedRep || 'Not specified'}
                        </li>
                    </ul>

                    <h5 className="mt-3">Defendant (Judgment-Debtor) Details</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Full Name:</strong> {executionAppData.basicDetails.judgmentDebtor.fullName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Address:</strong> {executionAppData.basicDetails.judgmentDebtor.address || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>CNIC/ID Number:</strong> {executionAppData.basicDetails.judgmentDebtor.cnic || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Contact Information:</strong> {executionAppData.basicDetails.judgmentDebtor.contactInfo || 'Not specified'}
                        </li>
                    </ul>

                    {/* Section 2: Decree Details */}
                    <h4 className="mt-4">Decree Details</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Nature of Decree:</strong> {executionAppData.decreeDetails.natureOfDecree || 'Not specified'}
                        </li>
                        {executionAppData.decreeDetails.natureOfDecree === 'Other' && (
                            <li className="list-group-item">
                                <strong>Other Nature:</strong> {executionAppData.decreeDetails.otherNature || 'Not specified'}
                            </li>
                        )}
                        <li className="list-group-item">
                            <strong>Principal Amount:</strong>{' '}
                            <NumericFormat
                                displayType="text"
                                thousandSeparator
                                value={executionAppData.decreeDetails.principalAmount || '0'}
                            />
                        </li>
                        <li className="list-group-item">
                            <strong>Interest (if applicable):</strong>{' '}
                            <NumericFormat
                                displayType="text"
                                thousandSeparator
                                value={executionAppData.decreeDetails.interestAmount || '0'}
                            />
                        </li>
                        <li className="list-group-item">
                            <strong>Total Amount Due:</strong>{' '}
                            <NumericFormat
                                displayType="text"
                                thousandSeparator
                                value={executionAppData.decreeDetails.totalAmountDue || '0'}
                            />
                        </li>
                        <li className="list-group-item">
                            <strong>Details of Outstanding Amount:</strong> {executionAppData.decreeDetails.detailsOfOutstanding || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Execution Sought:</strong> {executionAppData.decreeDetails.executionSought || 'Not specified'}
                        </li>
                        {executionAppData.decreeDetails.executionSought === 'Other' && (
                            <li className="list-group-item">
                                <strong>Other Execution Sought:</strong> {executionAppData.decreeDetails.otherExecutionSought || 'Not specified'}
                            </li>
                        )}
                    </ul>

                    {/* Section 3: Property/Asset Details */}
                    <h4 className="mt-4">Property/Asset Details (If Applicable)</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Type of Collateral:</strong> {executionAppData.assetDetails.typeOfCollateral || 'Not specified'}
                        </li>
                        {executionAppData.assetDetails.typeOfCollateral === 'Other' && (
                            <li className="list-group-item">
                                <strong>Other Collateral Type:</strong> {executionAppData.assetDetails.otherCollateral || 'Not specified'}
                            </li>
                        )}
                        <li className="list-group-item">
                            <strong>Description of Collateral:</strong> {executionAppData.assetDetails.description || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Current Status of Collateral:</strong> {executionAppData.assetDetails.currentStatus || 'Not specified'}
                        </li>
                    </ul>

                    {/* Section 4: Previous Notices/Actions */}
                    <h4 className="mt-4">Previous Notices/Actions</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Details of Notices Issued:</strong> {executionAppData.previousActions.notices || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Injunction or Interim Orders:</strong> {executionAppData.previousActions.injunctionOrders || 'Not specified'}
                        </li>
                    </ul>

                    {/* Section 5: Requested Court Actions */}
                    <h4 className="mt-4">Requested Court Actions</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Specific Relief Requested:</strong> {executionAppData.requestedActions.specificRelief || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Proposed Plan for Execution:</strong> {executionAppData.requestedActions.executionPlan || 'Not specified'}
                        </li>
                    </ul>

                    {/* Section 6: Attachments */}
                    <h4 className="mt-4">Attachments</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Mandatory Attachments:</strong> {executionAppData.attachments.mandatory || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Optional Attachments:</strong> {executionAppData.attachments.optional || 'Not specified'}
                        </li>
                    </ul>
                </>
            );
        } else if (draftTypeId === '18') {
            return (
                <>
                    <h2 className="text-center">Negotiation/Settlement Notice Preview</h2>

                    {/* Section 1: Debtor Information */}
                    <h4 className="mt-4">Debtor Information</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Company Name:</strong> {settlementNoticeData.debtorInfo.companyName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Chief Executive's Name:</strong> {settlementNoticeData.debtorInfo.chiefExecName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Address:</strong> {settlementNoticeData.debtorInfo.address || 'Not specified'}
                        </li>
                    </ul>

                    {/* Section 2: Creditor (Bank) Information */}
                    <h4 className="mt-4">Creditor (Bank) Information</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Bank Name:</strong> {settlementNoticeData.bankInfo.bankName || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Head Office Address:</strong> {settlementNoticeData.bankInfo.headOfficeAddress || 'Not specified'}
                        </li>
                    </ul>

                    {/* Section 3: Outstanding Amount Details */}
                    <h4 className="mt-4">Outstanding Amount Details</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Total Principal Amount:</strong>{' '}
                            <NumericFormat
                                displayType="text"
                                thousandSeparator
                                value={settlementNoticeData.outstandingAmountDetails.totalPrincipal || '0'}
                            />
                        </li>
                        <li className="list-group-item">
                            <strong>Total Mark-up:</strong>{' '}
                            <NumericFormat
                                displayType="text"
                                thousandSeparator
                                value={settlementNoticeData.outstandingAmountDetails.totalMarkUp || '0'}
                            />
                        </li>
                        <li className="list-group-item">
                            <strong>Penal Charges (if any):</strong>{' '}
                            <NumericFormat
                                displayType="text"
                                thousandSeparator
                                value={settlementNoticeData.outstandingAmountDetails.penalCharges || '0'}
                            />
                        </li>
                        <li className="list-group-item">
                            <strong>Amount Paid (if any):</strong>{' '}
                            <NumericFormat
                                displayType="text"
                                thousandSeparator
                                value={settlementNoticeData.outstandingAmountDetails.amountPaid || '0'}
                            />
                        </li>
                        <li className="list-group-item">
                            <strong>Aggregated Amount:</strong>{' '}
                            <NumericFormat
                                displayType="text"
                                thousandSeparator
                                value={settlementNoticeData.outstandingAmountDetails.aggregatedAmount || '0'}
                            />
                        </li>
                    </ul>

                    {/* Section 4: Dates and Deadlines */}
                    <h4 className="mt-4">Dates and Deadlines</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Overdue Date:</strong> {settlementNoticeData.datesDeadlines.overdueDate || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Notice Issuance Date:</strong> {settlementNoticeData.datesDeadlines.noticeIssuanceDate || 'Not specified'}
                        </li>
                    </ul>

                    {/* Section 5: Notice Details */}
                    <h4 className="mt-4">Notice Details</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Purpose of the Notice:</strong> {settlementNoticeData.noticeDetails.purposeOfNotice || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Type of Offer:</strong> {settlementNoticeData.noticeDetails.typeOfOffer || 'Not specified'}
                        </li>
                        {settlementNoticeData.noticeDetails.typeOfOffer === 'Other' && (
                            <li className="list-group-item">
                                <strong>Other Offer Type:</strong> {settlementNoticeData.noticeDetails.otherOfferType || 'Not specified'}
                            </li>
                        )}
                        <li className="list-group-item">
                            <strong>Proposed Amount:</strong>{' '}
                            <NumericFormat
                                displayType="text"
                                thousandSeparator
                                value={settlementNoticeData.noticeDetails.proposedAmount || '0'}
                            />
                        </li>
                        <li className="list-group-item">
                            <strong>Proposed Schedule:</strong> {settlementNoticeData.noticeDetails.proposedSchedule || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Reason for Settlement Proposal:</strong> {settlementNoticeData.noticeDetails.reasonForProposal || 'Not specified'}
                        </li>
                        <li className="list-group-item">
                            <strong>Response Required By:</strong> {settlementNoticeData.noticeDetails.responseRequiredBy || 'Not specified'}
                        </li>
                    </ul>

                    {/* Section 6: Supporting Documents */}
                    <h4 className="mt-4">Supporting Documents</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Documents List:</strong> {settlementNoticeData.supportingDocuments.list || 'Not specified'}
                        </li>
                    </ul>

                    {/* Section 7: Legal References (Optional) */}
                    <h4 className="mt-4">Legal References</h4>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <strong>Cited Legal Provisions:</strong> {settlementNoticeData.legalReferences.citedProvisions || 'Not specified'}
                        </li>
                    </ul>
                </>
            );
        }
        else {
            return <div>Invalid draft type</div>;
        }
    };

    return (
        <div className="container">
            {!generated && (
                <>
                    {/* <h3 className="mb-4">Review Your Details</h3> */}

                    <div className="card">
                        <div className="card-body">
                            {renderPreviewContent()}
                        </div>
                    </div>
                    {isQuotaExceeded && (
                        <div className={style.quotaMessage}>
                            <p>
                                {draftTypeId === '10' ? (
                                    <>
                                        Your quota has been exceeded for Case Summarization. Please email us at <a href="mailto:contact@paklawassist.com">contact@paklawassist.com</a> to request an increase.
                                    </>
                                ) : (
                                    <>
                                        Your quota has been exceeded for Legal Drafts. Please email us at <a href="mailto:contact@paklawassist.com">contact@paklawassist.com</a> to request an increase.
                                    </>
                                )}
                            </p>
                        </div>
                    )}
                    <div className="mt-4 mb-5 d-flex justify-content-between">
                        {/* <button className="btn btn-primary" onClick={handleGenerate} disabled={loading}>
                            {loading ? 'Generating...' : 'Confirm & Generate'}
                        </button> */}
                        <button
                            className="btn"
                            onClick={handleGenerate}
                            disabled={loading}
                            style={{
                                backgroundColor: '#166432',
                                color: '#C59D55',
                                border: 'none'
                            }}
                            onMouseEnter={(e) => {
                                e.target.style.backgroundColor = '#092415';
                                e.target.style.color = '#fff';
                            }}
                            onMouseLeave={(e) => {
                                e.target.style.backgroundColor = '#166432';
                                e.target.style.color = '#C59D55';
                            }}
                        >
                            {loading ? 'Generating...' : 'Confirm & Generate'}
                        </button>
                        <button className="btn btn-secondary me-2" onClick={handleBack}>Back to Edit</button>
                    </div>
                </>
            )}

            {generated && (
                <div>
                    <div className="card">
                        <div className="card-body" ref={contentRef}>
                            {!isEditing ? (
                                <ReactMarkdown>{content}</ReactMarkdown> // Render generated markdown
                            ) : (
                                <SimpleMDE value={content} onChange={setEditedContent} /> // Allow editing
                            )}
                        </div>
                    </div>

                    {/* Display Research Results */}
                    {draftTypeId === '35' && reseachDocList.length > 0 && (
                        <div className="card mt-4">
                            <div className="card-body">
                                <h3>Research Reference Judgments</h3>
                                <ul>
                                    {reseachDocList.map((item, index) => (
                                        <li key={index}>
                                            <a
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleJudgmentClick(item.source, item.Citation);
                                                }}
                                            >
                                                {item.Citation}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}

                    {/* Modal for displaying the judgment content */}
                    {isModalOpen && (
                        <div
                            className="modal show"
                            role="dialog"
                            aria-modal="true"
                            aria-labelledby="fileContentModalLabel"
                            style={{
                                display: 'block',
                                background: 'rgba(0,0,0,0.5)',
                            }}
                        >
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="fileContentModalLabel">
                                            {modalTitle}
                                        </h5>
                                        <button
                                            type="button"
                                            className="close"
                                            onClick={closeModal}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div
                                        className="modal-body"
                                        style={{ height: '80vh', overflowY: 'auto' }}
                                    >
                                        {fileContent ? (
                                            <pre>{fileContent}</pre>
                                        ) : (
                                            <p>Loading content...</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="mt-4 mb-5 d-flex justify-content-between">
                        {draftTypeId !== '10' && (
                            <button
                                className="btn"
                                onClick={handleEditToggle}
                                style={{
                                    backgroundColor: '#166432',
                                    color: '#C59D55',
                                    border: 'none'
                                }}
                                onMouseEnter={(e) => {
                                    e.target.style.backgroundColor = '#092415';
                                    e.target.style.color = '#fff';
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.backgroundColor = '#166432';
                                    e.target.style.color = '#C59D55';
                                }}
                            >
                                {isEditing ? 'Finish Editing' : 'Edit'}
                            </button>
                        )}
                        {draftTypeId === '1' && (
                            <button
                                className="btn"
                                onClick={handleResearchToggle}
                                style={{
                                    backgroundColor: '#166432',
                                    color: '#C59D55',
                                    border: 'none'
                                }}
                                onMouseEnter={(e) => {
                                    e.target.style.backgroundColor = '#092415';
                                    e.target.style.color = '#fff';
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.backgroundColor = '#166432';
                                    e.target.style.color = '#C59D55';
                                }}
                            >
                                {loading ? 'Researching...' : 'Research Related Judgments'}
                            </button>
                        )}
                        <button
                            className="btn btn-primary"
                            onClick={() => window.location.href = `/dashboard/form?draft_type_id=${draftTypeId}`}
                            style={{
                                backgroundColor: '#0056b3',
                                color: '#fff',
                                border: 'none'
                            }}
                            onMouseEnter={(e) => {
                                e.target.style.backgroundColor = '#004494';
                            }}
                            onMouseLeave={(e) => {
                                e.target.style.backgroundColor = '#0056b3';
                            }}
                        >
                            Generate New
                        </button>
                        <button className="btn btn-success" onClick={generateWord}>Export as Word</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PreviewPage;

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as pdfjsLib from 'pdfjs-dist';

// Set the workerSrc for PDF.js
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

function CaseSummarizationForm({ formData, setFormData, onSubmit }) {
    const location = useLocation();
    const [errors, setErrors] = useState({});
    console.log(location.state)
    // 2) If there’s text in location.state, set it in formData
  useEffect(() => {
    if (location.state && location.state.passedJudgmentText) {
      setFormData((prev) => ({
        ...prev,
        jugmenttextContent: location.state.passedJudgmentText,
        jugmentpdfContent: '',
      }));
    }
  }, [location.state, setFormData]);

    const handleTextChange = (e) => {
        const text = e.target.value;
        setFormData(prev => ({
            ...prev,
            jugmenttextContent: text,
            jugmentpdfContent: ''
        }));
    };

    // PDF file handling logic using pdf.js
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const text = await extractTextFromPDF(file);
                setFormData((prev) => ({
                    ...prev,
                    jugmenttextContent: '',
                    jugmentpdfContent: text,
                }));
            } catch (error) {
                console.error('Error extracting text from PDF:', error);
                setErrors({ input: 'Failed to extract text from PDF.' });
            }
        }
    };

    const extractTextFromPDF = async (file) => {
        const fileReader = new FileReader();
        return new Promise((resolve, reject) => {
            fileReader.onload = function () {
                const typedarray = new Uint8Array(this.result);
                pdfjsLib
                    .getDocument({ data: typedarray })
                    .promise.then(async (pdf) => {
                        let pdfText = '';
                        for (let i = 1; i <= pdf.numPages; i++) {
                            const page = await pdf.getPage(i);
                            const textContent = await page.getTextContent();
                            const pageText = textContent.items
                                .map((item) => item.str)
                                .join(' ');
                            pdfText += pageText + '\n';
                        }
                        resolve(pdfText);
                    })
                    .catch((err) => {
                        console.error('Error parsing PDF:', err);
                        reject(err);
                    });
            };
            fileReader.onerror = reject;
            fileReader.readAsArrayBuffer(file);
        });
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.jugmenttextContent && !formData.jugmentpdfContent) {
            formErrors.input = 'Please enter text or upload a PDF.';
        }
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            onSubmit();
        }
    };

    const isTextEntered = formData.jugmenttextContent && formData.jugmenttextContent.length > 0;

    return (
        <div className="mb-2" style={{ fontFamily: 'Arial, sans-serif', color: '#333' }}>
            <h4 className="text-center" style={{ fontWeight: 'bold', fontSize: '1.8rem', marginBottom: '1rem' }}>Case Summarization</h4>
            <p className="" style={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                Easily summarize lengthy judgments or case texts in seconds. Simply upload a file or paste the text below, and click <strong>'Next'</strong> to generate a concise summary.
            </p>
            <p style={{ fontSize: '1rem', marginBottom: '1rem', backgroundColor: '#f0f5f0', color: '#166432', padding: '10px', borderRadius: '8px', border: '1px solid #166432' }}>
                <strong>Note:</strong> If you're uploading a scanned PDF, ensure the text is searchable and not just an image. PDFs containing only images are not supported.
            </p>
            <form onSubmit={handleSubmit} style={{ backgroundColor: '#f9f9f9', padding: '20px', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0,0,0,0.1)' }}>
                {/* PDF File Upload Input */}
                <div className="form-group" style={{ marginBottom: '1.5rem' }}>
                    <label style={{ fontSize: '1rem', fontWeight: '500', marginBottom: '0.5rem', display: 'block' }}>Upload a PDF File:</label>
                    <input
                        type="file"
                        className="form-control"
                        accept="application/pdf"
                        onChange={handleFileChange}
                        disabled={isTextEntered}
                        style={{
                            padding: '0.5rem',
                            fontSize: '1rem',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                            cursor: 'pointer',
                            backgroundColor: '#fff',
                            width: '100%',
                        }}
                    />
                    <small className="form-text text-muted" style={{ fontSize: '0.85rem' }}>Only PDF files up to 1 MB are supported.</small>
                </div>

                <div className="form-group" style={{ marginBottom: '1.5rem' }}>
                    <label style={{ fontSize: '1rem', fontWeight: '500' }}>Or Case Text:</label>
                    <textarea
                        className="form-control"
                        value={formData.jugmenttextContent || ''}
                        onChange={handleTextChange}
                        placeholder="Paste the complete text of the judgment or case here. Avoid partial or incomplete inputs for accurate summarization."
                        rows={10}
                        style={{ width: '100%', padding: '1rem', fontSize: '1rem', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }}
                    ></textarea>
                </div>

                {errors.input && <div className="text-danger" style={{ marginBottom: '1rem' }}>{errors.input}</div>}
                <button
                    type="submit"
                    className="btn btn-primary"
                    style={{
                        backgroundColor: '#166432',
                        color: '#C59D55',
                        border: 'none',
                        padding: '0.5rem 1.5rem',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        transition: 'all 0.3s ease',
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.backgroundColor = '#092415';
                        e.target.style.color = '#fff';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.backgroundColor = '#166432';
                        e.target.style.color = '#C59D55';
                    }}
                >
                    Next
                </button>
            </form>
        </div>
    );
}

export default CaseSummarizationForm;

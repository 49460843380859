import React, { useState, useEffect } from 'react';

function StayApplicationForm({ formData, setFormData, onSubmit }) {
    const [errors, setErrors] = useState({});

    const handleInputChange = (section, field, value) => {
        setFormData(prev => ({
            ...prev,
            [section]: {
                ...prev[section],
                [field]: value,
            },
        }));
    };

    const validateForm = () => {
        let formErrors = {};

        // Validate Court Information
        if (!formData.courtInfo?.courtName) formErrors.courtName = "Court Name is required";

        // Validate Suit Details
        if (!formData.suitDetails?.typeOfSuit) formErrors.typeOfSuit = "Type of Suit is required";
        if (!formData.suitDetails?.cpcOrderAndRule) formErrors.cpcOrderAndRule = "CPC Order and Rule is required";
        if (!formData.suitDetails?.additionalSuitInfo) formErrors.additionalSuitInfo = "Additional Suit Information is required";

        // Validate Applicant Information
        if (!formData.applicantInfo?.fullName) formErrors.applicantFullName = "Applicant's Full Name is required";

        // Validate Respondent Information
        if (!formData.respondentInfo?.fullName) formErrors.respondentFullName = "Respondent's Full Name is required";

        // Validate Advocate Information
        if (!formData.advocateInfo?.name) formErrors.advocateName = "Advocate's Name is required";

        // Validate Affidavit Details
        if (!formData.affidavitDetails?.deponentName) formErrors.deponentName = "Deponent's Name is required";
        if (!formData.affidavitDetails?.address) formErrors.affidavitAddress = "Deponent's Address is required";

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log("Form data to submit:", formData);
            onSubmit();
        }
    };

    useEffect(() => {
        setFormData(prev => ({
            ...prev,
            suitDetails: {
                ...prev.suitDetails,
                cpcOrderAndRule: prev.suitDetails?.cpcOrderAndRule || 'Order 39 rules 1 & 2 of the CPC',
            },
        }));
    }, [setFormData]);

    return (
        <div className="container mb-2">
            <h2 className="text-center">Stay Application Form</h2>
            <form onSubmit={handleSubmit}>
                {/* Section 1: Court Information */}
                <div className="row mt-4">
                    <div className="col-md-12 mb-3">
                        <h4>1. Court Information</h4>
                        <div className="border rounded p-3">
                            <div className="form-group mb-3">
                                <label>Court Name:</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.courtName ? 'is-invalid' : ''}`}
                                    value={formData.courtInfo?.courtName || ""}
                                    onChange={(e) => handleInputChange('courtInfo', 'courtName', e.target.value)}
                                    placeholder="Enter Court Name"
                                />
                                {errors.courtName && <div className="invalid-feedback">{errors.courtName}</div>}
                            </div>
                            <div className="form-group mb-3">
                                <label>Suit Number (optional):</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={formData.courtInfo?.suitNumber || ""}
                                    onChange={(e) => handleInputChange('courtInfo', 'suitNumber', e.target.value)}
                                    placeholder="Enter Suit Number"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section 2: Suit Details */}
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <h4>2. Suit Details</h4>
                        <div className="border rounded p-3">
                            <div className="form-group mb-3">
                                <label>Type of Suit:</label>
                                <select
                                    className={`form-control ${errors.typeOfSuit ? 'is-invalid' : ''}`}
                                    value={formData.suitDetails?.typeOfSuit || ""}
                                    onChange={(e) => handleInputChange('suitDetails', 'typeOfSuit', e.target.value)}
                                >
                                    <option value="">Select Type of Suit</option>
                                    <option value="Suit for Declaration and Cancellation of Document">
                                        Suit for Declaration and Cancellation of Document
                                    </option>
                                </select>
                                {errors.typeOfSuit && <div className="invalid-feedback">{errors.typeOfSuit}</div>}
                            </div>
                            <div className="form-group mb-3">
                                <label>CPC Order and Rule:</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.cpcOrderAndRule ? 'is-invalid' : ''}`}
                                    value={formData.suitDetails?.cpcOrderAndRule || ""}
                                    onChange={(e) => handleInputChange('suitDetails', 'cpcOrderAndRule', e.target.value)}
                                />
                                {errors.cpcOrderAndRule && <div className="invalid-feedback">{errors.cpcOrderAndRule}</div>}
                            </div>
                            <div className="form-group mb-3">
                                <label>Additional Suit Information:</label>
                                <textarea
                                    className={`form-control ${errors.additionalSuitInfo ? 'is-invalid' : ''}`}
                                    value={formData.suitDetails?.additionalSuitInfo || ""}
                                    onChange={(e) => handleInputChange('suitDetails', 'additionalSuitInfo', e.target.value)}
                                    placeholder="Enter Additional Suit Information"
                                />
                                {errors.additionalSuitInfo && <div className="invalid-feedback">{errors.additionalSuitInfo}</div>}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section 3: Applicant Information */}
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <h4>3. Applicant Information</h4>
                        <div className="border rounded p-3">
                            <div className="form-group mb-3">
                                <label>Full Name:</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.applicantFullName ? 'is-invalid' : ''}`}
                                    value={formData.applicantInfo?.fullName || ""}
                                    onChange={(e) => handleInputChange('applicantInfo', 'fullName', e.target.value)}
                                    placeholder="Enter Applicant's Full Name"
                                />
                                {errors.applicantFullName && <div className="invalid-feedback">{errors.applicantFullName}</div>}
                            </div>
                            <div className="form-group mb-3">
                                <label>Address (optional):</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={formData.applicantInfo?.address || ""}
                                    onChange={(e) => handleInputChange('applicantInfo', 'address', e.target.value)}
                                    placeholder="Enter Applicant's Address"
                                />
                            </div>
                        </div>
                    </div>

                    {/* Section 4: Respondent Information */}
                    <div className="col-md-6 mb-3">
                        <h4>4. Respondent Information</h4>
                        <div className="border rounded p-3">
                            <div className="form-group mb-3">
                                <label>Full Name:</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.respondentFullName ? 'is-invalid' : ''}`}
                                    value={formData.respondentInfo?.fullName || ""}
                                    onChange={(e) => handleInputChange('respondentInfo', 'fullName', e.target.value)}
                                    placeholder="Enter Respondent's Full Name"
                                />
                                {errors.respondentFullName && <div className="invalid-feedback">{errors.respondentFullName}</div>}
                            </div>
                            <div className="form-group mb-3">
                                <label>Address (optional):</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={formData.respondentInfo?.address || ""}
                                    onChange={(e) => handleInputChange('respondentInfo', 'address', e.target.value)}
                                    placeholder="Enter Respondent's Address"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section 5: Advocate Information */}
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <h4>5. Advocate Information</h4>
                        <div className="border rounded p-3">
                            <h5>Advocate for Applicant:</h5>
                            <div className="form-group mb-3">
                                <label>Name:</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.advocateName ? 'is-invalid' : ''}`}
                                    value={formData.advocateInfo?.name || ""}
                                    onChange={(e) => handleInputChange('advocateInfo', 'name', e.target.value)}
                                    placeholder="Enter Advocate's Name"
                                />
                                {errors.advocateName && <div className="invalid-feedback">{errors.advocateName}</div>}
                            </div>
                            <div className="form-group mb-3">
                                <label>Title (optional):</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={formData.advocateInfo?.title || ""}
                                    onChange={(e) => handleInputChange('advocateInfo', 'title', e.target.value)}
                                    placeholder="Enter Advocate's Title"
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>Address (optional):</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={formData.advocateInfo?.address || ""}
                                    onChange={(e) => handleInputChange('advocateInfo', 'address', e.target.value)}
                                    placeholder="Enter Advocate's Address"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section 6: Affidavit Details */}
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <h4>6. Affidavit Details</h4>
                        <div className="border rounded p-3">
                            <div className="form-group mb-3">
                                <label>Deponent's Name:</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.deponentName ? 'is-invalid' : ''}`}
                                    value={formData.affidavitDetails?.deponentName || ""}
                                    onChange={(e) => handleInputChange('affidavitDetails', 'deponentName', e.target.value)}
                                    placeholder="Enter Deponent's Name"
                                />
                                {errors.deponentName && <div className="invalid-feedback">{errors.deponentName}</div>}
                            </div>
                            <div className="form-group mb-3">
                                <label>Address:</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.affidavitAddress ? 'is-invalid' : ''}`}
                                    value={formData.affidavitDetails?.address || ""}
                                    onChange={(e) => handleInputChange('affidavitDetails', 'address', e.target.value)}
                                    placeholder="Enter Deponent's Address"
                                />
                                {errors.affidavitAddress && <div className="invalid-feedback">{errors.affidavitAddress}</div>}
                            </div>
                            <div className="form-group mb-3">
                                <label>Affidavit Date (optional):</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={formData.affidavitDetails?.affidavitDate || ""}
                                    onChange={(e) => handleInputChange('affidavitDetails', 'affidavitDate', e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section 7: Verification Details */}
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <h4>7. Verification Details</h4>
                        <div className="border rounded p-3">
                            <div className="form-group mb-3">
                                <label>Verification Date (optional):</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={formData.verificationDetails?.verificationDate || ""}
                                    onChange={(e) => handleInputChange('verificationDetails', 'verificationDate', e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>Verification Location (optional):</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={formData.verificationDetails?.verificationLocation || ""}
                                    onChange={(e) => handleInputChange('verificationDetails', 'verificationLocation', e.target.value)}
                                    placeholder="Enter Verification Location"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section 8: Additional Information */}
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <h4>8. Additional Information</h4>
                        <div className="border rounded p-3">
                            <div className="form-group mb-3">
                                <label>Property Details (optional):</label>
                                <textarea
                                    className="form-control"
                                    value={formData.additionalInfo?.propertyDetails || ""}
                                    onChange={(e) => handleInputChange('additionalInfo', 'propertyDetails', e.target.value)}
                                    placeholder="Enter Property Details"
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>Reasons for Application (optional):</label>
                                <textarea
                                    className="form-control"
                                    value={formData.additionalInfo?.reasonsForApplication || ""}
                                    onChange={(e) => handleInputChange('additionalInfo', 'reasonsForApplication', e.target.value)}
                                    placeholder="Enter Reasons for Application"
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>Prayer/Application Requests (optional):</label>
                                <textarea
                                    className="form-control"
                                    value={formData.additionalInfo?.prayerRequests || ""}
                                    onChange={(e) => handleInputChange('additionalInfo', 'prayerRequests', e.target.value)}
                                    placeholder="Enter Prayer/Application Requests"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Submit Button */}
                <div className="mt-4">
                    <button
                        type="submit"
                        className="btn px-4 py-2"
                        style={{
                            backgroundColor: '#166432',
                            color: '#C59D55',
                            border: 'none',
                            transition: 'background-color 0.3s, color 0.3s',
                        }}
                        onMouseEnter={(e) => {
                            e.target.style.backgroundColor = '#092415';
                            e.target.style.color = '#fff';
                        }}
                        onMouseLeave={(e) => {
                            e.target.style.backgroundColor = '#166432';
                            e.target.style.color = '#C59D55';
                        }}
                    >
                        Next
                    </button>
                </div>
            </form>
        </div>
    );
}

export default StayApplicationForm;
import React, { useState, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';

function NegotiationSettlementForm({ formData, setFormData, onSubmit }) {
    const [errors, setErrors] = useState({});

    // Helper function to set nested or top-level state
    const handleInputChange = (section, field, value) => {
        if (section) {
            setFormData((prev) => ({
                ...prev,
                [section]: {
                    ...prev[section],
                    [field]: value,
                },
            }));
        } else {
            setFormData((prev) => ({
                ...prev,
                [field]: value,
            }));
        }
    };

    // Auto-calculate aggregatedAmount
    useEffect(() => {
        // Convert to numeric
        const principal = parseFloat(formData.outstandingAmountDetails.totalPrincipal || 0);
        const markup = parseFloat(formData.outstandingAmountDetails.totalMarkUp || 0);
        const penal = parseFloat(formData.outstandingAmountDetails.penalCharges || 0);
        const paid = parseFloat(formData.outstandingAmountDetails.amountPaid || 0);

        // Basic aggregator formula (adjust as desired):
        // aggregatedAmount = principal + markup + penal - paid
        const aggregated = principal + markup + penal - paid;

        handleInputChange('outstandingAmountDetails', 'aggregatedAmount', aggregated.toString());
        // eslint-disable-next-line
    }, [
        formData.outstandingAmountDetails.totalPrincipal,
        formData.outstandingAmountDetails.totalMarkUp,
        formData.outstandingAmountDetails.penalCharges,
        formData.outstandingAmountDetails.amountPaid
    ]);

    // Basic validation
    const validateForm = () => {
        const formErrors = {};

        // Section 1: Debtor Information
        if (!formData.debtorInfo.companyName) {
            formErrors.companyName = 'Company Name is required.';
        }
        if (!formData.debtorInfo.address) {
            formErrors.debtorAddress = 'Debtor Address is required.';
        }

        // Section 2: Creditor (Bank) Information
        if (!formData.bankInfo.bankName) {
            formErrors.bankName = 'Bank Name is required.';
        }

        // Section 3: Outstanding Amount Details
        if (!formData.outstandingAmountDetails.totalPrincipal) {
            formErrors.totalPrincipal = 'Total Principal Amount is required.';
        }

        // Section 4: Dates and Deadlines
        if (!formData.datesDeadlines.overdueDate) {
            formErrors.overdueDate = 'Overdue Date is required.';
        }
        if (!formData.datesDeadlines.noticeIssuanceDate) {
            formErrors.noticeIssuanceDate = 'Notice Issuance Date is required.';
        }

        // Section 5: Notice Details
        if (!formData.noticeDetails.purposeOfNotice) {
            formErrors.purposeOfNotice = 'Purpose of the Notice is required.';
        }
        if (formData.noticeDetails.typeOfOffer === 'Other' && !formData.noticeDetails.otherOfferType) {
            formErrors.otherOfferType = 'Please specify the type of offer.';
        }
        if (!formData.noticeDetails.responseRequiredBy) {
            formErrors.responseRequiredBy = 'Response Required By Date is needed.';
        }

        return formErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        setErrors(formErrors);

        if (Object.keys(formErrors).length === 0) {
            onSubmit();
        }
    };

    return (
        <div>
            <h2 className="text-center">Notice for Negotiation or Settlement</h2>
            <form onSubmit={handleSubmit}>
                {/* Section 1: Debtor Information */}
                <h4 className="mt-4">Debtor Information</h4>
                <div className="form-group">
                    <label>Company Name:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.companyName ? 'is-invalid' : ''}`}
                        value={formData.debtorInfo.companyName || ''}
                        onChange={(e) =>
                            handleInputChange('debtorInfo', 'companyName', e.target.value)
                        }
                        placeholder="Enter Debtor's Company Name"
                    />
                    {errors.companyName && <div className="invalid-feedback">{errors.companyName}</div>}
                </div>
                <div className="form-group">
                    <label>Chief Executive's Name (optional):</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.debtorInfo.chiefExecName || ''}
                        onChange={(e) =>
                            handleInputChange('debtorInfo', 'chiefExecName', e.target.value)
                        }
                        placeholder="Enter Chief Executive's Name"
                    />
                </div>
                <div className="form-group mb-3">
                    <label>Address:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.debtorAddress ? 'is-invalid' : ''}`}
                        value={formData.debtorInfo.address || ''}
                        onChange={(e) => handleInputChange('debtorInfo', 'address', e.target.value)}
                        placeholder="Enter Debtor's Address"
                    />
                    {errors.debtorAddress && <div className="invalid-feedback">{errors.debtorAddress}</div>}
                </div>

                {/* Section 2: Creditor (Bank) Information */}
                <h4 className="mt-4">Creditor (Bank) Information</h4>
                <div className="form-group">
                    <label>Bank Name:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.bankName ? 'is-invalid' : ''}`}
                        value={formData.bankInfo.bankName || ''}
                        onChange={(e) =>
                            handleInputChange('bankInfo', 'bankName', e.target.value)
                        }
                        placeholder="Enter Bank Name"
                    />
                    {errors.bankName && <div className="invalid-feedback">{errors.bankName}</div>}
                </div>
                <div className="form-group mb-3">
                    <label>Head Office Address (optional):</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.bankInfo.headOfficeAddress || ''}
                        onChange={(e) =>
                            handleInputChange('bankInfo', 'headOfficeAddress', e.target.value)
                        }
                        placeholder="Enter Head Office Address"
                    />
                </div>

                {/* Section 3: Outstanding Amount Details */}
                <h4 className="mt-4">Outstanding Amount Details</h4>
                <div className="form-group">
                    <label>Total Principal Amount:</label>
                    <NumericFormat
                        className={`form-control ${errors.totalPrincipal ? 'is-invalid' : ''}`}
                        value={formData.outstandingAmountDetails.totalPrincipal || ''}
                        onValueChange={(values) => {
                            const { value } = values;
                            handleInputChange('outstandingAmountDetails', 'totalPrincipal', value);
                        }}
                        thousandSeparator
                        placeholder="Enter Total Principal Amount"
                        isNumericString
                    />
                    {errors.totalPrincipal && (
                        <div className="invalid-feedback">{errors.totalPrincipal}</div>
                    )}
                </div>
                <div className="form-group">
                    <label>Total Mark-up:</label>
                    <NumericFormat
                        className="form-control"
                        value={formData.outstandingAmountDetails.totalMarkUp || ''}
                        onValueChange={(values) => {
                            const { value } = values;
                            handleInputChange('outstandingAmountDetails', 'totalMarkUp', value);
                        }}
                        thousandSeparator
                        placeholder="Enter Total Mark-up"
                        isNumericString
                    />
                </div>
                <div className="form-group">
                    <label>Penal Charges (if any):</label>
                    <NumericFormat
                        className="form-control"
                        value={formData.outstandingAmountDetails.penalCharges || ''}
                        onValueChange={(values) => {
                            const { value } = values;
                            handleInputChange('outstandingAmountDetails', 'penalCharges', value);
                        }}
                        thousandSeparator
                        placeholder="Enter Penal Charges"
                        isNumericString
                    />
                </div>
                <div className="form-group">
                    <label>Amount Paid (if any):</label>
                    <NumericFormat
                        className="form-control"
                        value={formData.outstandingAmountDetails.amountPaid || ''}
                        onValueChange={(values) => {
                            const { value } = values;
                            handleInputChange('outstandingAmountDetails', 'amountPaid', value);
                        }}
                        thousandSeparator
                        placeholder="Enter Amount Paid after notice"
                        isNumericString
                    />
                </div>
                <div className="form-group mb-3">
                    <label>Aggregated Amount (Auto-Calculated):</label>
                    <NumericFormat
                        className="form-control"
                        value={formData.outstandingAmountDetails.aggregatedAmount || '0'}
                        thousandSeparator
                        isNumericString
                        disabled
                    />
                </div>

                {/* Section 4: Dates and Deadlines */}
                <h4 className="mt-4">Dates and Deadlines</h4>
                <div className="form-group">
                    <label>Overdue Date:</label>
                    <input
                        type="date"
                        className={`form-control ${errors.overdueDate ? 'is-invalid' : ''}`}
                        value={formData.datesDeadlines.overdueDate || ''}
                        onChange={(e) =>
                            handleInputChange('datesDeadlines', 'overdueDate', e.target.value)
                        }
                    />
                    {errors.overdueDate && <div className="invalid-feedback">{errors.overdueDate}</div>}
                </div>
                <div className="form-group mb-3">
                    <label>Notice Issuance Date:</label>
                    <input
                        type="date"
                        className={`form-control ${errors.noticeIssuanceDate ? 'is-invalid' : ''}`}
                        value={formData.datesDeadlines.noticeIssuanceDate || ''}
                        onChange={(e) =>
                            handleInputChange('datesDeadlines', 'noticeIssuanceDate', e.target.value)
                        }
                    />
                    {errors.noticeIssuanceDate && (
                        <div className="invalid-feedback">{errors.noticeIssuanceDate}</div>
                    )}
                </div>

                {/* Section 5: Notice Details */}
                <h4 className="mt-4">Notice Details</h4>
                <div className="form-group">
                    <label>Purpose of the Notice:</label>
                    <select
                        className={`form-control ${errors.purposeOfNotice ? 'is-invalid' : ''}`}
                        value={formData.noticeDetails.purposeOfNotice || ''}
                        onChange={(e) => handleInputChange('noticeDetails', 'purposeOfNotice', e.target.value)}
                    >
                        <option value="">-- Select Purpose --</option>
                        <option value="Recovery of Outstanding Amount">Recovery of Outstanding Amount</option>
                        <option value="Proposal for Settlement">Proposal for Settlement</option>
                        <option value="Demand for Immediate Payment">Demand for Immediate Payment</option>
                        <option value="Proposed Negotiation/Settlement Terms">Proposed Negotiation/Settlement Terms</option>
                    </select>
                    {errors.purposeOfNotice && (
                        <div className="invalid-feedback">{errors.purposeOfNotice}</div>
                    )}
                </div>
                <div className="form-group">
                    <label>Type of Offer:</label>
                    <select
                        className="form-control"
                        value={formData.noticeDetails.typeOfOffer || ''}
                        onChange={(e) => handleInputChange('noticeDetails', 'typeOfOffer', e.target.value)}
                    >
                        <option value="">-- Select --</option>
                        <option value="Lump-Sum Payment">Lump-Sum Payment</option>
                        <option value="Installment Plan">Installment Plan</option>
                        <option value="Discounted Repayment">Discounted Repayment</option>
                        <option value="Other">Other (Specify)</option>
                    </select>
                </div>
                {formData.noticeDetails.typeOfOffer === 'Other' && (
                    <div className="form-group">
                        <label>Please Specify Other Offer Type:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.otherOfferType ? 'is-invalid' : ''}`}
                            value={formData.noticeDetails.otherOfferType || ''}
                            onChange={(e) =>
                                handleInputChange('noticeDetails', 'otherOfferType', e.target.value)
                            }
                            placeholder="Enter other offer type"
                        />
                        {errors.otherOfferType && (
                            <div className="invalid-feedback">{errors.otherOfferType}</div>
                        )}
                    </div>
                )}
                <div className="form-group">
                    <label>Proposed Amount (if applicable):</label>
                    <NumericFormat
                        className="form-control"
                        value={formData.noticeDetails.proposedAmount || ''}
                        onValueChange={(values) => {
                            const { value } = values;
                            handleInputChange('noticeDetails', 'proposedAmount', value);
                        }}
                        thousandSeparator
                        placeholder="Enter Proposed Settlement Amount"
                        isNumericString
                    />
                </div>
                <div className="form-group">
                    <label>Proposed Payment Schedule (Timeline/Installments):</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.noticeDetails.proposedSchedule || ''}
                        onChange={(e) =>
                            handleInputChange('noticeDetails', 'proposedSchedule', e.target.value)
                        }
                        placeholder="e.g., monthly installments for 6 months"
                    />
                </div>
                <div className="form-group">
                    <label>Reason for Settlement Proposal (Optional):</label>
                    <textarea
                        className="form-control"
                        value={formData.noticeDetails.reasonForProposal || ''}
                        onChange={(e) =>
                            handleInputChange('noticeDetails', 'reasonForProposal', e.target.value)
                        }
                        placeholder="e.g., financial hardship, amicable resolution, etc."
                    ></textarea>
                </div>
                <div className="form-group mb-3">
                    <label>Response Required By (Deadline):</label>
                    <input
                        type="date"
                        className={`form-control ${errors.responseRequiredBy ? 'is-invalid' : ''}`}
                        value={formData.noticeDetails.responseRequiredBy || ''}
                        onChange={(e) =>
                            handleInputChange('noticeDetails', 'responseRequiredBy', e.target.value)
                        }
                    />
                    {errors.responseRequiredBy && (
                        <div className="invalid-feedback">{errors.responseRequiredBy}</div>
                    )}
                </div>

                {/* Section 6: Supporting Documents */}
                <h4 className="mt-4">Supporting Documents</h4>
                <div className="form-group mb-3">
                    <label>Documents List:</label>
                    <textarea
                        className="form-control"
                        value={formData.supportingDocuments.list || ''}
                        onChange={(e) => handleInputChange('supportingDocuments', 'list', e.target.value)}
                        placeholder="Loan Agreement Copy, Outstanding Balance Statement, etc."
                    ></textarea>
                </div>

                {/* Section 7: Legal References (Optional) */}
                <h4 className="mt-4">Legal References</h4>
                <div className="form-group mb-3">
                    <label>Cited Legal Provisions:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.legalReferences.citedProvisions || ''}
                        onChange={(e) =>
                            handleInputChange('legalReferences', 'citedProvisions', e.target.value)
                        }
                        placeholder="E.g., Financial Institutions (Recovery of Finances) Ordinance, 2001"
                    />
                </div>

                {/* Submit Button */}
                <button
                    type="submit"
                    className="btn mt-4"
                    style={{
                        backgroundColor: '#166432',
                        color: '#C59D55',
                        border: 'none',
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.backgroundColor = '#092415';
                        e.target.style.color = '#fff';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.backgroundColor = '#166432';
                        e.target.style.color = '#C59D55';
                    }}
                >
                    Next
                </button>
            </form>
        </div>
    );
}

export default NegotiationSettlementForm;

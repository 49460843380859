import React from 'react';
import axios from 'axios';
import style from './Navbar.module.css';
import { Link, useNavigate } from 'react-router-dom';

export default function Navbar({ setIsOpen, isOpen }) {
    const commonUrl = process.env.REACT_APP_COMMON_URL;
    const navigate = useNavigate();
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleLogout = async () => {
        try {
            const token = localStorage.getItem('access_token');
            await axios.post(
                `${commonUrl}/api/logout`,
                {},
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            localStorage.clear();
            navigate('/', { state: { message: 'You have been logged out successfully.' } });
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    return (
        <nav className={`${style.navbar} navbar-expand navbar-light navbar-bg d-flex justify-content-between align-items-center`}>
            <div className="d-flex align-items-center">
                <a className={style.sidebar_toggle} onClick={toggleSidebar}>
                    <i className={`${style.hamburger}`}></i>
                </a>
                <Link to="/dashboard" className={style.sidebar_brand}>
                    <span>PAK LAW ASSIST</span>
                    <span className={style.sub}> - Revolutionizing Legal Realm (Beta)</span>
                </Link>
            </div>
            <div className="d-flex align-items-center">
                <Link to="/dashboard" className={`${style.logout} btn btn-sm`}>Home</Link>
                <Link to="/dashboard/feedback" className={`${style.logout} btn btn-sm mx-2`}>Feedback</Link>
                <button className={`${style.logout} btn btn-sm`} onClick={handleLogout}>Logout</button>
                {/* Circular Button to User Profile */}
                <Link to="/dashboard/userprofile" className="btn btn-dark rounded-circle mx-2" style={{ width: '40px', height: '40px', padding: '0' }}>
                    <i className="fa fa-user" style={{ fontSize: '20px', lineHeight: '40px', color: '#fff' }}></i>
                </Link>
            </div>
        </nav>
    );
}

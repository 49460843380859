import React, { useState } from 'react';

function DeclarationSuitForm({ formData, setFormData, onSubmit }) {
    const [errors, setErrors] = useState({});

    const handleInputChange = (section, field, value) => {
        if (section) {
            setFormData(prev => ({
                ...prev,
                [section]: {
                    ...prev[section],
                    [field]: value,
                },
            }));
        } else {
            // For top-level fields like declarationType
            setFormData(prev => ({
                ...prev,
                [field]: value,
            }));
        }
    };

    const validateForm = () => {
        let formErrors = {};

        // Basic validation examples:
        if (!formData.declarationType) formErrors.declarationType = "Declaration type is required.";

        // Plaintiff Details
        if (!formData.plaintiffDetails.name) formErrors.plaintiffName = "Plaintiff's full name is required.";
        if (!formData.plaintiffDetails.address) formErrors.plaintiffAddress = "Plaintiff's address is required.";

        // Defendant Details
        if (!formData.defendantDetails.name) formErrors.defendantName = "Defendant's full name is required.";
        if (!formData.defendantDetails.address) formErrors.defendantAddress = "Defendant's address is required.";

        // Case Summary
        if (!formData.caseSummary.subjectOfDeclaration) formErrors.subjectOfDeclaration = "Subject of declaration is required.";
        if (!formData.caseSummary.causeOfAction) formErrors.causeOfAction = "Cause of action details are required.";

        // Relief Sought
        if (!formData.reliefSought.specificDeclaration) formErrors.specificDeclaration = "Specific declaration sought is required.";

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            onSubmit();
        }
    };

    return (
        <div className="mb-2">
            <h2 className="text-center">Declaration Suit Form</h2>
            <form onSubmit={handleSubmit}>

                {/* 1. Type of Declaration */}
                <h4 className="mt-4">1. Type of Declaration</h4>
                <div className="form-group">
                    <label>Subject of Declaration:</label>
                    <select
                        className={`form-control ${errors.declarationType ? 'is-invalid' : ''}`}
                        value={formData.declarationType || ""}
                        onChange={(e) => handleInputChange('', 'declarationType', e.target.value)}
                    >
                        <option value="">Select Declaration Type</option>
                        <option value="Property Ownership">Property Ownership</option>
                        <option value="Legal Heirship">Legal Heirship</option>
                        <option value="Contractual Disputes">Contractual Disputes</option>
                        <option value="Marital or Citizenship Status">Marital or Citizenship Status</option>
                        <option value="Financial or Business Matters">Financial or Business Matters</option>
                    </select>
                    {errors.declarationType && <div className="invalid-feedback">{errors.declarationType}</div>}
                </div>

                {/* 2. Parties */}
                <h4 className="mt-4">2. Parties</h4>
                <h5 className="mt-3">Plaintiff Details</h5>
                <div className="form-group">
                    <label>Full Name:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.plaintiffName ? 'is-invalid' : ''}`}
                        value={formData.plaintiffDetails.name || ""}
                        onChange={(e) => handleInputChange('plaintiffDetails', 'name', e.target.value)}
                        placeholder="Enter Plaintiff's Full Name"
                    />
                    {errors.plaintiffName && <div className="invalid-feedback">{errors.plaintiffName}</div>}
                </div>
                <div className="form-group">
                    <label>CNIC/ID Number:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.plaintiffDetails.cnic || ""}
                        onChange={(e) => handleInputChange('plaintiffDetails', 'cnic', e.target.value)}
                        placeholder="Enter Plaintiff's CNIC/ID"
                    />
                </div>
                <div className="form-group">
                    <label>Address:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.plaintiffAddress ? 'is-invalid' : ''}`}
                        value={formData.plaintiffDetails.address || ""}
                        onChange={(e) => handleInputChange('plaintiffDetails', 'address', e.target.value)}
                        placeholder="Enter Plaintiff's Address"
                    />
                    {errors.plaintiffAddress && <div className="invalid-feedback">{errors.plaintiffAddress}</div>}
                </div>
                <div className="form-group mb-3">
                    <label>Contact Information:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.plaintiffDetails.contactInfo || ""}
                        onChange={(e) => handleInputChange('plaintiffDetails', 'contactInfo', e.target.value)}
                        placeholder="Enter Plaintiff's Contact Info"
                    />
                </div>

                <h5 className="mt-3">Defendant Details</h5>
                <div className="form-group">
                    <label>Full Name:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.defendantName ? 'is-invalid' : ''}`}
                        value={formData.defendantDetails.name || ""}
                        onChange={(e) => handleInputChange('defendantDetails', 'name', e.target.value)}
                        placeholder="Enter Defendant's Full Name"
                    />
                    {errors.defendantName && <div className="invalid-feedback">{errors.defendantName}</div>}
                </div>
                <div className="form-group">
                    <label>CNIC/ID Number (if available):</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.defendantDetails.cnic || ""}
                        onChange={(e) => handleInputChange('defendantDetails', 'cnic', e.target.value)}
                        placeholder="Enter Defendant's CNIC/ID"
                    />
                </div>
                <div className="form-group">
                    <label>Address:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.defendantAddress ? 'is-invalid' : ''}`}
                        value={formData.defendantDetails.address || ""}
                        onChange={(e) => handleInputChange('defendantDetails', 'address', e.target.value)}
                        placeholder="Enter Defendant's Address"
                    />
                    {errors.defendantAddress && <div className="invalid-feedback">{errors.defendantAddress}</div>}
                </div>
                <div className="form-group mb-3">
                    <label>Relationship to Plaintiff:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.defendantDetails.relationship || ""}
                        onChange={(e) => handleInputChange('defendantDetails', 'relationship', e.target.value)}
                        placeholder="e.g., co-heir, tenant, buyer"
                    />
                </div>

                {/* 3. Jurisdiction */}
                <h4 className="mt-4">3. Jurisdiction</h4>
                <div className="form-group">
                    <label>Territorial Jurisdiction (Basis):</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.jurisdiction.territorial || ""}
                        onChange={(e) => handleInputChange('jurisdiction', 'territorial', e.target.value)}
                        placeholder="Reason for this court (e.g., property location, defendant’s residence)"
                    />
                </div>
                <div className="form-group mb-3">
                    <label>Pecuniary Jurisdiction (If Applicable):</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.jurisdiction.pecuniary || ""}
                        onChange={(e) => handleInputChange('jurisdiction', 'pecuniary', e.target.value)}
                        placeholder="Estimated monetary value of the claim"
                    />
                </div>

                {/* 4. Case Summary */}
                <h4 className="mt-4">4. Case Summary</h4>
                <div className="form-group">
                    <label>Brief Description of the Declaration Sought:</label>
                    <textarea
                        className={`form-control ${errors.subjectOfDeclaration ? 'is-invalid' : ''}`}
                        value={formData.caseSummary.subjectOfDeclaration || ""}
                        onChange={(e) => handleInputChange('caseSummary', 'subjectOfDeclaration', e.target.value)}
                        placeholder="e.g., Ownership of property, validity of agreement"
                    ></textarea>
                    {errors.subjectOfDeclaration && <div className="invalid-feedback">{errors.subjectOfDeclaration}</div>}
                </div>
                <div className="form-group mb-3">
                    <label>Cause of Action (Key Facts):</label>
                    <textarea
                        className={`form-control ${errors.causeOfAction ? 'is-invalid' : ''}`}
                        value={formData.caseSummary.causeOfAction || ""}
                        onChange={(e) => handleInputChange('caseSummary', 'causeOfAction', e.target.value)}
                        placeholder="e.g., Disputed property purchase, denial of legal heirship"
                    ></textarea>
                    {errors.causeOfAction && <div className="invalid-feedback">{errors.causeOfAction}</div>}
                </div>

                {/* 5. Relief Sought */}
                <h4 className="mt-4">5. Relief Sought</h4>
                <div className="form-group mb-3">
                    <label>Specific Declaration:</label>
                    <textarea
                        className={`form-control ${errors.specificDeclaration ? 'is-invalid' : ''}`}
                        value={formData.reliefSought.specificDeclaration || ""}
                        onChange={(e) => handleInputChange('reliefSought', 'specificDeclaration', e.target.value)}
                        placeholder='e.g., "Declare the plaintiff as the rightful owner of the property"'
                    ></textarea>
                    {errors.specificDeclaration && <div className="invalid-feedback">{errors.specificDeclaration}</div>}
                </div>

                {/* 6. Supporting Documents */}
                <h4 className="mt-4">6. Supporting Documents</h4>
                <div className="form-group mb-3">
                    <label>List of Attached Documents:</label>
                    <textarea
                        className="form-control"
                        value={formData.supportingDocuments.list || ""}
                        onChange={(e) => handleInputChange('supportingDocuments', 'list', e.target.value)}
                        placeholder="e.g., Sale deeds, death certificates, agreements, CNIC copies"
                    ></textarea>
                </div>

                {/* Conditional Fields Based on Declaration Type */}
                {formData.declarationType === "Property Ownership" && (
                    <>
                        <h5 className="mt-3">Property Ownership Details</h5>
                        <div className="form-group">
                            <label>Property Address/Location:</label>
                            <input
                                type="text"
                                className="form-control"
                                value={formData.propertyOwnership.propertyAddress || ""}
                                onChange={(e) => handleInputChange('propertyOwnership', 'propertyAddress', e.target.value)}
                                placeholder="Enter property address"
                            />
                        </div>
                        <div className="form-group">
                            <label>Area/Size:</label>
                            <input
                                type="text"
                                className="form-control"
                                value={formData.propertyOwnership.propertyArea || ""}
                                onChange={(e) => handleInputChange('propertyOwnership', 'propertyArea', e.target.value)}
                                placeholder="Area/Size of property"
                            />
                        </div>
                        <div className="form-group">
                            <label>Ownership Documents:</label>
                            <textarea
                                className="form-control"
                                value={formData.propertyOwnership.ownershipDocs || ""}
                                onChange={(e) => handleInputChange('propertyOwnership', 'ownershipDocs', e.target.value)}
                                placeholder="List ownership documents (e.g., sale deed, mutation records)"
                            ></textarea>
                        </div>
                        <div className="form-group mb-3">
                            <label>Nature of Dispute:</label>
                            <textarea
                                className="form-control"
                                value={formData.propertyOwnership.disputeNature || ""}
                                onChange={(e) => handleInputChange('propertyOwnership', 'disputeNature', e.target.value)}
                                placeholder="e.g., title fraud, adverse possession, inheritance conflict"
                            ></textarea>
                        </div>
                    </>
                )}

                {formData.declarationType === "Legal Heirship" && (
                    <>
                        <h5 className="mt-3">Legal Heirship Details</h5>
                        <div className="form-group">
                            <label>Deceased's Name:</label>
                            <input
                                type="text"
                                className="form-control"
                                value={formData.legalHeirship.deceasedName || ""}
                                onChange={(e) => handleInputChange('legalHeirship', 'deceasedName', e.target.value)}
                                placeholder="Enter Deceased's Name"
                            />
                        </div>
                        <div className="form-group">
                            <label>Deceased's CNIC/ID Number:</label>
                            <input
                                type="text"
                                className="form-control"
                                value={formData.legalHeirship.deceasedCnic || ""}
                                onChange={(e) => handleInputChange('legalHeirship', 'deceasedCnic', e.target.value)}
                                placeholder="Enter Deceased's CNIC/ID"
                            />
                        </div>
                        <div className="form-group">
                            <label>Date of Death:</label>
                            <input
                                type="date"
                                className="form-control"
                                value={formData.legalHeirship.dateOfDeath || ""}
                                onChange={(e) => handleInputChange('legalHeirship', 'dateOfDeath', e.target.value)}
                            />
                        </div>
                        <div className="form-group mb-3">
                            <label>List of Potential Heirs (Names, Ages, Relationship):</label>
                            <textarea
                                className="form-control"
                                value={formData.legalHeirship.potentialHeirs || ""}
                                onChange={(e) => handleInputChange('legalHeirship', 'potentialHeirs', e.target.value)}
                                placeholder="e.g., John Doe (age 30, Son), Jane Doe (age 28, Daughter)"
                            ></textarea>
                        </div>
                    </>
                )}

                {formData.declarationType === "Contractual Disputes" && (
                    <>
                        <h5 className="mt-3">Contractual Dispute Details</h5>
                        <div className="form-group">
                            <label>Contract Date of Execution:</label>
                            <input
                                type="date"
                                className="form-control"
                                value={formData.contractualDisputes.executionDate || ""}
                                onChange={(e) => handleInputChange('contractualDisputes', 'executionDate', e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Parties Involved:</label>
                            <textarea
                                className="form-control"
                                value={formData.contractualDisputes.partiesInvolved || ""}
                                onChange={(e) => handleInputChange('contractualDisputes', 'partiesInvolved', e.target.value)}
                                placeholder="List all parties in the contract"
                            ></textarea>
                        </div>
                        <div className="form-group">
                            <label>Key Terms of Agreement:</label>
                            <textarea
                                className="form-control"
                                value={formData.contractualDisputes.keyTerms || ""}
                                onChange={(e) => handleInputChange('contractualDisputes', 'keyTerms', e.target.value)}
                                placeholder="Briefly describe the main terms"
                            ></textarea>
                        </div>
                        <div className="form-group mb-3">
                            <label>Nature of Dispute:</label>
                            <textarea
                                className="form-control"
                                value={formData.contractualDisputes.disputeNature || ""}
                                onChange={(e) => handleInputChange('contractualDisputes', 'disputeNature', e.target.value)}
                                placeholder="e.g., forged signature, breach of terms"
                            ></textarea>
                        </div>
                    </>
                )}

                {formData.declarationType === "Marital or Citizenship Status" && (
                    <>
                        <h5 className="mt-3">Marital/Citizenship Details</h5>
                        <div className="form-group">
                            <label>Marital Status Details (if applicable):</label>
                            <textarea
                                className="form-control"
                                value={formData.maritalCitizenship.maritalDetails || ""}
                                onChange={(e) => handleInputChange('maritalCitizenship', 'maritalDetails', e.target.value)}
                                placeholder="Date of marriage, spouse’s name"
                            ></textarea>
                        </div>
                        <div className="form-group">
                            <label>Citizenship Details (if applicable):</label>
                            <textarea
                                className="form-control"
                                value={formData.maritalCitizenship.citizenshipDetails || ""}
                                onChange={(e) => handleInputChange('maritalCitizenship', 'citizenshipDetails', e.target.value)}
                                placeholder="Nationality, basis of claim"
                            ></textarea>
                        </div>
                        <div className="form-group mb-3">
                            <label>Supporting Documents:</label>
                            <textarea
                                className="form-control"
                                value={formData.maritalCitizenship.supportingDocs || ""}
                                onChange={(e) => handleInputChange('maritalCitizenship', 'supportingDocs', e.target.value)}
                                placeholder="e.g., Marriage certificate, divorce papers, naturalization documents"
                            ></textarea>
                        </div>
                    </>
                )}

                {formData.declarationType === "Financial or Business Matters" && (
                    <>
                        <h5 className="mt-3">Financial/Business Details</h5>
                        <div className="form-group">
                            <label>Nature of Financial Relationship:</label>
                            <textarea
                                className="form-control"
                                value={formData.financialBusiness.nature || ""}
                                onChange={(e) => handleInputChange('financialBusiness', 'nature', e.target.value)}
                                placeholder="e.g., debt, partnership"
                            ></textarea>
                        </div>
                        <div className="form-group">
                            <label>Relevant Transactions and Their Value:</label>
                            <textarea
                                className="form-control"
                                value={formData.financialBusiness.transactions || ""}
                                onChange={(e) => handleInputChange('financialBusiness', 'transactions', e.target.value)}
                                placeholder="List key transactions and amounts"
                            ></textarea>
                        </div>
                        <div className="form-group mb-3">
                            <label>Business Documentation:</label>
                            <textarea
                                className="form-control"
                                value={formData.financialBusiness.documents || ""}
                                onChange={(e) => handleInputChange('financialBusiness', 'documents', e.target.value)}
                                placeholder="e.g., partnership agreements, financial statements"
                            ></textarea>
                        </div>
                    </>
                )}

                {/* 7. Other Details */}
                <h4 className="mt-4">7. Other Details (If Any)</h4>
                <div className="form-group">
                    <label>Opposing Claims (Details of Objections Raised):</label>
                    <textarea
                        className="form-control"
                        value={formData.otherDetails.opposingClaims || ""}
                        onChange={(e) => handleInputChange('otherDetails', 'opposingClaims', e.target.value)}
                        placeholder="Any objections raised by defendant or other parties"
                    ></textarea>
                </div>
                <div className="form-group">
                    <label>Prior Legal Action (If Any):</label>
                    <textarea
                        className="form-control"
                        value={formData.otherDetails.priorAction || ""}
                        onChange={(e) => handleInputChange('otherDetails', 'priorAction', e.target.value)}
                        placeholder="Whether any related suits or claims have been previously filed"
                    ></textarea>
                </div>
                <div className="form-group mb-3">
                    <label>Witness Details (If Any):</label>
                    <textarea
                        className="form-control"
                        value={formData.otherDetails.witnesses || ""}
                        onChange={(e) => handleInputChange('otherDetails', 'witnesses', e.target.value)}
                        placeholder="Names and contact info of witnesses"
                    ></textarea>
                </div>

                <button
                    type="submit"
                    className="btn mt-4"
                    style={{
                        backgroundColor: '#166432',
                        color: '#C59D55',
                        border: 'none',
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.backgroundColor = '#092415';
                        e.target.style.color = '#fff';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.backgroundColor = '#166432';
                        e.target.style.color = '#C59D55';
                    }}
                >
                    Next
                </button>
            </form>
        </div>
    );
}

export default DeclarationSuitForm;

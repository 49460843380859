import React, { useState } from 'react';

function InjunctionApplicationForm({ formData, setFormData, onSubmit }) {
    const [errors, setErrors] = useState({});

    // Handle updates for both top-level and nested state objects
    const handleInputChange = (section, field, value) => {
        if (section) {
            setFormData((prev) => ({
                ...prev,
                [section]: {
                    ...prev[section],
                    [field]: value,
                },
            }));
        } else {
            setFormData((prev) => ({
                ...prev,
                [field]: value,
            }));
        }
    };

    // Basic form validation (customize based on your requirements)
    const validateForm = () => {
        let formErrors = {};

        // 1. Injunction Type
        if (!formData.injunctionType) {
            formErrors.injunctionType = "Please select the type of injunction.";
        }

        // 2. Court Name
        if (!formData.courtName) {
            formErrors.courtName = "Court Name is required.";
        }

        // 3. Case Details
        if (!formData.caseDetails.caseTitle) {
            formErrors.caseTitle = "Case Title is required.";
        }
        if (!formData.caseDetails.caseNumber) {
            formErrors.caseNumber = "Case Number is required.";
        }
        if (!formData.caseDetails.courtName) {
            formErrors.caseDetailsCourtName = "Please enter the court name for the case.";
        }

        // 4. Applicant (Plaintiff) Details
        if (!formData.applicantDetails.name) {
            formErrors.applicantName = "Applicant's Full Name is required.";
        }
        if (!formData.applicantDetails.address) {
            formErrors.applicantAddress = "Applicant's Address is required.";
        }

        // 5. Respondent (Defendant) Details
        if (!formData.respondentDetails.name) {
            formErrors.respondentName = "Respondent's Full Name is required.";
        }
        if (!formData.respondentDetails.address) {
            formErrors.respondentAddress = "Respondent's Address is required.";
        }

        // 6. Common Fields
        if (!formData.commonFields.subjectOfApplication) {
            formErrors.subjectOfApplication = "Subject of Application is required.";
        }
        if (!formData.commonFields.causeOfAction) {
            formErrors.causeOfAction = "Cause of Action is required.";
        }
        if (!formData.commonFields.reliefSought) {
            formErrors.reliefSought = "Relief Sought is required.";
        }
        if (!formData.commonFields.urgencyOfRelief) {
            formErrors.urgencyOfRelief = "Please explain the urgency of this injunction.";
        }

        // 7. Conditional Validations
        if (formData.injunctionType === "Restraining Injunction Application") {
            if (!formData.restrainingInjunction.specificAct) {
                formErrors.specificActRestraining = "Please specify the act to be restrained.";
            }
            if (!formData.restrainingInjunction.balanceOfConvenience) {
                formErrors.balanceOfConvenience =
                    "Please explain the balance of convenience for the restraining injunction.";
            }
        }

        if (formData.injunctionType === "Mandatory Injunction Application") {
            if (!formData.mandatoryInjunction.specificAct) {
                formErrors.specificActMandatory = "Please specify the act to be compelled.";
            }
            if (!formData.mandatoryInjunction.irreparableHarm) {
                formErrors.irreparableHarm =
                    "Please explain the irreparable harm if the injunction is not granted.";
            }
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            onSubmit();
        }
    };

    return (
        <div className="mb-2">
            <h2 className="text-center">Temporary Injunction Application</h2>
            <p className="text-center">
                <em>Reference: Order XXXIX, Rules 1 &amp; 2 of the CPC, 1908</em>
            </p>

            <form onSubmit={handleSubmit}>
                {/* 1. Court Name */}
                <div className="form-group">
                    <label>Court Name:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.courtName ? 'is-invalid' : ''}`}
                        value={formData.courtName || ""}
                        onChange={(e) => handleInputChange('', 'courtName', e.target.value)}
                        placeholder="Enter the name of the court"
                    />
                    {errors.courtName && (
                        <div className="invalid-feedback">{errors.courtName}</div>
                    )}
                </div>

                {/* 2. Select Type of Injunction */}
                <h4 className="mt-4">Type of Injunction</h4>
                <div className="form-group">
                    <label>Injunction Type:</label>
                    <select
                        className={`form-control ${errors.injunctionType ? 'is-invalid' : ''}`}
                        value={formData.injunctionType || ""}
                        onChange={(e) => handleInputChange('', 'injunctionType', e.target.value)}
                    >
                        <option value="">Select Injunction Type</option>
                        <option value="Restraining Injunction Application">
                            Restraining Injunction
                        </option>
                        <option value="Mandatory Injunction Application">
                            Mandatory Injunction
                        </option>
                    </select>
                    {errors.injunctionType && (
                        <div className="invalid-feedback">{errors.injunctionType}</div>
                    )}
                </div>

                {/* 3. Case Details */}
                <h4 className="mt-4">Case Details</h4>
                <div className="form-group">
                    <label>Case Title (e.g., Plaintiff vs. Defendant):</label>
                    <input
                        type="text"
                        className={`form-control ${errors.caseTitle ? 'is-invalid' : ''}`}
                        value={formData.caseDetails.caseTitle || ""}
                        onChange={(e) => handleInputChange('caseDetails', 'caseTitle', e.target.value)}
                        placeholder="Enter Case Title"
                    />
                    {errors.caseTitle && (
                        <div className="invalid-feedback">{errors.caseTitle}</div>
                    )}
                </div>
                <div className="form-group">
                    <label>Case Number:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.caseNumber ? 'is-invalid' : ''}`}
                        value={formData.caseDetails.caseNumber || ""}
                        onChange={(e) => handleInputChange('caseDetails', 'caseNumber', e.target.value)}
                        placeholder="Enter Case Number"
                    />
                    {errors.caseNumber && (
                        <div className="invalid-feedback">{errors.caseNumber}</div>
                    )}
                </div>
                <div className="form-group">
                    <label>Court Name (for this specific case):</label>
                    <input
                        type="text"
                        className={`form-control ${errors.caseDetailsCourtName ? 'is-invalid' : ''
                            }`}
                        value={formData.caseDetails.courtName || ""}
                        onChange={(e) =>
                            handleInputChange('caseDetails', 'courtName', e.target.value)
                        }
                        placeholder="Enter Court Name for the case"
                    />
                    {errors.caseDetailsCourtName && (
                        <div className="invalid-feedback">
                            {errors.caseDetailsCourtName}
                        </div>
                    )}
                </div>
                <div className="form-group mb-3">
                    <label>Existing Suit Details (if any):</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.caseDetails.existingSuitDetails || ""}
                        onChange={(e) =>
                            handleInputChange('caseDetails', 'existingSuitDetails', e.target.value)
                        }
                        placeholder="Details of any related or existing suit"
                    />
                </div>

                {/* 4. Applicant (Plaintiff) Details */}
                <h4 className="mt-4">Applicant (Plaintiff) Details</h4>
                <div className="form-group">
                    <label>Full Name:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.applicantName ? 'is-invalid' : ''}`}
                        value={formData.applicantDetails.name || ""}
                        onChange={(e) =>
                            handleInputChange('applicantDetails', 'name', e.target.value)
                        }
                        placeholder="Enter Applicant's Full Name"
                    />
                    {errors.applicantName && (
                        <div className="invalid-feedback">{errors.applicantName}</div>
                    )}
                </div>
                <div className="form-group">
                    <label>Address:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.applicantAddress ? 'is-invalid' : ''}`}
                        value={formData.applicantDetails.address || ""}
                        onChange={(e) =>
                            handleInputChange('applicantDetails', 'address', e.target.value)
                        }
                        placeholder="Enter Applicant's Address"
                    />
                    {errors.applicantAddress && (
                        <div className="invalid-feedback">{errors.applicantAddress}</div>
                    )}
                </div>
                <div className="form-group">
                    <label>CNIC/ID Number:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.applicantDetails.cnic || ""}
                        onChange={(e) =>
                            handleInputChange('applicantDetails', 'cnic', e.target.value)
                        }
                        placeholder="Enter Applicant's CNIC/ID"
                    />
                </div>
                <div className="form-group mb-3">
                    <label>Contact Information:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.applicantDetails.contactInfo || ""}
                        onChange={(e) =>
                            handleInputChange('applicantDetails', 'contactInfo', e.target.value)
                        }
                        placeholder="Enter Applicant's Contact Info"
                    />
                </div>

                {/* 5. Respondent (Defendant) Details */}
                <h4 className="mt-4">Respondent (Defendant) Details</h4>
                <div className="form-group">
                    <label>Full Name:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.respondentName ? 'is-invalid' : ''}`}
                        value={formData.respondentDetails.name || ""}
                        onChange={(e) =>
                            handleInputChange('respondentDetails', 'name', e.target.value)
                        }
                        placeholder="Enter Respondent's Full Name"
                    />
                    {errors.respondentName && (
                        <div className="invalid-feedback">{errors.respondentName}</div>
                    )}
                </div>
                <div className="form-group">
                    <label>Address:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.respondentAddress ? 'is-invalid' : ''
                            }`}
                        value={formData.respondentDetails.address || ""}
                        onChange={(e) =>
                            handleInputChange('respondentDetails', 'address', e.target.value)
                        }
                        placeholder="Enter Respondent's Address"
                    />
                    {errors.respondentAddress && (
                        <div className="invalid-feedback">{errors.respondentAddress}</div>
                    )}
                </div>
                <div className="form-group">
                    <label>CNIC/ID Number (optional):</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.respondentDetails.cnic || ""}
                        onChange={(e) =>
                            handleInputChange('respondentDetails', 'cnic', e.target.value)
                        }
                        placeholder="Enter Respondent's CNIC/ID"
                    />
                </div>
                <div className="form-group mb-3">
                    <label>Relationship to Plaintiff:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.respondentDetails.relationship || ""}
                        onChange={(e) =>
                            handleInputChange(
                                'respondentDetails',
                                'relationship',
                                e.target.value
                            )
                        }
                        placeholder="e.g., tenant, neighbor, business partner, etc."
                    />
                </div>

                {/* 6. Common Fields */}
                <h4 className="mt-4">Injunction Application Detail</h4>
                <div className="form-group">
                    <label>Subject of Application:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.subjectOfApplication ? 'is-invalid' : ''
                            }`}
                        value={formData.commonFields.subjectOfApplication || ""}
                        onChange={(e) =>
                            handleInputChange('commonFields', 'subjectOfApplication', e.target.value)
                        }
                        placeholder='e.g., "To halt illegal construction..." or "To compel removal of encroachment."'
                    />
                    {errors.subjectOfApplication && (
                        <div className="invalid-feedback">{errors.subjectOfApplication}</div>
                    )}
                </div>
                <div className="form-group">
                    <label>Cause of Action (Key Facts):</label>
                    <textarea
                        className={`form-control ${errors.causeOfAction ? 'is-invalid' : ''}`}
                        value={formData.commonFields.causeOfAction || ""}
                        onChange={(e) =>
                            handleInputChange('commonFields', 'causeOfAction', e.target.value)
                        }
                        placeholder="Explain the facts leading to this application"
                    ></textarea>
                    {errors.causeOfAction && (
                        <div className="invalid-feedback">{errors.causeOfAction}</div>
                    )}
                </div>
                <div className="form-group">
                    <label>Relief Sought:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.reliefSought ? 'is-invalid' : ''}`}
                        value={formData.commonFields.reliefSought || ""}
                        onChange={(e) =>
                            handleInputChange('commonFields', 'reliefSought', e.target.value)
                        }
                        placeholder='e.g., "Prevent the defendant from selling the property"'
                    />
                    {errors.reliefSought && (
                        <div className="invalid-feedback">{errors.reliefSought}</div>
                    )}
                </div>
                <div className="form-group">
                    <label>Urgency of Relief:</label>
                    <textarea
                        className={`form-control ${errors.urgencyOfRelief ? 'is-invalid' : ''}`}
                        value={formData.commonFields.urgencyOfRelief || ""}
                        onChange={(e) =>
                            handleInputChange('commonFields', 'urgencyOfRelief', e.target.value)
                        }
                        placeholder="Why do you need immediate relief?"
                    ></textarea>
                    {errors.urgencyOfRelief && (
                        <div className="invalid-feedback">{errors.urgencyOfRelief}</div>
                    )}
                </div>
                <div className="form-group mb-3">
                    <label>Supporting Evidence (List of Documents):</label>
                    <textarea
                        className="form-control"
                        value={formData.commonFields.supportingEvidence || ""}
                        onChange={(e) =>
                            handleInputChange('commonFields', 'supportingEvidence', e.target.value)
                        }
                        placeholder="e.g., property documents, photos/videos, CNIC copies..."
                    ></textarea>
                </div>

                {/* 7. Conditional Fields Based on Injunction Type */}
                {formData.injunctionType === "Restraining Injunction Application" && (
                    <>
                        <h4 className="mt-3">Restraining Injunction Details</h4>
                        <div className="form-group">
                            <label>Specific Act to Be Restrained:</label>
                            <input
                                type="text"
                                className={`form-control ${errors.specificActRestraining ? 'is-invalid' : ''
                                    }`}
                                value={formData.restrainingInjunction.specificAct || ""}
                                onChange={(e) =>
                                    handleInputChange('restrainingInjunction', 'specificAct', e.target.value)
                                }
                                placeholder='e.g., "Transfer of property," "Illegal construction," etc.'
                            />
                            {errors.specificActRestraining && (
                                <div className="invalid-feedback">
                                    {errors.specificActRestraining}
                                </div>
                            )}
                        </div>
                        <div className="form-group mb-3">
                            <label>Balance of Convenience:</label>
                            <textarea
                                className={`form-control ${errors.balanceOfConvenience ? 'is-invalid' : ''
                                    }`}
                                value={formData.restrainingInjunction.balanceOfConvenience || ""}
                                onChange={(e) =>
                                    handleInputChange(
                                        'restrainingInjunction',
                                        'balanceOfConvenience',
                                        e.target.value
                                    )
                                }
                                placeholder="Explain why granting the injunction will cause less harm to the defendant..."
                            ></textarea>
                            {errors.balanceOfConvenience && (
                                <div className="invalid-feedback">
                                    {errors.balanceOfConvenience}
                                </div>
                            )}
                        </div>
                    </>
                )}

                {formData.injunctionType === "Mandatory Injunction Application" && (
                    <>
                        <h4 className="mt-3">Mandatory Injunction Details</h4>
                        <div className="form-group">
                            <label>Specific Act to Be Compelled:</label>
                            <input
                                type="text"
                                className={`form-control ${errors.specificActMandatory ? 'is-invalid' : ''
                                    }`}
                                value={formData.mandatoryInjunction.specificAct || ""}
                                onChange={(e) =>
                                    handleInputChange('mandatoryInjunction', 'specificAct', e.target.value)
                                }
                                placeholder='e.g., "Restore access to shared pathway," or "Remove unauthorized construction"'
                            />
                            {errors.specificActMandatory && (
                                <div className="invalid-feedback">
                                    {errors.specificActMandatory}
                                </div>
                            )}
                        </div>
                        <div className="form-group mb-3">
                            <label>Irreparable Harm Without Injunction:</label>
                            <textarea
                                className={`form-control ${errors.irreparableHarm ? 'is-invalid' : ''}`}
                                value={formData.mandatoryInjunction.irreparableHarm || ""}
                                onChange={(e) =>
                                    handleInputChange('mandatoryInjunction', 'irreparableHarm', e.target.value)
                                }
                                placeholder="Explain the harm if the injunction is not granted"
                            ></textarea>
                            {errors.irreparableHarm && (
                                <div className="invalid-feedback">{errors.irreparableHarm}</div>
                            )}
                        </div>
                    </>
                )}

                {/* 8. Optional Fields for Complex Cases */}
                <h4 className="mt-4">Optional Fields (If Any)</h4>
                <div className="form-group">
                    <label>Prior Legal Actions:</label>
                    <textarea
                        className="form-control"
                        value={formData.optionalFields.priorLegalActions || ""}
                        onChange={(e) =>
                            handleInputChange('optionalFields', 'priorLegalActions', e.target.value)
                        }
                        placeholder="Details of any previous or ongoing legal proceedings"
                    ></textarea>
                </div>
                <div className="form-group mb-3">
                    <label>Witness Information:</label>
                    <textarea
                        className="form-control"
                        value={formData.optionalFields.witnessInformation || ""}
                        onChange={(e) =>
                            handleInputChange('optionalFields', 'witnessInformation', e.target.value)
                        }
                        placeholder="Names and contact details of potential witnesses"
                    ></textarea>
                </div>

                {/* Submit Button */}
                <button
                    type="submit"
                    className="btn mt-4"
                    style={{
                        backgroundColor: '#166432',
                        color: '#C59D55',
                        border: 'none',
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.backgroundColor = '#092415';
                        e.target.style.color = '#fff';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.backgroundColor = '#166432';
                        e.target.style.color = '#C59D55';
                    }}
                >
                    Next
                </button>
            </form>
        </div>
    );
}

export default InjunctionApplicationForm;

import React, { useState, useEffect } from 'react';
import { NumericFormat } from 'react-number-format'; // Make sure to install via `npm install react-number-format`

function ExecutionApplicationForm({ formData, setFormData, onSubmit }) {
  const [errors, setErrors] = useState({});

  // Helper to update nested or root-level state
  const handleInputChange = (section, field, value) => {
    if (section) {
      setFormData((prev) => ({
        ...prev,
        [section]: {
          ...prev[section],
          [field]: value,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  // Auto-calculate totalDue
  useEffect(() => {
    const principal = parseFloat(formData.decreeDetails.principalAmount || 0);
    const interest = parseFloat(formData.decreeDetails.interestAmount || 0);
    const totalDue = principal + interest;

    handleInputChange('decreeDetails', 'totalAmountDue', totalDue.toString());
    // eslint-disable-next-line
  }, [
    formData.decreeDetails.principalAmount,
    formData.decreeDetails.interestAmount,
  ]);

  // Validate required fields
  const validateForm = () => {
    const formErrors = {};

    // Section 1: Basic Details
    if (!formData.basicDetails.courtName) {
      formErrors.courtName = 'Court Name is required.';
    }
    if (!formData.basicDetails.caseTitle) {
      formErrors.caseTitle = 'Case Title is required.';
    }
    if (!formData.basicDetails.caseNumber) {
      formErrors.caseNumber = 'Case Number is required.';
    }
    if (!formData.basicDetails.dateOfDecree) {
      formErrors.dateOfDecree = 'Date of Decree is required.';
    }
    if (!formData.basicDetails.decreeHolder.bankName) {
      formErrors.bankName = 'Bank Name (Decree-Holder) is required.';
    }
    if (!formData.basicDetails.decreeHolder.branchName) {
      formErrors.branchName = 'Branch Name (Decree-Holder) is required.';
    }
    if (!formData.basicDetails.judgmentDebtor.fullName) {
      formErrors.judgmentDebtorName = 'Judgment-Debtor Full Name is required.';
    }
    if (!formData.basicDetails.judgmentDebtor.address) {
      formErrors.judgmentDebtorAddress = 'Judgment-Debtor Address is required.';
    }

    // Section 2: Decree Details
    if (!formData.decreeDetails.natureOfDecree) {
      formErrors.natureOfDecree = 'Select the nature of the decree.';
    }
    if (!formData.decreeDetails.principalAmount) {
      formErrors.principalAmount = 'Principal Amount is required.';
    }
    if (!formData.decreeDetails.detailsOfOutstanding) {
      formErrors.detailsOfOutstanding = 'Details of Outstanding Amount are required.';
    }
    if (!formData.decreeDetails.executionSought) {
      formErrors.executionSought = 'Select the execution sought.';
    }

    return formErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formErrors = validateForm();
    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      onSubmit();
    }
  };

  return (
    <div>
      <h2 className="text-center">Execution Application</h2>

      <form onSubmit={handleSubmit}>
        {/* Section 1: Basic Details */}
        <div className="form-group">
          <label>Court Name:</label>
          <input
            type="text"
            className={`form-control ${errors.courtName ? 'is-invalid' : ''}`}
            value={formData.basicDetails.courtName || ''}
            onChange={(e) =>
              handleInputChange('basicDetails', 'courtName', e.target.value)
            }
            placeholder="Enter the name of the court"
          />
          {errors.courtName && <div className="invalid-feedback">{errors.courtName}</div>}
        </div>

        <h4 className="mt-3">Case Details</h4>
        <div className="form-group">
          <label>Case Title (e.g., Bank vs. Defendant):</label>
          <input
            type="text"
            className={`form-control ${errors.caseTitle ? 'is-invalid' : ''}`}
            value={formData.basicDetails.caseTitle || ''}
            onChange={(e) =>
              handleInputChange('basicDetails', 'caseTitle', e.target.value)
            }
            placeholder="Enter Case Title"
          />
          {errors.caseTitle && <div className="invalid-feedback">{errors.caseTitle}</div>}
        </div>
        <div className="form-group">
          <label>Case Number:</label>
          <input
            type="text"
            className={`form-control ${errors.caseNumber ? 'is-invalid' : ''}`}
            value={formData.basicDetails.caseNumber || ''}
            onChange={(e) =>
              handleInputChange('basicDetails', 'caseNumber', e.target.value)
            }
            placeholder="Enter Case Number"
          />
          {errors.caseNumber && <div className="invalid-feedback">{errors.caseNumber}</div>}
        </div>
        <div className="form-group">
          <label>Date of Decree:</label>
          <input
            type="date"
            className={`form-control ${errors.dateOfDecree ? 'is-invalid' : ''}`}
            value={formData.basicDetails.dateOfDecree || ''}
            onChange={(e) =>
              handleInputChange('basicDetails', 'dateOfDecree', e.target.value)
            }
          />
          {errors.dateOfDecree && <div className="invalid-feedback">{errors.dateOfDecree}</div>}
        </div>

        <h4 className="mt-3">Applicant (Decree-Holder) Details</h4>
        <div className="form-group">
          <label>Bank Name:</label>
          <input
            type="text"
            className={`form-control ${errors.bankName ? 'is-invalid' : ''}`}
            value={formData.basicDetails.decreeHolder.bankName || ''}
            onChange={(e) =>
              handleInputChange('basicDetails', 'decreeHolder', {
                ...formData.basicDetails.decreeHolder,
                bankName: e.target.value,
              })
            }
            placeholder="Enter Bank Name"
          />
          {errors.bankName && <div className="invalid-feedback">{errors.bankName}</div>}
        </div>
        <div className="form-group">
          <label>Branch Name:</label>
          <input
            type="text"
            className={`form-control ${errors.branchName ? 'is-invalid' : ''}`}
            value={formData.basicDetails.decreeHolder.branchName || ''}
            onChange={(e) =>
              handleInputChange('basicDetails', 'decreeHolder', {
                ...formData.basicDetails.decreeHolder,
                branchName: e.target.value,
              })
            }
            placeholder="Enter Branch Name"
          />
          {errors.branchName && <div className="invalid-feedback">{errors.branchName}</div>}
        </div>
        <div className="form-group">
          <label>Contact Information (Phone/Email):</label>
          <input
            type="text"
            className="form-control"
            value={formData.basicDetails.decreeHolder.contactInfo || ''}
            onChange={(e) =>
              handleInputChange('basicDetails', 'decreeHolder', {
                ...formData.basicDetails.decreeHolder,
                contactInfo: e.target.value,
              })
            }
            placeholder="Enter Contact Information"
          />
        </div>
        <div className="form-group mb-3">
          <label>Authorized Representative Name:</label>
          <input
            type="text"
            className="form-control"
            value={formData.basicDetails.decreeHolder.authorizedRep || ''}
            onChange={(e) =>
              handleInputChange('basicDetails', 'decreeHolder', {
                ...formData.basicDetails.decreeHolder,
                authorizedRep: e.target.value,
              })
            }
            placeholder="Enter Authorized Representative Name"
          />
        </div>

        <h4 className="mt-3">Defendant (Judgment-Debtor) Details</h4>
        <div className="form-group">
          <label>Full Name:</label>
          <input
            type="text"
            className={`form-control ${errors.judgmentDebtorName ? 'is-invalid' : ''}`}
            value={formData.basicDetails.judgmentDebtor.fullName || ''}
            onChange={(e) =>
              handleInputChange('basicDetails', 'judgmentDebtor', {
                ...formData.basicDetails.judgmentDebtor,
                fullName: e.target.value,
              })
            }
            placeholder="Enter Judgment-Debtor's Full Name"
          />
          {errors.judgmentDebtorName && (
            <div className="invalid-feedback">{errors.judgmentDebtorName}</div>
          )}
        </div>
        <div className="form-group">
          <label>Address:</label>
          <input
            type="text"
            className={`form-control ${errors.judgmentDebtorAddress ? 'is-invalid' : ''}`}
            value={formData.basicDetails.judgmentDebtor.address || ''}
            onChange={(e) =>
              handleInputChange('basicDetails', 'judgmentDebtor', {
                ...formData.basicDetails.judgmentDebtor,
                address: e.target.value,
              })
            }
            placeholder="Enter Judgment-Debtor's Address"
          />
          {errors.judgmentDebtorAddress && (
            <div className="invalid-feedback">{errors.judgmentDebtorAddress}</div>
          )}
        </div>
        <div className="form-group">
          <label>CNIC/ID Number (Optional):</label>
          <input
            type="text"
            className="form-control"
            value={formData.basicDetails.judgmentDebtor.cnic || ''}
            onChange={(e) =>
              handleInputChange('basicDetails', 'judgmentDebtor', {
                ...formData.basicDetails.judgmentDebtor,
                cnic: e.target.value,
              })
            }
            placeholder="Enter CNIC/ID Number"
          />
        </div>
        <div className="form-group mb-3">
          <label>Contact Information:</label>
          <input
            type="text"
            className="form-control"
            value={formData.basicDetails.judgmentDebtor.contactInfo || ''}
            onChange={(e) =>
              handleInputChange('basicDetails', 'judgmentDebtor', {
                ...formData.basicDetails.judgmentDebtor,
                contactInfo: e.target.value,
              })
            }
            placeholder="Enter Contact Info"
          />
        </div>

        {/* Section 2: Decree Details */}
        <h4 className="mt-4">Decree Details</h4>
        <div className="form-group">
          <label>Nature of Decree:</label>
          <select
            className={`form-control ${errors.natureOfDecree ? 'is-invalid' : ''}`}
            value={formData.decreeDetails.natureOfDecree || ''}
            onChange={(e) => handleInputChange('decreeDetails', 'natureOfDecree', e.target.value)}
          >
            <option value="">-- Select --</option>
            <option value="Recovery of Money">Recovery of Money</option>
            <option value="Possession of Property">Possession of Property</option>
            <option value="Delivery of Goods">Delivery of Goods</option>
            <option value="Specific Performance">Specific Performance</option>
            <option value="Other">Other (Specify)</option>
          </select>
          {errors.natureOfDecree && (
            <div className="invalid-feedback">{errors.natureOfDecree}</div>
          )}
        </div>
        {formData.decreeDetails.natureOfDecree === 'Other' && (
          <div className="form-group">
            <label>Please Specify Other Nature of Decree:</label>
            <input
              type="text"
              className="form-control"
              value={formData.decreeDetails.otherNature || ''}
              onChange={(e) =>
                handleInputChange('decreeDetails', 'otherNature', e.target.value)
              }
              placeholder="Enter other nature of decree"
            />
          </div>
        )}

        <div className="form-group">
          <label>Principal Amount:</label>
          <NumericFormat
            className={`form-control ${errors.principalAmount ? 'is-invalid' : ''}`}
            value={formData.decreeDetails.principalAmount || ''}
            onValueChange={(values) => {
              const { value } = values; // raw unformatted value
              handleInputChange('decreeDetails', 'principalAmount', value);
            }}
            thousandSeparator
            placeholder="Enter Principal Amount"
            isNumericString
          />
          {errors.principalAmount && (
            <div className="invalid-feedback">{errors.principalAmount}</div>
          )}
        </div>
        <div className="form-group">
          <label>Interest (if applicable):</label>
          <NumericFormat
            className="form-control"
            value={formData.decreeDetails.interestAmount || ''}
            onValueChange={(values) => {
              const { value } = values;
              handleInputChange('decreeDetails', 'interestAmount', value);
            }}
            thousandSeparator
            placeholder="Enter Interest Amount"
            isNumericString
          />
        </div>
        <div className="form-group">
          <label>Total Amount Due:</label>
          {/* This field is read-only, but stored in the DB. */}
          <NumericFormat
            className="form-control"
            value={formData.decreeDetails.totalAmountDue || '0'}
            thousandSeparator
            isNumericString
            disabled
          />
        </div>
        <div className="form-group">
          <label>Details of Outstanding Amount:</label>
          <textarea
            className={`form-control ${errors.detailsOfOutstanding ? 'is-invalid' : ''}`}
            value={formData.decreeDetails.detailsOfOutstanding || ''}
            onChange={(e) =>
              handleInputChange('decreeDetails', 'detailsOfOutstanding', e.target.value)
            }
            placeholder="Explain why the amount is still unpaid"
          ></textarea>
          {errors.detailsOfOutstanding && (
            <div className="invalid-feedback">{errors.detailsOfOutstanding}</div>
          )}
        </div>

        <div className="form-group mb-3">
          <label>Execution Sought:</label>
          <select
            className={`form-control ${errors.executionSought ? 'is-invalid' : ''}`}
            value={formData.decreeDetails.executionSought || ''}
            onChange={(e) =>
              handleInputChange('decreeDetails', 'executionSought', e.target.value)
            }
          >
            <option value="">-- Select --</option>
            <option value="Attachment of Property">Attachment of Property</option>
            <option value="Sale of Attached Property">Sale of Attached Property</option>
            <option value="Arrest and Detention of Debtor">Arrest and Detention of Debtor</option>
            <option value="Garnishment of Bank Account">Garnishment of Bank Account</option>
            <option value="Other">Other (Specify)</option>
          </select>
          {errors.executionSought && (
            <div className="invalid-feedback">{errors.executionSought}</div>
          )}
        </div>
        {formData.decreeDetails.executionSought === 'Other' && (
          <div className="form-group mb-3">
            <label>Please Specify Other Execution Sought:</label>
            <input
              type="text"
              className="form-control"
              value={formData.decreeDetails.otherExecutionSought || ''}
              onChange={(e) =>
                handleInputChange('decreeDetails', 'otherExecutionSought', e.target.value)
              }
              placeholder="Enter other execution method"
            />
          </div>
        )}

        {/* Section 3: Property/Asset Details (If Applicable) */}
        <h4 className="mt-4">Property/Asset Details (If Applicable)</h4>
        <div className="form-group">
          <label>Type of Collateral (if any):</label>
          <select
            className="form-control"
            value={formData.assetDetails.typeOfCollateral || ''}
            onChange={(e) =>
              handleInputChange('assetDetails', 'typeOfCollateral', e.target.value)
            }
          >
            <option value="">-- Select --</option>
            <option value="Immovable Property">Immovable Property</option>
            <option value="Movable Property">Movable Property</option>
            <option value="Bank Account">Bank Account</option>
            <option value="Other">Other (Specify)</option>
          </select>
        </div>
        {formData.assetDetails.typeOfCollateral === 'Other' && (
          <div className="form-group">
            <label>Please Specify Other Collateral Type:</label>
            <input
              type="text"
              className="form-control"
              value={formData.assetDetails.otherCollateral || ''}
              onChange={(e) =>
                handleInputChange('assetDetails', 'otherCollateral', e.target.value)
              }
              placeholder="Enter other collateral type"
            />
          </div>
        )}

        <div className="form-group">
          <label>Description of Collateral:</label>
          <textarea
            className="form-control"
            value={formData.assetDetails.description || ''}
            onChange={(e) => handleInputChange('assetDetails', 'description', e.target.value)}
            placeholder="For Immovable: address, area, docs. For Movable: registration #, etc."
          ></textarea>
        </div>
        <div className="form-group mb-3">
          <label>Current Status of Collateral:</label>
          <input
            type="text"
            className="form-control"
            value={formData.assetDetails.currentStatus || ''}
            onChange={(e) =>
              handleInputChange('assetDetails', 'currentStatus', e.target.value)
            }
            placeholder="Explain if property is in possession or needs repossession"
          />
        </div>

        {/* Section 4: Previous Notices/Actions */}
        <h4 className="mt-4">Previous Notices/Actions</h4>
        <div className="form-group">
          <label>Details of Notices Issued:</label>
          <textarea
            className="form-control"
            value={formData.previousActions.notices || ''}
            onChange={(e) =>
              handleInputChange('previousActions', 'notices', e.target.value)
            }
            placeholder="Legal Notice Issued on..., Reply Received (Yes/No), etc."
          ></textarea>
        </div>
        <div className="form-group mb-3">
          <label>Injunction or Interim Orders (if any):</label>
          <textarea
            className="form-control"
            value={formData.previousActions.injunctionOrders || ''}
            onChange={(e) =>
              handleInputChange('previousActions', 'injunctionOrders', e.target.value)
            }
            placeholder="Mention any stay or attachment orders passed during the suit"
          ></textarea>
        </div>

        {/* Section 5: Requested Court Actions */}
        <h4 className="mt-4">Requested Court Actions</h4>
        <div className="form-group">
          <label>Specific Relief Requested from the Court:</label>
          <textarea
            className="form-control"
            value={formData.requestedActions.specificRelief || ''}
            onChange={(e) =>
              handleInputChange('requestedActions', 'specificRelief', e.target.value)
            }
            placeholder="e.g., attachment/sale of property, garnishment, etc."
          ></textarea>
        </div>
        <div className="form-group mb-3">
          <label>Proposed Plan for Execution (if required):</label>
          <textarea
            className="form-control"
            value={formData.requestedActions.executionPlan || ''}
            onChange={(e) =>
              handleInputChange('requestedActions', 'executionPlan', e.target.value)
            }
            placeholder="Outline steps to execute the decree"
          ></textarea>
        </div>

        {/* Section 6: Attachments */}
        <h4 className="mt-4">Attachments</h4>
        <div className="form-group">
          <label>Mandatory Attachments:</label>
          <textarea
            className="form-control"
            value={formData.attachments.mandatory || ''}
            onChange={(e) => handleInputChange('attachments', 'mandatory', e.target.value)}
            placeholder="Certified copy of the decree, judgment, affidavit, etc."
          ></textarea>
        </div>
        <div className="form-group mb-3">
          <label>Optional Attachments (As Applicable):</label>
          <textarea
            className="form-control"
            value={formData.attachments.optional || ''}
            onChange={(e) => handleInputChange('attachments', 'optional', e.target.value)}
            placeholder="Title docs of collateral, previous notices, valuation reports, etc."
          ></textarea>
        </div>

        <button
          type="submit"
          className="btn mt-4"
          style={{
            backgroundColor: '#166432',
            color: '#C59D55',
            border: 'none',
          }}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = '#092415';
            e.target.style.color = '#fff';
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = '#166432';
            e.target.style.color = '#C59D55';
          }}
        >
          Next
        </button>
      </form>
    </div>
  );
}

export default ExecutionApplicationForm;

// import React, { useState, useEffect } from 'react';

// function SettlementAgreementForm({ formData, setFormData, onSubmit }) {
//     const [errors, setErrors] = useState({});

//     const handleInputChange = (section, field, value) => {
//         // Handle nested fields
//         if (field.includes('.')) {
//             const [nestedSection, nestedField] = field.split('.'); // e.g. 'witness1.name'
//             setFormData(prev => ({
//                 ...prev,
//                 [section]: {
//                     ...prev[section],
//                     [nestedSection]: {
//                         ...prev[section][nestedSection],
//                         [nestedField]: value
//                     }
//                 }
//             }));
//         } else {
//             // Handle non-nested fields
//             setFormData(prev => ({
//                 ...prev,
//                 [section]: { ...prev[section], [field]: value }
//             }));
//         }
//     };
    
//     const validateForm = () => {
//         let formErrors = {};

//         // Validate Party 1 information
//         if (!formData.party1.fullName) formErrors.party1FullName = "Full name or business name is required for Party 1";
//         if (!formData.party1.cnic) formErrors.party1Cnic = "CNIC or registration number is required for Party 1";
//         if (!formData.party1.address) formErrors.party1Address = "Address is required for Party 1";
//         if (!formData.party1.contact) formErrors.party1Contact = "Contact information is required for Party 1";

//         // Validate Party 2 information
//         if (!formData.party2.fullName) formErrors.party2FullName = "Full name or business name is required for Party 2";
//         if (!formData.party2.cnic) formErrors.party2Cnic = "CNIC or registration number is required for Party 2";
//         if (!formData.party2.address) formErrors.party2Address = "Address is required for Party 2";
//         if (!formData.party2.contact) formErrors.party2Contact = "Contact information is required for Party 2";

//         // Validate Agreement details
//         if (!formData.agreement.effectiveDate) formErrors.effectiveDate = "Effective date is required";
//         if (!formData.agreement.purpose) formErrors.purpose = "Settlement purpose is required";
//         if (!formData.agreement.jurisdiction) formErrors.jurisdiction = "Jurisdiction is required";

//         // Validate Financial terms
//         if (!formData.financial.totalAmountDue) formErrors.totalAmountDue = "Total amount due is required";
//         if (!formData.financial.currency) formErrors.currency = "Currency is required";
//         if (!formData.financial.paymentSchedule) formErrors.paymentSchedule = "Payment schedule is required";

//         setErrors(formErrors);
//         return Object.keys(formErrors).length === 0;
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         if (validateForm()) {
//             console.log("Form data to submit:", formData);
//             setFormData(formData);
//             onSubmit();
//         }
//     };

//     return (
//         <div className="mb-2">
//             <h2 className="text-center">Settlement Agreement Form</h2>
//             <form onSubmit={handleSubmit}>
//                 {/* Parties Involved */}
//                 <h4 className="mt-4">1. Parties Involved</h4>
//                 <div className="border rounded p-3 mb-3">
//                     <h5>Party 1 (Creditor/Settler)</h5>
//                     <div className="form-group">
//                         <label>Full Name or Business Name:</label>
//                         <input
//                             type="text"
//                             className={`form-control ${errors.party1FullName ? 'is-invalid' : ''}`}
//                             value={formData.party1.fullName}
//                             onChange={(e) => handleInputChange('party1', 'fullName', e.target.value)}
//                             placeholder="Enter full name or business name"
//                         />
//                         {errors.party1FullName && <div className="invalid-feedback">{errors.party1FullName}</div>}
//                     </div>
//                     <div className="form-group">
//                         <label>CNIC/Company Registration Number:</label>
//                         <input
//                             type="text"
//                             className={`form-control ${errors.party1Cnic ? 'is-invalid' : ''}`}
//                             value={formData.party1.cnic}
//                             onChange={(e) => handleInputChange('party1', 'cnic', e.target.value)}
//                             placeholder="Enter CNIC or registration number"
//                         />
//                         {errors.party1Cnic && <div className="invalid-feedback">{errors.party1Cnic}</div>}
//                     </div>
//                     <div className="form-group">
//                         <label>Address:</label>
//                         <input
//                             type="text"
//                             className={`form-control ${errors.party1Address ? 'is-invalid' : ''}`}
//                             value={formData.party1.address}
//                             onChange={(e) => handleInputChange('party1', 'address', e.target.value)}
//                             placeholder="Enter address"
//                         />
//                         {errors.party1Address && <div className="invalid-feedback">{errors.party1Address}</div>}
//                     </div>
//                     <div className="form-group">
//                         <label>Contact Information:</label>
//                         <input
//                             type="text"
//                             className={`form-control ${errors.party1Contact ? 'is-invalid' : ''}`}
//                             value={formData.party1.contact}
//                             onChange={(e) => handleInputChange('party1', 'contact', e.target.value)}
//                             placeholder="Enter contact information"
//                         />
//                         {errors.party1Contact && <div className="invalid-feedback">{errors.party1Contact}</div>}
//                     </div>
//                 </div>

//                 <div className="border rounded p-3 mb-3">
//                     <h5>Party 2 (Debtor/Settlee)</h5>
//                     <div className="form-group">
//                         <label>Full Name or Business Name:</label>
//                         <input
//                             type="text"
//                             className={`form-control ${errors.party2FullName ? 'is-invalid' : ''}`}
//                             value={formData.party2.fullName}
//                             onChange={(e) => handleInputChange('party2', 'fullName', e.target.value)}
//                             placeholder="Enter full name or business name"
//                         />
//                         {errors.party2FullName && <div className="invalid-feedback">{errors.party2FullName}</div>}
//                     </div>
//                     <div className="form-group">
//                         <label>CNIC/Company Registration Number:</label>
//                         <input
//                             type="text"
//                             className={`form-control ${errors.party2Cnic ? 'is-invalid' : ''}`}
//                             value={formData.party2.cnic}
//                             onChange={(e) => handleInputChange('party2', 'cnic', e.target.value)}
//                             placeholder="Enter CNIC or registration number"
//                         />
//                         {errors.party2Cnic && <div className="invalid-feedback">{errors.party2Cnic}</div>}
//                     </div>
//                     <div className="form-group">
//                         <label>Address:</label>
//                         <input
//                             type="text"
//                             className={`form-control ${errors.party2Address ? 'is-invalid' : ''}`}
//                             value={formData.party2.address}
//                             onChange={(e) => handleInputChange('party2', 'address', e.target.value)}
//                             placeholder="Enter address"
//                         />
//                         {errors.party2Address && <div className="invalid-feedback">{errors.party2Address}</div>}
//                     </div>
//                     <div className="form-group">
//                         <label>Contact Information:</label>
//                         <input
//                             type="text"
//                             className={`form-control ${errors.party2Contact ? 'is-invalid' : ''}`}
//                             value={formData.party2.contact}
//                             onChange={(e) => handleInputChange('party2', 'contact', e.target.value)}
//                             placeholder="Enter contact information"
//                         />
//                         {errors.party2Contact && <div className="invalid-feedback">{errors.party2Contact}</div>}
//                     </div>
//                 </div>

//                 {/* Agreement Details */}
//                 <h4 className="mt-4">2. Agreement Details</h4>
//                 <div className="form-group">
//                     <label>Effective Date:</label>
//                     <input
//                         type="date"
//                         className={`form-control ${errors.effectiveDate ? 'is-invalid' : ''}`}
//                         value={formData.agreement.effectiveDate}
//                         onChange={(e) => handleInputChange('agreement', 'effectiveDate', e.target.value)}
//                     />
//                     {errors.effectiveDate && <div className="invalid-feedback">{errors.effectiveDate}</div>}
//                 </div>
//                 <div className="form-group">
//                     <label>Settlement Purpose:</label>
//                     <textarea
//                         className={`form-control ${errors.purpose ? 'is-invalid' : ''}`}
//                         value={formData.agreement.purpose}
//                         onChange={(e) => handleInputChange('agreement', 'purpose', e.target.value)}
//                         placeholder="Describe the purpose of the settlement"
//                     ></textarea>
//                     {errors.purpose && <div className="invalid-feedback">{errors.purpose}</div>}
//                 </div>
//                 <div className="form-group">
//                     <label>Jurisdiction:</label>
//                     <input
//                         type="text"
//                         className={`form-control ${errors.jurisdiction ? 'is-invalid' : ''}`}
//                         value={formData.agreement.jurisdiction}
//                         onChange={(e) => handleInputChange('agreement', 'jurisdiction', e.target.value)}
//                         placeholder="Enter jurisdiction"
//                     />
//                     {errors.jurisdiction && <div className="invalid-feedback">{errors.jurisdiction}</div>}
//                 </div>

//                 {/* Financial Settlement Terms */}
//                 <h4 className="mt-4">3. Financial Settlement Terms</h4>
//                 <div className="form-group">
//                     <label>Total Amount Due:</label>
//                     <input
//                         type="text"
//                         className={`form-control ${errors.totalAmountDue ? 'is-invalid' : ''}`}
//                         value={formData.financial.totalAmountDue}
//                         onChange={(e) => handleInputChange('financial', 'totalAmountDue', e.target.value)}
//                         placeholder="Enter total amount due"
//                     />
//                     {errors.totalAmountDue && <div className="invalid-feedback">{errors.totalAmountDue}</div>}
//                 </div>
//                 <div className="form-group">
//                     <label>Currency:</label>
//                     <input
//                         type="text"
//                         className={`form-control ${errors.currency ? 'is-invalid' : ''}`}
//                         value={formData.financial.currency}
//                         onChange={(e) => handleInputChange('financial', 'currency', e.target.value)}
//                         placeholder="Enter currency (e.g., PKR, USD)"
//                     />
//                     {errors.currency && <div className="invalid-feedback">{errors.currency}</div>}
//                 </div>
//                 <div className="form-group">
//                     <label>Payment Schedule:</label>
//                     <textarea
//                         className={`form-control ${errors.paymentSchedule ? 'is-invalid' : ''}`}
//                         value={formData.financial.paymentSchedule}
//                         onChange={(e) => handleInputChange('financial', 'paymentSchedule', e.target.value)}
//                         placeholder="Enter payment schedule"
//                     ></textarea>
//                     {errors.paymentSchedule && <div className="invalid-feedback">{errors.paymentSchedule}</div>}
//                 </div>

//                 {/* Ownership and Asset Transfer */}
//                 <h4 className="mt-4">4. Ownership and Asset Transfer (if applicable)</h4>
//                 <div className="form-group">
//                     <label>Items to Be Transferred:</label>
//                     <textarea
//                         className="form-control"
//                         value={formData.ownershipTransfer.itemsToTransfer}
//                         onChange={(e) => handleInputChange('ownershipTransfer', 'itemsToTransfer', e.target.value)}
//                         placeholder="Describe items to be transferred"
//                     ></textarea>
//                 </div>
//                 <div className="form-group">
//                     <label>Transfer of Ownership Date:</label>
//                     <input
//                         type="date"
//                         className="form-control"
//                         value={formData.ownershipTransfer.transferDate}
//                         onChange={(e) => handleInputChange('ownershipTransfer', 'transferDate', e.target.value)}
//                     />
//                 </div>
//                 <div className="form-group">
//                     <label>Conditions for Transfer:</label>
//                     <textarea
//                         className="form-control"
//                         value={formData.ownershipTransfer.transferConditions}
//                         onChange={(e) => handleInputChange('ownershipTransfer', 'transferConditions', e.target.value)}
//                         placeholder="Enter conditions for transfer"
//                     ></textarea>
//                 </div>

//                 {/* Dispute Resolution */}
//                 <h4 className="mt-4">5. Breach of Agreement and Dispute Resolution</h4>
//                 <div className="form-group">
//                     <label>Dispute Resolution Method:</label>
//                     <input
//                         type="text"
//                         className={`form-control ${errors.disputeResolutionMethod ? 'is-invalid' : ''}`}
//                         value={formData.disputeResolution.method}
//                         onChange={(e) => handleInputChange('disputeResolution', 'method', e.target.value)}
//                         placeholder="Enter dispute resolution method"
//                     />
//                     {errors.disputeResolutionMethod && <div className="invalid-feedback">{errors.disputeResolutionMethod}</div>}
//                 </div>
//                 <div className="form-group">
//                     <label>Arbitration Clause:</label>
//                     <textarea
//                         className={`form-control ${errors.arbitrationClause ? 'is-invalid' : ''}`}
//                         value={formData.disputeResolution.arbitrationClause}
//                         onChange={(e) => handleInputChange('disputeResolution', 'arbitrationClause', e.target.value)}
//                         placeholder="Enter arbitration clause"
//                     ></textarea>
//                     {errors.arbitrationClause && <div className="invalid-feedback">{errors.arbitrationClause}</div>}
//                 </div>

//                 {/* Confidentiality and Other Clauses */}
//                 <h4 className="mt-4">6. Confidentiality and Other Clauses</h4>
//                 <div className="form-group">
//                     <label>Confidentiality Clause:</label>
//                     <textarea
//                         className="form-control"
//                         value={formData.confidentiality.confidentialityClause}
//                         onChange={(e) => handleInputChange('confidentiality', 'confidentialityClause', e.target.value)}
//                         placeholder="Enter confidentiality clause"
//                     ></textarea>
//                 </div>
//                 <div className="form-group">
//                     <label>Force Majeure:</label>
//                     <textarea
//                         className="form-control"
//                         value={formData.confidentiality.forceMajeure}
//                         onChange={(e) => handleInputChange('confidentiality', 'forceMajeure', e.target.value)}
//                         placeholder="Enter force majeure clause"
//                     ></textarea>
//                 </div>
//                 <div className="form-group">
//                     <label>Governing Law:</label>
//                     <input
//                         type="text"
//                         className="form-control"
//                         value={formData.confidentiality.governingLaw}
//                         onChange={(e) => handleInputChange('confidentiality', 'governingLaw', e.target.value)}
//                         placeholder="Enter governing law"
//                     />
//                 </div>

//                 {/* Signatures */}
//                 <h4 className="mt-4">7. Witnesses</h4>
//                 <div className="form-group">
//                     <label>Witness 1 Name:</label>
//                     <input
//                         type="text"
//                         className="form-control"
//                         value={formData.signatures.witness1.name}
//                         onChange={(e) => handleInputChange('signatures', 'witness1.name', e.target.value)}
//                         placeholder="Witness 1 name"
//                     />
//                 </div>
//                 <div className="form-group">
//                     <label>Witness 1 Address:</label>
//                     <input
//                         type="text"
//                         className="form-control"
//                         value={formData.signatures.witness1.address}
//                         onChange={(e) => handleInputChange('signatures', 'witness1.address', e.target.value)}
//                         placeholder="Witness 1 address"
//                     />
//                 </div>
//                 <div className="form-group">
//                     <label>Witness 2 Name:</label>
//                     <input
//                         type="text"
//                         className="form-control"
//                         value={formData.signatures.witness2.name}
//                         onChange={(e) => handleInputChange('signatures', 'witness2.name', e.target.value)}
//                         placeholder="Witness 2 name"
//                     />
//                 </div>
//                 <div className="form-group">
//                     <label>Witness 2 Address:</label>
//                     <input
//                         type="text"
//                         className="form-control"
//                         value={formData.signatures.witness2.address}
//                         onChange={(e) => handleInputChange('signatures', 'witness2.address', e.target.value)}
//                         placeholder="Witness 2 address"
//                     />
//                 </div>

//                 {/* Submit */}
//                 {/* <button type="submit" className="btn btn-primary mt-4">Next</button> */}
//                 <button
//                     type="submit"
//                     className="btn mt-4"
//                     style={{
//                         backgroundColor: '#166432',
//                         color: '#C59D55',
//                         border: 'none',
//                     }}
//                     onMouseEnter={(e) => {
//                         e.target.style.backgroundColor = '#092415';
//                         e.target.style.color = '#fff';
//                     }}
//                     onMouseLeave={(e) => {
//                         e.target.style.backgroundColor = '#166432';
//                         e.target.style.color = '#C59D55';
//                     }}
//                 >
//                     Next
//                 </button>
//             </form>
//         </div>
//     );
// }

// export default SettlementAgreementForm;

import React, { useState, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';

function SettlementAgreementForm({ formData, setFormData, onSubmit }) {
  const [errors, setErrors] = useState({});

  // Helper to update nested or root-level state
  const handleInputChange = (section, field, value) => {
    if (section) {
      setFormData((prev) => ({
        ...prev,
        [section]: {
          ...prev[section],
          [field]: value,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  // Auto-calculate totalOutstanding
  useEffect(() => {
    const originalLoan = parseFloat(formData.loanDetails.originalLoanAmount || 0);
    const outstandingPrincipal = parseFloat(formData.loanDetails.outstandingPrincipal || 0);
    const markUp = parseFloat(formData.loanDetails.markUp || 0);
    const penalCharges = parseFloat(formData.loanDetails.penalCharges || 0);

    // For demonstration, let's define total as outstandingPrincipal + markUp + penalCharges
    // Adjust logic if needed (e.g., if "outstandingPrincipal" already includes markup, etc.)
    const totalOutstanding = outstandingPrincipal + markUp + penalCharges;
    handleInputChange('loanDetails', 'totalOutstanding', totalOutstanding.toString());
    // eslint-disable-next-line
  }, [
    formData.loanDetails.outstandingPrincipal,
    formData.loanDetails.markUp,
    formData.loanDetails.penalCharges
  ]);

  // Auto-calculate finalAgreedSettlement
  useEffect(() => {
    const totalSettlement = parseFloat(formData.settlementTerms.totalSettlementAmount || 0);
    const discountWaiver = parseFloat(formData.settlementTerms.agreedDiscountWaiver || 0);
    const finalSettlement = totalSettlement - discountWaiver;

    handleInputChange('settlementTerms', 'finalAgreedSettlement', finalSettlement.toString());
    // eslint-disable-next-line
  }, [
    formData.settlementTerms.totalSettlementAmount,
    formData.settlementTerms.agreedDiscountWaiver
  ]);

  // Basic validation
  const validateForm = () => {
    const formErrors = {};

    // Section 1: Basic Details
    if (!formData.basicDetails.agreementTitle) {
      formErrors.agreementTitle = 'Agreement Title is required.';
    }
    if (!formData.basicDetails.dateOfAgreement) {
      formErrors.dateOfAgreement = 'Date of Agreement is required.';
    }

    // Creditor (Bank) Info
    if (!formData.basicDetails.bankName) {
      formErrors.bankName = 'Bank/Finance Company Name is required.';
    }
    if (!formData.basicDetails.debtorName) {
      formErrors.debtorName = 'Debtor Name is required.';
    }

    // Section 2: Loan or Financial Obligation Details
    if (!formData.loanDetails.loanAccountNumber) {
      formErrors.loanAccountNumber = 'Loan Account Number is required.';
    }
    if (!formData.loanDetails.outstandingPrincipal) {
      formErrors.outstandingPrincipal = 'Outstanding Principal Amount is required.';
    }

    // Section 3: Settlement Terms
    if (!formData.settlementTerms.totalSettlementAmount) {
      formErrors.totalSettlementAmount = 'Total Settlement Amount is required.';
    }
    if (!formData.settlementTerms.settlementMethod) {
      formErrors.settlementMethod = 'Settlement Method is required.';
    }
    // If Lump Sum, check dueDateForPayment
    if (formData.settlementTerms.settlementMethod === 'Lump-Sum Payment' && !formData.settlementTerms.dueDateForPayment) {
      formErrors.dueDateForPayment = 'Due Date for Lump-Sum Payment is required.';
    }
    // If Installment Plan, check # of installments, amount per, etc.
    if (formData.settlementTerms.settlementMethod === 'Installment Plan') {
      if (!formData.settlementTerms.numberOfInstallments) {
        formErrors.numberOfInstallments = 'Number of Installments is required.';
      }
      if (!formData.settlementTerms.amountPerInstallment) {
        formErrors.amountPerInstallment = 'Amount per Installment is required.';
      }
      if (!formData.settlementTerms.paymentSchedule) {
        formErrors.paymentSchedule = 'Payment Schedule is required.';
      }
      if (!formData.settlementTerms.startDateOfInstallments) {
        formErrors.startDateOfInstallments = 'Start Date of Installments is required.';
      }
    }

    return formErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      onSubmit();
    }
  };

  return (
    <div>
      <h2 className="text-center">Settlement Agreement</h2>
      <form onSubmit={handleSubmit}>
        {/* Section 1: Basic Details */}
        <h4 className="mt-4">Basic Details</h4>
        <div className="form-group">
          <label>Agreement Title:</label>
          <input
            type="text"
            className={`form-control ${errors.agreementTitle ? 'is-invalid' : ''}`}
            value={formData.basicDetails.agreementTitle || ''}
            onChange={(e) => handleInputChange('basicDetails', 'agreementTitle', e.target.value)}
            placeholder="E.g., Settlement Agreement Between Bank and Borrower"
          />
          {errors.agreementTitle && <div className="invalid-feedback">{errors.agreementTitle}</div>}
        </div>
        <div className="form-group">
          <label>Date of Agreement:</label>
          <input
            type="date"
            className={`form-control ${errors.dateOfAgreement ? 'is-invalid' : ''}`}
            value={formData.basicDetails.dateOfAgreement || ''}
            onChange={(e) => handleInputChange('basicDetails', 'dateOfAgreement', e.target.value)}
          />
          {errors.dateOfAgreement && <div className="invalid-feedback">{errors.dateOfAgreement}</div>}
        </div>

        <h5 className="mt-3">Creditor (Bank/Finance Company) Information</h5>
        <div className="form-group">
          <label>Bank/Finance Company Name:</label>
          <input
            type="text"
            className={`form-control ${errors.bankName ? 'is-invalid' : ''}`}
            value={formData.basicDetails.bankName || ''}
            onChange={(e) => handleInputChange('basicDetails', 'bankName', e.target.value)}
            placeholder="Enter Creditor's Name"
          />
          {errors.bankName && <div className="invalid-feedback">{errors.bankName}</div>}
        </div>
        <div className="form-group">
          <label>Branch Name:</label>
          <input
            type="text"
            className="form-control"
            value={formData.basicDetails.branchName || ''}
            onChange={(e) => handleInputChange('basicDetails', 'branchName', e.target.value)}
            placeholder="Enter Branch Name"
          />
        </div>
        <div className="form-group">
          <label>Address:</label>
          <input
            type="text"
            className="form-control"
            value={formData.basicDetails.bankAddress || ''}
            onChange={(e) => handleInputChange('basicDetails', 'bankAddress', e.target.value)}
            placeholder="Enter Address"
          />
        </div>
        <div className="form-group">
          <label>Authorized Representative Name:</label>
          <input
            type="text"
            className="form-control"
            value={formData.basicDetails.authorizedRepName || ''}
            onChange={(e) => handleInputChange('basicDetails', 'authorizedRepName', e.target.value)}
            placeholder="Enter Authorized Representative Name"
          />
        </div>
        <div className="form-group">
          <label>Designation:</label>
          <input
            type="text"
            className="form-control"
            value={formData.basicDetails.designation || ''}
            onChange={(e) => handleInputChange('basicDetails', 'designation', e.target.value)}
            placeholder="Enter Designation"
          />
        </div>
        <div className="form-group mb-3">
          <label>Contact Information (Phone/Email):</label>
          <input
            type="text"
            className="form-control"
            value={formData.basicDetails.bankContactInfo || ''}
            onChange={(e) => handleInputChange('basicDetails', 'bankContactInfo', e.target.value)}
            placeholder="Enter Contact Information (Phone/Email)"
          />
        </div>

        <h5 className="mt-3">Debtor (Borrower) Information</h5>
        <div className="form-group">
          <label>Debtor’s Name:</label>
          <input
            type="text"
            className={`form-control ${errors.debtorName ? 'is-invalid' : ''}`}
            value={formData.basicDetails.debtorName || ''}
            onChange={(e) => handleInputChange('basicDetails', 'debtorName', e.target.value)}
            placeholder="Enter Debtor’s Name"
          />
          {errors.debtorName && <div className="invalid-feedback">{errors.debtorName}</div>}
        </div>
        <div className="form-group">
          <label>Company Name:</label>
          <input
            type="text"
            className="form-control"
            value={formData.basicDetails.debtorCompanyName || ''}
            onChange={(e) => handleInputChange('basicDetails', 'debtorCompanyName', e.target.value)}
            placeholder="Enter Company Name"
          />
        </div>
        <div className="form-group">
          <label>CNIC/ID Number:</label>
          <input
            type="text"
            className="form-control"
            value={formData.basicDetails.debtorCnic || ''}
            onChange={(e) => handleInputChange('basicDetails', 'debtorCnic', e.target.value)}
            placeholder="Enter CNIC/ID Number"
          />
        </div>
        <div className="form-group">
          <label>Address:</label>
          <input
            type="text"
            className="form-control"
            value={formData.basicDetails.debtorAddress || ''}
            onChange={(e) => handleInputChange('basicDetails', 'debtorAddress', e.target.value)}
            placeholder="Enter Address"
          />
        </div>
        <div className="form-group mb-3">
          <label>Contact Information (Phone/Email):</label>
          <input
            type="text"
            className="form-control"
            value={formData.basicDetails.debtorContactInfo || ''}
            onChange={(e) => handleInputChange('basicDetails', 'debtorContactInfo', e.target.value)}
            placeholder="Enter Contact Information (Phone/Email)"
          />
        </div>

        {/* Section 2: Loan/Financial Obligation Details */}
        <h4 className="mt-4">Loan or Financial Obligation Details</h4>
        <h5>Loan Details</h5>
        <div className="form-group">
          <label>Loan Account Number:</label>
          <input
            type="text"
            className={`form-control ${errors.loanAccountNumber ? 'is-invalid' : ''}`}
            value={formData.loanDetails.loanAccountNumber || ''}
            onChange={(e) => handleInputChange('loanDetails', 'loanAccountNumber', e.target.value)}
            placeholder="Enter Loan Account Number"
          />
          {errors.loanAccountNumber && (
            <div className="invalid-feedback">{errors.loanAccountNumber}</div>
          )}
        </div>
        <div className="form-group">
          <label>Loan Agreement Date:</label>
          <input
            type="date"
            className="form-control"
            value={formData.loanDetails.loanAgreementDate || ''}
            onChange={(e) =>
              handleInputChange('loanDetails', 'loanAgreementDate', e.target.value)
            }
          />
        </div>
        <div className="form-group">
          <label>Original Loan Amount:</label>
          <NumericFormat
            className="form-control"
            value={formData.loanDetails.originalLoanAmount || ''}
            onValueChange={(values) => {
              const { value } = values;
              handleInputChange('loanDetails', 'originalLoanAmount', value);
            }}
            thousandSeparator
            placeholder="Enter Original Loan Amount"
            isNumericString
          />
        </div>
        <div className="form-group">
          <label>Outstanding Principal Amount:</label>
          <NumericFormat
            className={`form-control ${errors.outstandingPrincipal ? 'is-invalid' : ''}`}
            value={formData.loanDetails.outstandingPrincipal || ''}
            onValueChange={(values) => {
              const { value } = values;
              handleInputChange('loanDetails', 'outstandingPrincipal', value);
            }}
            thousandSeparator
            placeholder="Enter Outstanding Principal"
            isNumericString
          />
          {errors.outstandingPrincipal && (
            <div className="invalid-feedback">{errors.outstandingPrincipal}</div>
          )}
        </div>
        <div className="form-group">
          <label>Mark-up (Interest):</label>
          <NumericFormat
            className="form-control"
            value={formData.loanDetails.markUp || ''}
            onValueChange={(values) => {
              const { value } = values;
              handleInputChange('loanDetails', 'markUp', value);
            }}
            thousandSeparator
            placeholder="Enter Interest/Mark-up"
            isNumericString
          />
        </div>
        <div className="form-group">
          <label>Penal Charges (if any):</label>
          <NumericFormat
            className="form-control"
            value={formData.loanDetails.penalCharges || ''}
            onValueChange={(values) => {
              const { value } = values;
              handleInputChange('loanDetails', 'penalCharges', value);
            }}
            thousandSeparator
            placeholder="Enter Penal Charges"
            isNumericString
          />
        </div>
        <div className="form-group mb-3">
          <label>Total Outstanding Amount (Auto-calculated):</label>
          <NumericFormat
            className="form-control"
            value={formData.loanDetails.totalOutstanding || '0'}
            thousandSeparator
            isNumericString
            disabled
          />
        </div>

        <h5>Payment History (if applicable)</h5>
        <div className="form-group">
          <label>Amount Paid to Date:</label>
          <NumericFormat
            className="form-control"
            value={formData.loanDetails.amountPaidToDate || ''}
            onValueChange={(values) => {
              const { value } = values;
              handleInputChange('loanDetails', 'amountPaidToDate', value);
            }}
            thousandSeparator
            placeholder="Enter Amount Paid to Date"
            isNumericString
          />
        </div>
        <div className="form-group mb-3">
          <label>Number of Missed Payments:</label>
          <NumericFormat
            className="form-control"
            value={formData.loanDetails.numMissedPayments || ''}
            onValueChange={(values) => {
              const { value } = values;
              handleInputChange('loanDetails', 'numMissedPayments', value);
            }}
            thousandSeparator
            placeholder="Enter number of missed payments"
            isNumericString
          />
        </div>

        {/* Section 3: Settlement Terms */}
        <h4 className="mt-4">Settlement Terms</h4>
        <div className="form-group">
          <label>Total Settlement Amount:</label>
          <NumericFormat
            className={`form-control ${errors.totalSettlementAmount ? 'is-invalid' : ''}`}
            value={formData.settlementTerms.totalSettlementAmount || ''}
            onValueChange={(values) => {
              const { value } = values;
              handleInputChange('settlementTerms', 'totalSettlementAmount', value);
            }}
            thousandSeparator
            placeholder="Enter total settlement amount"
            isNumericString
          />
          {errors.totalSettlementAmount && (
            <div className="invalid-feedback">{errors.totalSettlementAmount}</div>
          )}
        </div>
        <div className="form-group">
          <label>Agreed Discount/Waiver (if any):</label>
          <NumericFormat
            className="form-control"
            value={formData.settlementTerms.agreedDiscountWaiver || ''}
            onValueChange={(values) => {
              const { value } = values;
              handleInputChange('settlementTerms', 'agreedDiscountWaiver', value);
            }}
            thousandSeparator
            placeholder="Enter discount or waiver"
            isNumericString
          />
        </div>
        <div className="form-group mb-3">
          <label>Final Agreed Settlement Amount (Auto-calculated):</label>
          <NumericFormat
            className="form-control"
            value={formData.settlementTerms.finalAgreedSettlement || '0'}
            thousandSeparator
            isNumericString
            disabled
          />
        </div>

        <div className="form-group">
          <label>Settlement Method:</label>
          <select
            className={`form-control ${errors.settlementMethod ? 'is-invalid' : ''}`}
            value={formData.settlementTerms.settlementMethod || ''}
            onChange={(e) => handleInputChange('settlementTerms', 'settlementMethod', e.target.value)}
          >
            <option value="">-- Select --</option>
            <option value="Lump-Sum Payment">Lump-Sum Payment</option>
            <option value="Installment Plan">Installment Plan</option>
          </select>
          {errors.settlementMethod && (
            <div className="invalid-feedback">{errors.settlementMethod}</div>
          )}
        </div>

        {/* If Lump-Sum Payment */}
        {formData.settlementTerms.settlementMethod === 'Lump-Sum Payment' && (
          <div className="form-group mb-3">
            <label>Due Date for Payment:</label>
            <input
              type="date"
              className={`form-control ${errors.dueDateForPayment ? 'is-invalid' : ''}`}
              value={formData.settlementTerms.dueDateForPayment || ''}
              onChange={(e) =>
                handleInputChange('settlementTerms', 'dueDateForPayment', e.target.value)
              }
            />
            {errors.dueDateForPayment && (
              <div className="invalid-feedback">{errors.dueDateForPayment}</div>
            )}
          </div>
        )}

        {/* If Installment Plan */}
        {formData.settlementTerms.settlementMethod === 'Installment Plan' && (
          <>
            <div className="form-group">
              <label>Number of Installments:</label>
              <NumericFormat
                className={`form-control ${errors.numberOfInstallments ? 'is-invalid' : ''}`}
                value={formData.settlementTerms.numberOfInstallments || ''}
                onValueChange={(values) => {
                  const { value } = values;
                  handleInputChange('settlementTerms', 'numberOfInstallments', value);
                }}
                thousandSeparator
                placeholder="Enter Number of Installments"
                isNumericString
              />
              {errors.numberOfInstallments && (
                <div className="invalid-feedback">{errors.numberOfInstallments}</div>
              )}
            </div>
            <div className="form-group">
              <label>Amount per Installment:</label>
              <NumericFormat
                className={`form-control ${errors.amountPerInstallment ? 'is-invalid' : ''}`}
                value={formData.settlementTerms.amountPerInstallment || ''}
                onValueChange={(values) => {
                  const { value } = values;
                  handleInputChange('settlementTerms', 'amountPerInstallment', value);
                }}
                thousandSeparator
                placeholder="Enter Amount per Installment"
                isNumericString
              />
              {errors.amountPerInstallment && (
                <div className="invalid-feedback">{errors.amountPerInstallment}</div>
              )}
            </div>
            <div className="form-group">
              <label>Payment Schedule:</label>
              <select
                className={`form-control ${errors.paymentSchedule ? 'is-invalid' : ''}`}
                value={formData.settlementTerms.paymentSchedule || ''}
                onChange={(e) =>
                  handleInputChange('settlementTerms', 'paymentSchedule', e.target.value)
                }
              >
                <option value="">-- Select --</option>
                <option value="Monthly">Monthly</option>
                <option value="Quarterly">Quarterly</option>
                <option value="Bi-Annual">Bi-Annual</option>
                <option value="Annual">Annual</option>
              </select>
              {errors.paymentSchedule && (
                <div className="invalid-feedback">{errors.paymentSchedule}</div>
              )}
            </div>
            <div className="form-group mb-3">
              <label>Start Date of Installments:</label>
              <input
                type="date"
                className={`form-control ${errors.startDateOfInstallments ? 'is-invalid' : ''}`}
                value={formData.settlementTerms.startDateOfInstallments || ''}
                onChange={(e) =>
                  handleInputChange('settlementTerms', 'startDateOfInstallments', e.target.value)
                }
              />
              {errors.startDateOfInstallments && (
                <div className="invalid-feedback">{errors.startDateOfInstallments}</div>
              )}
            </div>
          </>
        )}

        {/* Section 4: Additional Clauses */}
        <h4 className="mt-4">Additional Clauses</h4>
        <div className="form-group">
          <label>Default Clause (Action in Case of Default):</label>
          <textarea
            className="form-control"
            value={formData.additionalClauses.defaultClause || ''}
            onChange={(e) =>
              handleInputChange('additionalClauses', 'defaultClause', e.target.value)
            }
            placeholder="Describe actions upon default"
          ></textarea>
        </div>
        <div className="form-group">
          <label>Release Clause (Description of Discharge):</label>
          <textarea
            className="form-control"
            value={formData.additionalClauses.releaseClause || ''}
            onChange={(e) =>
              handleInputChange('additionalClauses', 'releaseClause', e.target.value)
            }
            placeholder="E.g., claims are resolved upon full settlement"
          ></textarea>
        </div>
        <div className="form-group mb-3">
          <label>Confidentiality Clause (Specify if agreement is confidential):</label>
          <textarea
            className="form-control"
            value={formData.additionalClauses.confidentialityClause || ''}
            onChange={(e) =>
              handleInputChange('additionalClauses', 'confidentialityClause', e.target.value)
            }
            placeholder="State if the agreement is confidential"
          ></textarea>
        </div>

        {/* Section 5: Attachments */}
        <h4 className="mt-4">Attachments</h4>
        <h5>Mandatory Attachments</h5>
        <div className="form-group mb-3">
          <textarea
            className="form-control"
            value={formData.attachments.mandatory || ''}
            onChange={(e) => handleInputChange('attachments', 'mandatory', e.target.value)}
            placeholder={
              'Copy of Loan Agreement, Outstanding Balance Statement, Mediation Documents, etc.'
            }
          ></textarea>
        </div>
        <h5>Optional Attachments</h5>
        <div className="form-group mb-3">
          <textarea
            className="form-control"
            value={formData.attachments.optional || ''}
            onChange={(e) => handleInputChange('attachments', 'optional', e.target.value)}
            placeholder="Title Documents, Bank's Internal Policies, etc."
          ></textarea>
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="btn mt-4"
          style={{
            backgroundColor: '#166432',
            color: '#C59D55',
            border: 'none',
          }}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = '#092415';
            e.target.style.color = '#fff';
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = '#166432';
            e.target.style.color = '#C59D55';
          }}
        >
          Next
        </button>
      </form>
    </div>
  );
}

export default SettlementAgreementForm;



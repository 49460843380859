import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import SimpleMDE from "react-simplemde-editor";
import { saveAs } from "file-saver";
import HtmlDocx from "html-docx-js/dist/html-docx";
import styles from './LegalDrafts.module.css'; // Adjust the path if necessary

const DraftListing = ({ draftMasterId, setDraftMasterId, setRecoverySuitData, setSettlementNoticeData }) => {
    const [drafts, setDrafts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [draftContent, setDraftContent] = useState('');
    const [currentDraftId, setCurrentDraftId] = useState(null);
    const [modalTitle, setModalTitle] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const contentRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const commonUrl = process.env.REACT_APP_COMMON_URL;

    // Extract draft_type_id from query parameters
    const queryParams = new URLSearchParams(location.search);
    const draftTypeId = queryParams.get('draft_type_id');

    useEffect(() => {
        const fetchDrafts = async () => {
            try {
                const token = localStorage.getItem('access_token');
                const response = await axios.get(`${commonUrl}/api/drafts`, {
                    params: { draft_type_id: draftTypeId },
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setDrafts(response.data);
            } catch (error) {
                console.error('Error fetching drafts:', error);
                if (error.response && error.response.status === 401) {
                    // Handle unauthorized access (e.g., redirect to login)
                    navigate('/', { state: { message: 'Please log in to continue.' } });
                }
            } finally {
                setLoading(false);
            }
        };

        fetchDrafts();
    }, [navigate, commonUrl, draftTypeId]);

    const handleDraftTitleClick = async (draft_id, draftTitle, source) => {
        try {
            const token = localStorage.getItem('access_token');
            let response;

            if (source === 'draft_master') {
                response = await axios.get(`${commonUrl}/api/getLegalNoticeOrRecoverySuitDetail`, {
                    params: { draft_master_id: draft_id },
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            } else if (source === 'legal_drafts') {
                response = await axios.get(`${commonUrl}/api/getLegalDraftDetail`, {
                    params: { legal_draft_id: draft_id },
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            } else {
                console.error('Unknown source:', source);
                return;
            }

            console.log(response)

            setDraftContent(response.data['generated_draft']);
            setModalTitle(draftTitle);
            console.log(draft_id)
            setCurrentDraftId(draft_id);
            setShowModal(true);
        } catch (error) {
            console.error('Error fetching draft details:', error);
        }
    };

    // Implement the handleRecoverySuitClick function
    const handleRecoverySuitClick = async (draft_master_id) => {
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.get(`${commonUrl}/api/getLegalNoticeOrRecoverySuitDetail`, {
                params: { draft_master_id },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const legalNoticeData = response.data;
            console.log(legalNoticeData.penal_charges)

            const mappedRecoverySuitData = {
                basicInfo: {
                    courtName: "", // User will fill this
                    filingDate: "", // User will fill this
                },
                plaintiffInfo: {
                    name: legalNoticeData.creditor_bank_name || "",
                    position: "", // If applicable
                    address: legalNoticeData.creditor_office_address || "",
                    legalRepresentative: "", // If applicable
                    companyNominationAttorney: "", // If applicable
                },
                defendantInfo: {
                    name: legalNoticeData.debtor_company_name || "",
                    businessInformation: "", // If applicable
                    address: legalNoticeData.debtor_address || "",
                    legalRepresentative: "",
                },
                debtDetails: {
                    natureOfLoan: "", // Map as appropriate
                    assetOfferedAsCollateral: "", // Map as appropriate
                    outstandingAmount: legalNoticeData.aggregated_amount || "",
                    defaultSince: legalNoticeData.overdue_date || "",
                },
                causeOfAction: {
                    contractualObligation: "", // User will fill this
                    reasonForDefault: "", // User will fill this
                    paymentFollowUp: "", // User will fill this
                },
                supportingDocuments: {
                    contracts: "", // User will fill this
                    invoices: "", // User will fill this
                    chequeDetails: "", // User will fill this
                    collateralAssetDetail: "", // User will fill this
                    anyOtherInfo: "", // User will fill this
                },
                legalNotices: {
                    noticeDate: legalNoticeData.notice_date || "",
                    defendantResponse: "", // User will fill this
                },
                reliefSought: {
                    totalPrincipalAmount: legalNoticeData.total_principal || "",
                    interestCalculation: legalNoticeData.total_markup_due || "", // User will fill this
                    penalCharges: legalNoticeData.penal_charges || "",
                    legalCosts: "", // User will fill this
                    penalties: "",
                },
                jurisdiction: {
                    pecuniaryJurisdiction: "", // User will fill this
                    territorialJurisdiction: "", // User will fill this
                },
                lawyerDetails: {
                    advocateName: "", // User will fill this
                    licenseNumber: "", // User will fill this
                    lawFirmName: "", // User will fill this
                    contactInformation: "", // User will fill this
                },
                prayer: {
                    prayerForDecree: "", // User will fill this
                    additionalRelief: "", // User will fill this
                },
            };

            // Update the recoverySuitData state in App.js
            setRecoverySuitData(mappedRecoverySuitData);

            setDraftMasterId(draft_master_id);
            navigate('/dashboard/form?draft_type_id=4');
        } catch (error) {
            console.error('Error fetching legal notice:', error);
        }
    };
    const handleSettlementNoticeClick = async (draft_master_id) => {
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.get(`${commonUrl}/api/getLegalNoticeOrRecoverySuitDetail`, {
                params: { draft_master_id },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const legalNoticeData = response.data;

            const mappedSettlementNoticeData = {
                debtorInfo: {
                    companyName: legalNoticeData.debtor_company_name || "",
                    chiefExecName: "",
                    address: legalNoticeData.debtor_address || "",
                },
                bankInfo: {
                    bankName: legalNoticeData.creditor_bank_name || "",
                    headOfficeAddress: legalNoticeData.creditor_office_address || "",
                },
                outstandingAmountDetails: {
                    totalPrincipal: legalNoticeData.total_principal || "",
                    totalMarkUp: legalNoticeData.total_markup_due || "",
                    penalCharges: legalNoticeData.penal_charges || "",
                    amountPaid: legalNoticeData.amount_paid || "",
                    aggregatedAmount: legalNoticeData.aggregated_amount || "",
                },
                datesDeadlines: {
                    overdueDate: legalNoticeData.overdue_date || "",
                    noticeIssuanceDate: ""
                },
                noticeDetails: {
                    purposeOfNotice: "",
                    typeOfOffer: "",
                    otherOfferType: "",
                    proposedAmount: "",
                    proposedSchedule: "",
                    reasonForProposal: "",
                    responseRequiredBy: ""
                },
                supportingDocuments: {
                    list: ""
                },
                legalReferences: {
                    citedProvisions: ""
                }
            };

            // // Update the recoverySuitData state in App.js
            setSettlementNoticeData(mappedSettlementNoticeData);

            // setDraftMasterId(draft_master_id);
            navigate('/dashboard/form?draft_type_id=18');
        } catch (error) {
            console.error('Error fetching legal notice:', error);
        }
    };

    const updateDraftContent = async (draftTypeId ,draftId, content) => {
        try {
            const token = localStorage.getItem('access_token');
            await axios.put(`${commonUrl}/api/update-draft/`, {
                draft: content,
                inserted_draft_id: draftId,
                draft_type_id: draftTypeId
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // setContent(editedContent); // Update the content with the edited content
            setIsEditing(false);
        } catch (error) {
            console.error('Error updating content:', error);
        }
    };

    const preprocessHtmlContent = (html) => {
        return html
            .replace(/“/g, '&ldquo;')
            .replace(/”/g, '&rdquo;')
            .replace(/‘/g, '&lsquo;')
            .replace(/’/g, '&rsquo;')
            .replace(/-/g, '&ndash;')
            .replace(/—/g, '&mdash;')
            .replace(/…/g, '&hellip;');
    };

    const generateWord = () => {
        try {
            const contentElement = contentRef.current;
    
            if (!contentElement) {
                console.error("Content reference is null");
                return;
            }
    
            // Get and preprocess the HTML content
            let contentHtml = contentElement.innerHTML;
            contentHtml = preprocessHtmlContent(contentHtml);
    
            // Convert the HTML to a Word document blob
            const docxBlob = HtmlDocx.asBlob(contentHtml);
            if (!docxBlob) {
                console.error("Failed to generate Word document blob");
                return;
            }
    
            // Ensure the file name includes the .docx extension
            const fileName = modalTitle.endsWith(".docx") ? modalTitle : `${modalTitle}.docx`;
            // Save the blob as a .docx file
            saveAs(docxBlob, fileName);
    
            console.log("Document generated and download initiated!");
        } catch (error) {
            console.error("Error generating document:", error);
        }
    };
    

    const handleCloseModal = () => {
        setShowModal(false);
        setDraftContent('');
        setModalTitle('');
    };

    return (
        <div className="container mt-4">
            <h2>Drafts Listing</h2>
            {loading ? (
                <p>Loading drafts...</p>
            ) : drafts.length > 0 ? (
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Draft Title</th>
                            <th>Status</th>
                            <th>Created At</th>
                            {parseInt(draftTypeId, 10) === 11 && <th>Action</th>} {/* Add Action column for Legal Notices */}
                        </tr>
                    </thead>
                    <tbody>
                        {drafts.map((draft, index) => (
                            <tr key={draft.draft_id}>
                                <td>
                                    <a
                                        href="#!"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleDraftTitleClick(draft.draft_id, draft.draft_title, draft.source);
                                        }}
                                    >
                                        {draft.draft_title}
                                    </a>
                                </td>
                                <td>{draft.status}</td>
                                <td>{new Date(draft.created_at).toLocaleString()}</td>
                                {parseInt(draftTypeId, 10) === 11 && (
                                    <td>
                                        <button
                                            className={`btn btn-sm btn-warning ${styles.btn_color}`}
                                            onClick={() => handleRecoverySuitClick(draft.draft_id)}
                                        >
                                            Generate Recovery Suit
                                        </button>

                                        <span style={{ margin: "0 10px" }}></span> {/* Adds space between buttons */}

                                        <button
                                            className={`btn btn-sm btn-warning ${styles.btn_color}`}
                                            onClick={() => handleSettlementNoticeClick(draft.draft_id)}
                                        >
                                            Generate Settlement Notice
                                        </button>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No drafts found.</p>
            )}

            {/* Modal to display draft content */}
            {/* {showModal && (
                <div className="modal show fade d-block" tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header d-flex justify-content-between align-items-center">
                                <h5 className="modal-title">{modalTitle}</h5>
                                <div>
                                    <button
                                        className="btn btn-sm btn-secondary mr-2"
                                        onClick={generateWord}
                                    >
                                        Download as Word
                                    </button>
                                    <button type="button" className="close" onClick={handleCloseModal}>
                                        <span>&times;</span>
                                    </button>
                                </div>
                            </div>
                            <div
                                className="modal-body"
                                style={{ height: '80vh', overflowY: 'auto' }}
                                ref={contentRef}
                            >
                                <ReactMarkdown>{draftContent}</ReactMarkdown>
                            </div>
                        </div>
                    </div>
                </div>
            )} */}
            {showModal && (
                <div className="modal show fade d-block" tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header d-flex justify-content-between align-items-center">
                                <h5 className="modal-title">{modalTitle}</h5>
                                <div className="d-flex align-items-center">
                                    {!isEditing ? (
                                        <button
                                            className="btn btn-sm btn-primary mr-2"
                                            onClick={() => setIsEditing(true)} // Switch to edit mode
                                        >
                                            Edit
                                        </button>
                                    ) : (
                                        <button
                                            className="btn btn-sm btn-success mr-2"
                                            onClick={async () => {
                                                if (!currentDraftId) {
                                                    alert("Draft ID is not set. Please try again.");
                                                    return;
                                                }
                                                await updateDraftContent(draftTypeId , currentDraftId, draftContent); // Save updates
                                                setIsEditing(false); // Exit edit mode
                                            }}
                                        >
                                            Save Changes
                                        </button>
                                    )}
                                    <button
                                        className="btn btn-sm btn-secondary mr-2"
                                        onClick={generateWord}
                                    >
                                        Download as Word
                                    </button>
                                    <button type="button" className="close" onClick={handleCloseModal}>
                                        <span>&times;</span>
                                    </button>
                                </div>
                            </div>
                            <div
                                className="modal-body"
                                style={{ height: '80vh', overflowY: 'auto' }}
                                ref={contentRef}
                            >
                                {!isEditing ? (
                                    <ReactMarkdown>{draftContent}</ReactMarkdown> // Display content
                                ) : (
                                    <SimpleMDE
                                        value={draftContent}
                                        onChange={(value) => setDraftContent(value)} // Allow editing
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showModal && <div className="modal-backdrop fade show"></div>}
        </div>
    );
};

export default DraftListing;

import React, { useState } from 'react';

function InjunctionSuitForm({ formData, setFormData, onSubmit }) {
    const [errors, setErrors] = useState({});

    // Handle updates for both top-level and nested state objects
    const handleInputChange = (section, field, value) => {
        if (section) {
            setFormData((prev) => ({
                ...prev,
                [section]: {
                    ...prev[section],
                    [field]: value,
                },
            }));
        } else {
            setFormData((prev) => ({
                ...prev,
                [field]: value,
            }));
        }
    };

    // Basic form validation
    const validateForm = () => {
        let formErrors = {};

        // Basic Suit Details
        if (!formData.typeOfInjunction) {
            formErrors.typeOfInjunction = "Select the type of injunction suit.";
        }
        if (!formData.basicSuitDetails.caseTitle) {
            formErrors.caseTitle = "Case Title is required.";
        }
        if (!formData.basicSuitDetails.courtName) {
            formErrors.courtName = "Court Name is required.";
        }
        if (!formData.basicSuitDetails.plaintiffName) {
            formErrors.plaintiffName = "Plaintiff's Full Name is required.";
        }
        if (!formData.basicSuitDetails.plaintiffAddress) {
            formErrors.plaintiffAddress = "Plaintiff's Address is required.";
        }
        if (!formData.basicSuitDetails.defendantName) {
            formErrors.defendantName = "Defendant's Full Name is required.";
        }
        if (!formData.basicSuitDetails.defendantAddress) {
            formErrors.defendantAddress = "Defendant's Address is required.";
        }

        // Common Fields
        if (!formData.commonFields.causeOfAction) {
            formErrors.causeOfAction = "Cause of Action is required.";
        }
        if (!formData.commonFields.reliefSought) {
            formErrors.reliefSought = "Relief Sought is required.";
        }
        if (!formData.commonFields.disputedSubject.nature) {
            formErrors.disputedSubjectNature = "Specify the nature of the disputed subject.";
        }
        if (!formData.commonFields.urgencyAndHarm) {
            formErrors.urgencyAndHarm = "Please explain the urgency and harm.";
        }

        // Specific Fields
        if (formData.typeOfInjunction === "Restraining Injunction Suit") {
            if (!formData.restrainingInjunction.actToBeRestrained) {
                formErrors.actToBeRestrained = "Describe the act to be restrained.";
            }
            if (!formData.restrainingInjunction.balanceOfConvenience) {
                formErrors.balanceOfConvenience = "Provide the balance of convenience details.";
            }
        }

        if (formData.typeOfInjunction === "Mandatory Injunction Suit") {
            if (!formData.mandatoryInjunction.actToBeCompelled) {
                formErrors.actToBeCompelled = "Describe the act to be compelled.";
            }
            if (!formData.mandatoryInjunction.natureOfHarm) {
                formErrors.natureOfHarm = "Explain how the plaintiff is harmed.";
            }
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            onSubmit();
        }
    };

    return (
        <div>
            <h2 className="text-center">Injunction Suit Form</h2>
            <p className="text-center">
                <em>Relevant Law: Sections 54–57 of the Specific Relief Act, 1877</em>
            </p>

            <form onSubmit={handleSubmit}>
                <h4 className="mt-4">Basic Suit Details</h4>
                <div className="form-group">
                    <label>Injunction Type:</label>
                    <select
                        className={`form-control ${errors.typeOfInjunction ? 'is-invalid' : ''}`}
                        value={formData.typeOfInjunction || ""}
                        onChange={(e) => handleInputChange('', 'typeOfInjunction', e.target.value)}
                    >
                        <option value="">-- Select --</option>
                        <option value="Restraining Injunction Suit">Restraining Injunction Suit</option>
                        <option value="Mandatory Injunction Suit">Mandatory Injunction Suit</option>
                    </select>
                    {errors.typeOfInjunction && (
                        <div className="invalid-feedback">{errors.typeOfInjunction}</div>
                    )}
                </div>
                <div className="form-group">
                    <label>Case Title (e.g., Plaintiff vs. Defendant):</label>
                    <input
                        type="text"
                        className={`form-control ${errors.caseTitle ? 'is-invalid' : ''}`}
                        value={formData.basicSuitDetails.caseTitle || ""}
                        onChange={(e) => handleInputChange('basicSuitDetails', 'caseTitle', e.target.value)}
                        placeholder="Enter Case Title"
                    />
                    {errors.caseTitle && (
                        <div className="invalid-feedback">{errors.caseTitle}</div>
                    )}
                </div>
                <div className="form-group">
                    <label>Court Name:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.courtName ? 'is-invalid' : ''}`}
                        value={formData.basicSuitDetails.courtName || ""}
                        onChange={(e) => handleInputChange('basicSuitDetails', 'courtName', e.target.value)}
                        placeholder="Enter Court Name"
                    />
                    {errors.courtName && (
                        <div className="invalid-feedback">{errors.courtName}</div>
                    )}
                </div>
                
                <h4 className="mt-3">Plaintiff Details</h4>
                <div className="form-group">
                    <label>Full Name:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.plaintiffName ? 'is-invalid' : ''}`}
                        value={formData.basicSuitDetails.plaintiffName || ""}
                        onChange={(e) => handleInputChange('basicSuitDetails', 'plaintiffName', e.target.value)}
                        placeholder="Enter Plaintiff's Full Name"
                    />
                    {errors.plaintiffName && (
                        <div className="invalid-feedback">{errors.plaintiffName}</div>
                    )}
                </div>
                <div className="form-group">
                    <label>CNIC/ID Number:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.basicSuitDetails.plaintiffCnic || ""}
                        onChange={(e) => handleInputChange('basicSuitDetails', 'plaintiffCnic', e.target.value)}
                        placeholder="Enter Plaintiff's CNIC/ID"
                    />
                </div>
                <div className="form-group">
                    <label>Address:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.plaintiffAddress ? 'is-invalid' : ''}`}
                        value={formData.basicSuitDetails.plaintiffAddress || ""}
                        onChange={(e) => handleInputChange('basicSuitDetails', 'plaintiffAddress', e.target.value)}
                        placeholder="Enter Plaintiff's Address"
                    />
                    {errors.plaintiffAddress && (
                        <div className="invalid-feedback">{errors.plaintiffAddress}</div>
                    )}
                </div>
                <div className="form-group mb-3">
                    <label>Contact Information:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.basicSuitDetails.plaintiffContactInfo || ""}
                        onChange={(e) =>
                            handleInputChange('basicSuitDetails', 'plaintiffContactInfo', e.target.value)
                        }
                        placeholder="Enter Plaintiff's Contact Info"
                    />
                </div>

                <h4 className="mt-3">Defendant Details</h4>
                <div className="form-group">
                    <label>Full Name:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.defendantName ? 'is-invalid' : ''}`}
                        value={formData.basicSuitDetails.defendantName || ""}
                        onChange={(e) => handleInputChange('basicSuitDetails', 'defendantName', e.target.value)}
                        placeholder="Enter Defendant's Full Name"
                    />
                    {errors.defendantName && (
                        <div className="invalid-feedback">{errors.defendantName}</div>
                    )}
                </div>
                <div className="form-group">
                    <label>CNIC/ID Number (if available):</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.basicSuitDetails.defendantCnic || ""}
                        onChange={(e) => handleInputChange('basicSuitDetails', 'defendantCnic', e.target.value)}
                        placeholder="Enter Defendant's CNIC/ID"
                    />
                </div>
                <div className="form-group">
                    <label>Address:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.defendantAddress ? 'is-invalid' : ''}`}
                        value={formData.basicSuitDetails.defendantAddress || ""}
                        onChange={(e) =>
                            handleInputChange('basicSuitDetails', 'defendantAddress', e.target.value)
                        }
                        placeholder="Enter Defendant's Address"
                    />
                    {errors.defendantAddress && (
                        <div className="invalid-feedback">{errors.defendantAddress}</div>
                    )}
                </div>
                <div className="form-group mb-3">
                    <label>Relationship to Plaintiff:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.basicSuitDetails.defendantRelationship || ""}
                        onChange={(e) =>
                            handleInputChange('basicSuitDetails', 'defendantRelationship', e.target.value)
                        }
                        placeholder="e.g., neighbor, business partner, etc."
                    />
                </div>

                {/* 2. Common Fields */}
                <h4 className="mt-4">Injunction Suit Detail</h4>
                <div className="form-group">
                    <label>Cause of Action:</label>
                    <textarea
                        className={`form-control ${errors.causeOfAction ? 'is-invalid' : ''}`}
                        value={formData.commonFields.causeOfAction || ""}
                        onChange={(e) =>
                            handleInputChange('commonFields', 'causeOfAction', e.target.value)
                        }
                        placeholder="Explain the facts leading to the suit"
                    ></textarea>
                    {errors.causeOfAction && (
                        <div className="invalid-feedback">{errors.causeOfAction}</div>
                    )}
                </div>
                <div className="form-group">
                    <label>Relief Sought:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.reliefSought ? 'is-invalid' : ''}`}
                        value={formData.commonFields.reliefSought || ""}
                        onChange={(e) => handleInputChange('commonFields', 'reliefSought', e.target.value)}
                        placeholder='e.g., "Prevent the defendant from selling the property"'
                    />
                    {errors.reliefSought && (
                        <div className="invalid-feedback">{errors.reliefSought}</div>
                    )}
                </div>

                <div className="form-group">
                    <label>Details of Disputed Subject - Nature:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.disputedSubjectNature ? 'is-invalid' : ''}`}
                        value={formData.commonFields.disputedSubject.nature || ""}
                        onChange={(e) =>
                            handleInputChange('commonFields', 'disputedSubject', {
                                ...formData.commonFields.disputedSubject,
                                nature: e.target.value,
                            })
                        }
                        placeholder="e.g., Property, Contract, Financial Matter, etc."
                    />
                    {errors.disputedSubjectNature && (
                        <div className="invalid-feedback">{errors.disputedSubjectNature}</div>
                    )}
                </div>
                <div className="form-group mb-3">
                    <label>Specific Details (if property or contract, etc.):</label>
                    <textarea
                        className="form-control"
                        value={formData.commonFields.disputedSubject.specificDetails || ""}
                        onChange={(e) =>
                            handleInputChange('commonFields', 'disputedSubject', {
                                ...formData.commonFields.disputedSubject,
                                specificDetails: e.target.value,
                            })
                        }
                        placeholder="e.g., property address, contract date, key terms..."
                    ></textarea>
                </div>

                <div className="form-group">
                    <label>Urgency and Harm:</label>
                    <textarea
                        className={`form-control ${errors.urgencyAndHarm ? 'is-invalid' : ''}`}
                        value={formData.commonFields.urgencyAndHarm || ""}
                        onChange={(e) => handleInputChange('commonFields', 'urgencyAndHarm', e.target.value)}
                        placeholder="Explain why this relief is critical and how plaintiff will suffer without it"
                    ></textarea>
                    {errors.urgencyAndHarm && (
                        <div className="invalid-feedback">{errors.urgencyAndHarm}</div>
                    )}
                </div>

                <div className="form-group mb-3">
                    <label>Prior Legal Actions (if any):</label>
                    <textarea
                        className="form-control"
                        value={formData.commonFields.priorLegalActions || ""}
                        onChange={(e) => handleInputChange('commonFields', 'priorLegalActions', e.target.value)}
                        placeholder="Were there any prior suits or applications?"
                    ></textarea>
                </div>

                {/* 3. Specific Fields */}
                {formData.typeOfInjunction === "Restraining Injunction Suit" && (
                    <>
                        <h4 className="mt-3">Restraining Injunction Details</h4>
                        <div className="form-group">
                            <label>Act to Be Restrained:</label>
                            <input
                                type="text"
                                className={`form-control ${errors.actToBeRestrained ? 'is-invalid' : ''}`}
                                value={formData.restrainingInjunction.actToBeRestrained || ""}
                                onChange={(e) =>
                                    handleInputChange('restrainingInjunction', 'actToBeRestrained', e.target.value)
                                }
                                placeholder='e.g., "Selling the disputed property"'
                            />
                            {errors.actToBeRestrained && (
                                <div className="invalid-feedback">{errors.actToBeRestrained}</div>
                            )}
                        </div>
                        <div className="form-group mb-3">
                            <label>Balance of Convenience:</label>
                            <textarea
                                className={`form-control ${errors.balanceOfConvenience ? 'is-invalid' : ''}`}
                                value={formData.restrainingInjunction.balanceOfConvenience || ""}
                                onChange={(e) =>
                                    handleInputChange('restrainingInjunction', 'balanceOfConvenience', e.target.value)
                                }
                                placeholder="Explain why granting the injunction is less harmful than not granting it"
                            ></textarea>
                            {errors.balanceOfConvenience && (
                                <div className="invalid-feedback">{errors.balanceOfConvenience}</div>
                            )}
                        </div>
                    </>
                )}

                {formData.typeOfInjunction === "Mandatory Injunction Suit" && (
                    <>
                        <h4 className="mt-3">Mandatory Injunction Details</h4>
                        <div className="form-group">
                            <label>Act to Be Compelled:</label>
                            <input
                                type="text"
                                className={`form-control ${errors.actToBeCompelled ? 'is-invalid' : ''}`}
                                value={formData.mandatoryInjunction.actToBeCompelled || ""}
                                onChange={(e) =>
                                    handleInputChange('mandatoryInjunction', 'actToBeCompelled', e.target.value)
                                }
                                placeholder='e.g., "Remove encroachments," or "Restore access"'
                            />
                            {errors.actToBeCompelled && (
                                <div className="invalid-feedback">{errors.actToBeCompelled}</div>
                            )}
                        </div>
                        <div className="form-group mb-3">
                            <label>Nature of Harm:</label>
                            <textarea
                                className={`form-control ${errors.natureOfHarm ? 'is-invalid' : ''}`}
                                value={formData.mandatoryInjunction.natureOfHarm || ""}
                                onChange={(e) =>
                                    handleInputChange('mandatoryInjunction', 'natureOfHarm', e.target.value)
                                }
                                placeholder="Explain how the plaintiff is harmed by the defendant's inaction or wrongful act"
                            ></textarea>
                            {errors.natureOfHarm && (
                                <div className="invalid-feedback">{errors.natureOfHarm}</div>
                            )}
                        </div>
                    </>
                )}

                {/* 4. Evidence and Supporting Details */}
                <h4 className="mt-4">Evidence and Supporting Details</h4>
                <div className="form-group">
                    <label>List of Supporting Documents:</label>
                    <textarea
                        className="form-control"
                        value={formData.evidence.supportingDocuments || ""}
                        onChange={(e) =>
                            handleInputChange('evidence', 'supportingDocuments', e.target.value)
                        }
                        placeholder="Sale deed, contracts, photos/videos, etc."
                    ></textarea>
                </div>

                {/* 5. Legal References (Optional) */}
                <h4 className="mt-4">Legal References (Optional)</h4>
                <div className="form-group mb-3">
                    <label>Relevant Sections Invoked:</label>
                    <textarea
                        className="form-control"
                        value={formData.legalReferences.sections || ""}
                        onChange={(e) =>
                            handleInputChange('legalReferences', 'sections', e.target.value)
                        }
                        placeholder='e.g., "Sections 54–57 of the Specific Relief Act, 1877"'
                    ></textarea>
                </div>

                {/* 6. Prayer/Relief Sought */}
                <h4 className="mt-4">Prayer/Relief Sought</h4>
                <div className="form-group mb-3">
                    <textarea
                        className="form-control"
                        value={formData.prayer || ""}
                        onChange={(e) => handleInputChange('', 'prayer', e.target.value)}
                        placeholder="Summarize the final prayer or relief you want from the court"
                    ></textarea>
                </div>

                {/* Submit Button */}
                <button
                    type="submit"
                    className="btn mt-4"
                    style={{
                        backgroundColor: '#166432',
                        color: '#C59D55',
                        border: 'none',
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.backgroundColor = '#092415';
                        e.target.style.color = '#fff';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.backgroundColor = '#166432';
                        e.target.style.color = '#C59D55';
                    }}
                >
                    Next
                </button>
            </form>
        </div>
    );
}

export default InjunctionSuitForm;
